import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { TripType } from '../../../../../types/types';
import styles from './TripCard.module.scss'
import { ReactComponent as DelIcon } from '../../../../../assets/icons/delete.svg'
import { TextLine } from '../../../../simples/TextLine';
import LogoIcon from '../../../../../assets/images/logo.png'
import { ModalClose } from '../../../../simples/ModalClose';
import classNames from 'classnames';
import { BASE_URL } from '../../../../../constants/location';
import { thousand } from '../../../../../constants/helper';



type Props = {
    onDelete: (id: number) => void
    onClick: (id: number) => void
    trip: TripType
}

const TripCard: FC<Props> = ({ onClick, onDelete, trip }) => {
    const onHandleDelete = useCallback((e: any, id: number) => {
        setIsShowModalClose(true)
        e.stopPropagation()
    }, [onDelete, trip.id])

    const [isShowModalClose, setIsShowModalClose] = useState<boolean>(false)

    const closeModal = () => {
        setIsShowModalClose(false)
    }

    const del = () => {
        onDelete(trip.id)

    }

    return (
        <>
            <div className={styles.root}>
                <div onClick={() => onClick(trip.id)} className={styles.card}>
                    <div className={styles.imgWrapper}>
                        {(trip.images && trip.images.length > 0) ?
                            <img
                                className={styles.img}
                                src={BASE_URL.API_URL + trip.images[0].url}
                                alt={'cover'}
                            /> : <div>  <img src={'logo.png'} alt={'Logo'} className={styles.logo} /></div>}
                    </div>
                    <DelIcon
                        // style={{
                        //     stroke: 'red'
                        // }} 

                        className={styles.delete} onClick={(e) => onHandleDelete(e, trip.id)} />
                </div>
                <div className={styles.info}>
                    <div className={styles.name}>
                        <TextLine
                            size={16}
                            weight={700}
                            display={'block'}>{trip.name}</TextLine>
                    </div>
                    <TextLine
                        size={14}
                    >{`${thousand(trip.pricePerAdult)} ₽`} / {`${thousand(trip.pricePerChild)} ₽`}</TextLine>
                </div>
            </div>
            {isShowModalClose && <ModalClose onClose={closeModal} classes={{
                modal: styles.modal
            }} >
                <span>Вы действительно  хотите <br />удалить экскурсию?</span>
                <div className={styles.btnWrapper}>
                    <button onClick={closeModal} className={classNames(styles.btn, styles.btnClose)}>ОТМЕНА</button>
                    <button onClick={del} className={classNames(styles.btn, styles.btnDel)}>УДАЛИТЬ</button>
                </div>
            </ModalClose>}
        </>
    )
}
export default React.memo(TripCard)