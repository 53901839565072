import React, { FC, useCallback, useMemo } from 'react'
import { ImageType } from '../../../types/types'
import { TextLine } from '../../simples/TextLine'
import { ReactComponent as DelIcon } from '../../../assets/icons/delete.svg'
import styles from './ImagesBlock.module.scss'
import { BASE_URL } from '../../../constants/location'


type Props = {
    image: ImageType
    onDelete?: (id: number) => void
}


const Item: FC<Props> = ({ image, onDelete }) => {
    const onHandleDelete = useCallback(() => {
        onDelete && onDelete(image.id)
    },[image.id, onDelete])

    const nameFile = useMemo(() => {
        return image.url.split('/')[image.url.split('/').length - 1]
    },[image.url])
    return (
        <div className={styles.itemWrapper}>
            <div className={styles.imageBlock}>
                <div className={styles.imageWrapper}>
                    <img src={`${BASE_URL.API_URL}${image.url}`} alt={image.name} />
                </div>
                <TextLine>{nameFile}</TextLine>
            </div>
            {onDelete && <DelIcon
            className={styles.delete}
            onClick={onHandleDelete} />}
        </div>
    )
}

export default React.memo(Item)