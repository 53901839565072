import React, { FC, useCallback } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './TextLine.module.scss'




type Props = RouteProps &{
    size?: number
    color?: string
    weight?: number
    family?: string
    mb?: number
    mr?: number
    mt?: number
    ml?: number
    display?: 'block' | 'inline' | 'flex'
    onClick?: () => void
}

/**
 * 
 * @param size - font size
 * @param color - color
 * @param children - text or jsx
 * @param weight - weight font
 * @param family - font family
 * @param mb - margin-bottom
 * @param mr - margin-right
 * @param mt - margin-top
 * @param ml - margin-left
 * @param display - display 
 * @param onClick - callback 
 * @returns - Ntxn line
 */

const TextLine: FC<Props> = ({
    size = 16,
    color = '#363949',
    children,
    weight = 400,
    family,
    mb = 0,
    mr = 0,
    mt = 0,
    ml = 0,
    display = 'inline',
    onClick
}) => {

    const onHandleClick = useCallback(() => {
        onClick && onClick()
    },[onClick])
     return (
        <span
            onClick={onHandleClick}
            style={{
                display: display,
                fontSize: size,
                color: color,
                fontWeight: weight,
                fontFamily: family,
                marginRight: mr + 'px',
                marginTop: mt + 'px',
                marginLeft: ml + 'px',
                marginBottom: mb + 'px',
                cursor: onClick ? 'pointer' : 'initial'
            }}
            className={styles.root}
        >{children}</span>
    )
}
export default React.memo(TextLine)