import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { TextLine } from '../../simples/TextLine';
import styles from './Note.module.scss'

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import UserItem from './UserItem';
import { Button, ButtonColors } from '../../simples/Button';
import classNames from 'classnames';
import { NoteType } from '../../../types/types';
import { NoteService, useCreateNoteMutation, useGetNotesQuery, useUpdateNoteMutation } from '../../../services/NoteService';



type Props = {
}

const Note: FC<Props> = ({  }) => {
    const { data } = useGetNotesQuery({},{
        pollingInterval: 5000
    })
    const [updateNote] = useUpdateNoteMutation()
    const [createNote] = useCreateNoteMutation()

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [usersReaded, setUsersReaded] = useState<Array<any>>(data?.usersReaded || [])

    const [noteText, setNoteText] = useState<string>(data?.content || '')

    const currentNote = useRef<NoteType>(data || {} as NoteType)

    useEffect(() => {
        if (data) {
            setNoteText(data?.content)
            setUsersReaded(data.usersReaded || [])
            currentNote.current = data
        }
    }, [data])


    /**
     * change note
     */
    const onChangeNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setNoteText(event.target.value)
    }, [])

    /**
     * save note
     */
    const onSaveNote = useCallback(async () => {
        try {
            if (currentNote.current.id) {
                await updateNote({ ...currentNote.current, content: noteText })
                // setUsersReaded(response.usersReaded || [])
            }
            else {
                await createNote({ name: 'Заметка', content: noteText })
                // currentNote.current = newNote
                // setUsersReaded(newNote.usersReaded || [])

            }

        } catch (error: any) {
            console.log(error?.response?.data);

        }
        setIsEdit(false)
    }, [createNote, noteText, updateNote])


    return (
        <div className={styles.root}>
            <div className="">
                <div className={styles.top}>
                    <TextLine weight={700} size={18} >Заметка</TextLine>
                    {!isEdit && <EditIcon className={styles.edit} onClick={() => setIsEdit(prev => !prev)} />}
                    {isEdit && <Button
                        onClick={onSaveNote}
                        className={styles.saveBtn}
                        color={ButtonColors.Secondary}
                    >Сохранить</Button>}
                </div>

                <div className={styles.editBlock}>
                    <textarea
                        disabled={!isEdit}
                        className={classNames(styles.textArea, {
                            [styles.active]: isEdit
                        })}
                        value={noteText}
                        onChange={onChangeNote}
                        rows={20}
                    />
                </div>
            </div>
            <div className="">
                <TextLine display={'block'} mb={8} color={'rgba(19, 26, 47, 0.7)'} size={12} weight={600}>Прочитали:</TextLine>
                <div className={styles.usersBlock}>
                    {
                        usersReaded.map((user, index) => <UserItem key={index} user={user} />)
                    }
                    <div className={styles.gradient} />
                </div>
            </div>

        </div>
    )
}
export default Note