import { Endpoints } from '../constants/constants'
import { sendFormData } from '../constants/helper'
import { ProfileType } from '../types/types'
import api from '../utils/api'
import apiWithoutToken from '../utils/apiWithoutToken'

type AuthData = {
    login: string
    password: string
}
export class AuthService {
    static async login(data: AuthData) {


        const response = await apiWithoutToken.post(Endpoints.LOGIN, sendFormData(data))
        return response.data.data.token
    }

    /**
     * get profile user
     */
    static async getUSer(): Promise<ProfileType>{
        const response =await api.get(Endpoints.GET_USER)
        return response.data.data
    } 
}