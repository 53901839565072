import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { RoutesProps, useNavigate } from 'react-router-dom';
import { Button } from '../../simples/Button';
import { Input } from '../../simples/Input';
import { ItemHolder } from '../../simples/ItemHolder';
import { ModalClose } from '../../simples/ModalClose';
import styles from './AddTransferTypeModal.module.scss'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as ImageIcon } from '../../../assets/icons/uploadImage.svg'
import classNames from 'classnames';
import { TRansferService } from '../../../services/TransferService';
import { useAppDispatch } from '../../../hooks/store';
import { editTransferType } from '../../../store/slice/storage';



type Props = RoutesProps & {
    title: string
    onClose: () => void
    onSubmit: (name: string, icon: string) => void
    id?: string | undefined
}
type ErrorType = {
    name?: string,
    icon?: boolean
}
const AddTransferTypeModal: FC<Props> = ({ title, onSubmit, id, onClose, children }) => {
    const dispatch = useAppDispatch()

    const getById = async () => {
        if (id) {
            const response = await TRansferService.fetchbyIdTransferTypes(+id)
            // console.log(response);

            setVlue(response.name)
            setIcon(response.svgIcon)
        }
    }
    useEffect(() => {
        getById()
    }, [id])

    const [error, setError] = useState<ErrorType>({
        icon: false
    } as ErrorType)
    const [value, setVlue] = useState<string>('')
    const [icon, setIcon] = useState<string>('')
    const [preview, setPreview] = useState<string>('')
    // console.log(icon);


    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: 'image/svg+xml',
        maxFiles: 1
    })
    // console.log('acceptedFiles', acceptedFiles);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setPreview(URL.createObjectURL(acceptedFiles[0]))
            let reader = new FileReader();

            reader.readAsText(acceptedFiles[0]);

            reader.onload = function () {
                if (typeof reader.result === 'string')
                    setIcon(reader.result);
            };
        }
    }, [acceptedFiles])

    const disableIcon = useCallback(() => {
        open()
        setError((prev) => ({ ...prev, icon: false }))
    }, [open])

    const onHandleSubmit = async () => {

        if (!value) {
            setError((prev) => ({ ...prev, name: 'Поле обязательно для заполнения' }))
        }
        if (id) {
            // onSubmitEdit(id, value, icon)
            // const response = await TRansferService.updateTransferType(+id, value, icon)
            await dispatch(editTransferType({ id, name: value, svgIcon: icon }))
            onClose()
            return
        }
        if (!!!preview) {
            setError((prev) => ({ ...prev, icon: true }))
        }

        if (icon && value) {

            onSubmit(value, icon)
            onClose()

        }

        // onSubmit(value,)
    }
    const valueFun = (str: string) => {
        setVlue(str)
        setError((prev) => ({ ...prev, name: '' }))
    }
    // console.log('prev', preview);
    const div = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (div && div.current) {
            // console.log(div.current);
            div.current.innerHTML = icon
        }
    }, [div.current, div, icon])

    return (
        <ModalClose classes={{
            body: styles.modal
        }} onClose={onClose} title={title}>
            <ItemHolder>
                <Input error={error.name} onChange={valueFun} value={value} />
            </ItemHolder>
            <ItemHolder>
                <div className={styles.iconBlock}>
                    <div {...getRootProps({ className: 'dropzone' })}><input {...getInputProps()} />
                        <button className={classNames(styles.iconLoad, {
                            [styles.errorIcon]: error.icon,
                            [styles.haveIcon]: !error.icon,
                        })} type="button" onClick={disableIcon}>
                            Нажмите для загрузки иконки
                        </button>
                    </div>
                    <div className={styles.icon}>
                        {!!preview ?
                            <img
                                className={styles.icon}
                                src={preview}
                                alt={''}
                            />
                            :
                            !!icon ?
                                // <img src={`data:image/svg;utf8,${icon}`} />
                                // <img src={`data:image/svg+xml;utf8,${image}`} />
                                // <span dangerouslysetinnerhtml={{ __html: " " < svg >..."{'}'}} />;
                                // <img src={svg(icon)} />
                                <div ref={div}></div>
                                :
                                <ImageIcon width={`50px`} height={`50px`} />
                        }
                        {/* /> : <ImageIcon width={`50px`} height={`50px`} />} */}


                    </div>
                </div>

            </ItemHolder>
            <Button className={styles.btn} onClick={onHandleSubmit}>Создать</Button>
        </ModalClose >
    )
}
export default AddTransferTypeModal

function Svg(svg: string) {
    return svg
}
