import React, { FC, useState } from "react";
import { ModerationType } from "../../../types/types";
import styles from "./Moderation.module.scss";
import Men from "../../../assets/images/men.png";
import StarIcon from "../../simples/StarIcon/StarIcon";
import { ModerationService } from "../../../services/ModerationService";
import { BASE_URL } from "../../../constants/location";

type Props = {
  item: ModerationType;
  show: (item: ModerationType) => void;
  deleteLocalId: (id: number) => void
};


const ModerationItem: FC<Props> = ({ item, deleteLocalId, show }) => {

  const [loading, setLoading] = useState<boolean>(false)
  const deleteAnswer = async () => {
    setLoading(true);
    if (item?.id !== undefined) {
      const response = await ModerationService.deleteComments(item?.id)
    }
    setLoading(false);
    deleteLocalId(item?.id)
  }
  
  return (
    <div className={styles.cardItem}>
      <div className={styles.photo}>
        <img src={BASE_URL.API_URL + item.userData.photoUrl} alt={"Logo"} className={styles.logo} />
      </div>
      <div className={styles.rigth}>
        <div className={styles.top}>
          <div className={styles.center}>
            <div className={styles.title}>
              {item.userData.name}
            </div>
            <div className={styles.excurtion}>
              {" "}<p>{item.tripData.cityName}</p> <p>{item.tripData.name}</p>{" "}
            </div>
          </div>
          <div className={styles.date}>
            <p>
              {item.updatedAt.split(" ")[0].split('-').reverse().join('.')} {item.updatedAt.split(" ")[1]}
            </p>
          </div>
        </div>
        <div className={styles.description}>
          {item.comment}
        </div>
        <div className={styles.bottom}>
          <div className={styles.rate}>
            <StarIcon starCount={item.rating} />
          </div>
          <div className={styles.btn}>
            <button onClick={deleteAnswer} className={styles.delete}>Удалить</button>
            <button onClick={() => show(item)} className={styles.answer}>
              Ответить
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ModerationItem;
