import classNames from 'classnames'
import produce from 'immer'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/store'
import { ProfileService } from '../../../services/ProfileService'
import { AliasStaticPage, Profile, ProfileTab, UserRole } from '../../../types/types'
import { ScreenWrapper } from '../../complexes/ScreenWrapper'
import { Button } from '../../simples/Button'
import { ItemHolder } from '../../simples/ItemHolder'
import { TextEditor } from '../../simples/TextEditor'
import styles from './Profile.module.scss'
type Props = {}
type TextEditorType = {
    privacyPolicy: string
    contacts: string
    userAgreement: string
    aboutUs: string
}

const ProfileUsers = (props: Props) => {
    const [textIditer, setTextEditer] = useState<TextEditorType>({
        privacyPolicy: '',
        contacts: '',
        userAgreement: '',
        aboutUs: '',
    })
    const [mode, setMode] = useState<AliasStaticPage>(AliasStaticPage.PRIVACYPOLICY)
    const [loading, setLoading] = useState<boolean>(false)
    const [profile, setProfile] = useState<Profile>()
    const [profileTab, setProfileTab] = useState<ProfileTab[]>([])
    const [sendClick, setSendClick] = useState<boolean>(false)
    const user = useAppSelector(state => state.auth.user)
    const onChangetextEditer = useCallback((value, key: keyof TextEditorType) => {
        setTextEditer(produce(
            draft => {
                const f: any = draft
                f[key] = value
            }
        ))
    }, [])

    const setTab = (modeFun: AliasStaticPage) => {
        setMode(modeFun)
    }

    // ProfileService
    const getProfile =
        async () => {

            setLoading(true);
            try {
                const response = await ProfileService.getUsers();

                setProfile(response);
            } catch (error) { }
            setLoading(false);
        }
    // ProfileServiceEditor
    const getProfileServiceEditor =
        async () => {

            setLoading(true);
            try {
                const response = await ProfileService.getTabText();

                setProfileTab(response)
                for (const key of response) {
                    onChangetextEditer(key.content, key.alias)
                }
            } catch (error) { }
            setLoading(false);
        }


    const updateTabEdit = async (id: number, data: ProfileTab) => {

        try {
            const response = await ProfileService.updateTabTextItem(id, data)

        } catch (error) {

        }
    }
    useEffect(() => {
        getProfileServiceEditor()
        getProfile()
    }, [])

    const saveBtn = () => {
        setSendClick(true)
        setTimeout(() => {
            setSendClick(false)

        }, 1000)

        for (const key of profileTab) {
            if (key.alias === mode) {
                updateTabEdit(key.id, {
                    id: key.id,
                    title: key.title,
                    alias: key.alias,
                    content: textIditer[mode]
                })
            }

        }
    }
    return (
        <div className={styles.rootProfile}>

            <ScreenWrapper classes={{
                root: styles.screenRoot
            }} >
                <div className={styles.root}>
                    <h1 className={styles.title}>Профиль <span>Край Рай</span> </h1>
                    <div className={styles.wrapper}>
                        <div className={styles.top}>
                            <div className={styles.userProfile}>
                                <h2>{profile?.firstName} {profile?.lastName || ''}</h2>
                                <p>{profile?.role}</p>
                            </div>
                            <div className={styles.contacts}>
                                <div>
                                    <h3>Номер телефона: {profile?.phone || ''}</h3>
                                    <h3>Почта: {profile?.email || ''}</h3>
                                </div>
                                <div>
                                    <h3>Город: {profile?.city?.name || ''}</h3>
                                </div>
                            </div>
                        </div>
                        {user!.role === UserRole.ADMIN && <div className={styles.bottom}>

                            <div className={styles.tabsEditorWrapper}>
                                <div className={styles.btn}>
                                    <button onClick={() => setTab(AliasStaticPage.PRIVACYPOLICY)} className={classNames(styles.btnItem, {
                                        [styles.__activeBtnItem]: AliasStaticPage.PRIVACYPOLICY === mode
                                    })}>Политика конфи-ти</button>
                                    <button onClick={() => setTab(AliasStaticPage.CONTACTS)} className={classNames(styles.btnItem, {
                                        [styles.__activeBtnItem]: AliasStaticPage.CONTACTS === mode
                                    })}>Контакты</button>
                                    <button onClick={() => setTab(AliasStaticPage.USERAGREEMENT)} className={classNames(styles.btnItem, {
                                        [styles.__activeBtnItem]: AliasStaticPage.USERAGREEMENT === mode
                                    })}>Пользовательское соглашение</button>
                                    <button onClick={() => setTab(AliasStaticPage.ABOUTUS)} className={classNames(styles.btnItem, {
                                        [styles.__activeBtnItem]: AliasStaticPage.ABOUTUS === mode
                                    })}>О нас</button>
                                </div>
                                <div className={styles.textEditor}>
                                    <div className={classNames(styles.textEditorItem, {
                                        [styles.textEditorItemNone]: AliasStaticPage.PRIVACYPOLICY !== mode
                                    })}>
                                        <ItemHolder>
                                            <TextEditor
                                                classes={{
                                                    root: styles.texEditorStyle
                                                }}
                                                onChange={(value) => onChangetextEditer(value, 'privacyPolicy')}
                                                value={textIditer.privacyPolicy}
                                            />
                                        </ItemHolder>
                                    </div>
                                    <div className={classNames(styles.textEditorItem, {
                                        [styles.textEditorItemNone]: AliasStaticPage.CONTACTS !== mode
                                    })}>
                                        <ItemHolder>
                                            <TextEditor
                                                classes={{
                                                    root: styles.texEditorStyle
                                                }}
                                                onChange={(value) => onChangetextEditer(value, 'contacts')}
                                                value={textIditer.contacts}
                                            />
                                        </ItemHolder>
                                    </div>
                                    <div className={classNames(styles.textEditorItem, {
                                        [styles.textEditorItemNone]: AliasStaticPage.USERAGREEMENT !== mode
                                    })}>
                                        <ItemHolder>
                                            <TextEditor
                                                classes={{
                                                    root: styles.texEditorStyle
                                                }}
                                                onChange={(value) => onChangetextEditer(value, 'userAgreement')}
                                                value={textIditer.userAgreement}
                                            />
                                        </ItemHolder>
                                    </div>
                                    <div className={classNames(styles.textEditorItem, {
                                        [styles.textEditorItemNone]: AliasStaticPage.ABOUTUS !== mode
                                    })}>
                                        <ItemHolder>
                                            <TextEditor
                                                classes={{
                                                    root: styles.texEditorStyle
                                                }}
                                                onChange={(value) => onChangetextEditer(value, 'aboutUs')}
                                                value={textIditer.aboutUs}
                                            />
                                        </ItemHolder>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.wrapperButton}>
                                <Button onClick={saveBtn} className={classNames(styles.button, {
                                    [styles.click]: sendClick
                                })}>
                                    Обновить
                                </Button>
                            </div>
                        </div>}
                    </div>
                </div>
            </ScreenWrapper>
        </div>
    )
}

export default ProfileUsers 
