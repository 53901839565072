import React, { FC } from 'react';
import styles from './TextEditor.module.scss'
import JoditEditor, { JoditProps } from "jodit-react";
import classNames from 'classnames';

const buttons = [

    "bold",
    // "strikethrough",
    "underline",
    "italic",
    "|",
    "fontsize",
    "link",
    'align', '|',
    'ul',
    'brush', '|',
    'ol', '|',



]

const config = {
    readonly: false,
    addNewLineOnDBLClick: false,
    toolbar: true,
    spellcheck: true,
    language: "ru",
    toolbarButtonSize: "large",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    
    //defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    uploader: {
        insertImageAsBase64URI: false
    },
    width: 'auto',
    height: '100%',
    backgroundColor: '#fff',
    FontSizer: 24,
    tabIndex: 1,
    enter: "P",

};


type Props = {
    value: string
    onChange: (value: string) => void
    label?: string
    classes?: {
        root?: string
    }
}

const TextEditor: FC<Props> = ({ value, onChange, label, classes }) => {
    return (
        <div>
            <div className={styles.label}>{label}</div>
            <div className={classNames(styles.wrapper, classes?.root)}>
                <JoditEditor

                    config={config}
                    onChange={onChange}
                    value={value}
                />
            </div>
        </div>
    )
}
export default TextEditor