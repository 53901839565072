import React, { FC } from 'react';
import styles from './SalesTable.module.scss'



type Props = {

}

const TableHeader: FC<Props> = ({ }) => {
    return (
        <thead>
            <tr>
                <th className={styles.tableCellHeader}>Название</th>
                <th className={styles.tableCellHeader}>Цена взрослого (ребенка)</th>
                <th className={styles.tableCellHeader}>Кол-во</th>
                <th className={styles.tableCellHeader}>Общая стоимость</th>
                <th className={styles.tableCellHeader}>Дата и время</th>
            </tr>
        </thead>
    )
}
export default React.memo(TableHeader)