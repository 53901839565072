import React, { FC } from 'react';
import { ModalClose } from '../../simples/ModalClose';
import { TextLine } from '../../simples/TextLine';
import styles from './AddWidgetModal.module.scss'
import ChooseButton from './ChooseButton';
import { ReactComponent as GuidIcon } from '../../../assets/icons/guid.svg'
import { ReactComponent as TripIcon } from '../../../assets/icons/trip.svg'



type Props = {
    onClose: () => void
    onClickGuid: () => void
    onClickExcurtion: () => void
}

const AddWidgetModal: FC<Props> = ({
    onClose,
    onClickGuid,
    onClickExcurtion
}) => {


    return (
        <ModalClose
            title={<ModalTitle />}
            classes={{
                close: styles.modalTitle,
                modal: styles.modalBody
            }} onClose={onClose}>

            <div className={styles.modalInner}>
                <ChooseButton
                    icon={<GuidIcon />}
                    onClick={onClickGuid}
                    title={'Гид'}
                />
                <ChooseButton
                    icon={<TripIcon />}
                    onClick={onClickExcurtion}
                    title={'Экскурсии'}
                />
            </div>
        </ModalClose>
    )
}
export default AddWidgetModal


function ModalTitle() {
    return (
        <div className={styles.titleElement}>
            <TextLine weight={700} size={24} display={'block'}>Создание нового блока</TextLine>
            <TextLine color={'#A2A3A5'} weight={600} size={12}>Выберете, по чему будет осуществляться фильтр</TextLine>
        </div>
    )
}