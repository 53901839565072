import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './Auth.module.scss'
import { useAppDispatch } from '../../../hooks/store'
import { AuthService } from '../../../services/AuthService'
import { sendFormData } from '../../../constants/helper'
import { TextLine } from '../../simples/TextLine'
import { Input } from '../../simples/Input'
import { CheckBox } from '../../simples/CheckBox'
import { Button } from '../../simples/Button'
import { getUser, signIn } from '../../../store/slice/auth'
import { store } from '../../../store'
import { TypeInput } from '../../simples/Input/Input'

const Auth: FC = () => {
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [remember, setRemember] = useState<boolean>(true)

    const [tabelNumber, setTabelNumber] = useState<string>('')

    const [error, setError] = useState<string>('')
    const [repairError, setRepairError] = useState<string>('')

    const dispatch = useAppDispatch()
    const [errors, setErrors] = useState<{
        login?: string
        password?: string
    }>({})
    /**
 * запрос на восстановление пароля
 */
    const repairLogin = useCallback(async () => {
        try {

        } catch (error: any) {
            setRepairError(error.response.data.errors[0])
        }
    }, [login, tabelNumber])




    const logIn = useCallback(async () => {
        if (!password) {
            setErrors(prev => ({ ...prev, password: 'Поле обязательно для заполнения' }))
        }
        if (!login) {
            setErrors(prev => ({ ...prev, login: 'Поле обязательно для заполнения' }))
        }

        if (password.length && login.length) {
            try {
                const response = await dispatch(signIn({ login, password, remember }))

                if (response.payload === undefined) {
                    setErrors(prev => ({ ...prev, login: 'Неправильный логин или пароль' }))


                } else {
                    await dispatch(getUser())
                }

            } catch (error: any) {
                setError(error.response.data.errors[0])
            }
        }

    }, [dispatch, login, password, remember])


    // /**
    //  * 
    //  */
    // const authError = useCallback(() => {
    //     // setIsRepair(prev => !prev)
    //     setPassword('')
    //     setTabelNumber('')
    // }, [])

    // useEffect(() => {
    //     setError('')
    //     setRepairError('')
    // }, [login, password, tabelNumber])

    const passwordEnter = (str: string) => {
        setErrors(prev => ({ ...prev, password: '' }))
        setPassword(str)
    }
    const loginEnter = (str: string) => {
        setErrors(prev => ({ ...prev, login: '' }))
        setLogin(str)
    }
    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        logIn()
    }

    return (
        <div className={styles.root}>
            <div className={styles.centerBlock}>

                <div className={styles.centerInnerBlock}>
                    <div className={styles.border}>
                        <div className={styles.innerLeft}>
                            <div>
                                <div className={styles.leftTopText}><TextLine color={'#A2A3A5'} weight={600}>Добро пожаловать в </TextLine></div>
                                <div className={styles.leftTopText}><TextLine size={32} weight={600}>КрайРай</TextLine></div>
                            </div>
                            <div>
                                <img src={'logo.png'} alt={"КрайРай"} />
                            </div>
                        </div>
                        <form onSubmit={submitForm} className={styles.innerRight}>
                            <div className={styles.authText}><TextLine weight={600}>Авторизация</TextLine></div>
                            <div >
                                <div className={styles.inputHolder}>
                                    <Input
                                        error={errors.login}
                                        placeholder={'Почта'}
                                        value={login}
                                        onChange={loginEnter}
                                    />
                                </div>
                                <div className={styles.inputHolderPass}>
                                    <Input
                                        typeProps={TypeInput.PASSWORD}
                                        error={errors.password}
                                        placeholder={'Пароль'}
                                        value={password}
                                        onChange={passwordEnter}
                                    />
                                </div>


                                <div className={styles.helpBlock}>
                                    <TextLine color='rgba(19, 26, 47, 0.7)' weight={500} size={12}>Забыли пароль?</TextLine>
                                    <TextLine color='rgba(19, 26, 47, 0.7)' weight={500} size={12}>Обратитесь по номеру
                                        <span className={styles.redText}>+7 911 923 93 32</span>
                                    </TextLine>
                                </div>

                                <div className={styles.checkBox}>
                                    <CheckBox
                                        checked={remember}
                                        onChange={() => setRemember(prev => !prev)}
                                    />
                                    <TextLine family='Roboto' color={'rgba(0, 0, 0, 0.5)'}>Запомнить меня</TextLine>
                                </div>
                            </div>

                            <Button
                                type={'submit'}
                                onClick={logIn}
                            >Войти</Button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Auth;
