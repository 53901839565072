import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { AddButtonSimple } from "../../complexes/AddButtonSimple";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import StaffCard from "./parts/StaffCard";
import { AddUsersModal } from "./parts/AddUsersModal";
import { ScrollBlock } from "../../simples/ScrollBlock";
import styles from "./Staff.module.scss";
import { StaffService } from "../../../services/StaffService";
import { Sort, UserRole, StaffType } from "../../../types/types";
import { StaffContext } from "./StaffContexts";
import { Dropdown } from "../../simples/Dropdown";
import { ItemHolder } from "../../simples/ItemHolder";
import { useAppSelector } from "../../../hooks/store";
import { selectCities } from "../../../store/slice/storage";
import { OptionType } from "../../simples/Dropdown/Dropdown";
import { selectUser } from "../../../store/slice/auth";

type Props = {};



const Staff: FC<Props> = ({ }) => {
  const [isShowModal, setisShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const offset = useRef(0);
  const has = useRef(true);
  const [users, setUsers] = useState<StaffType[] | any[]>([]);
  const currentUsers = useRef<StaffType[]>(users)
  currentUsers.current = users
  const [itemId, setItemId] = useState<number | undefined>();
  const [optionCity, setOptionCity] = useState<OptionType[]>([])
  const [cityValue, setCityValue] = useState<string>('')
  const user = useAppSelector(selectUser)

  const cities = useAppSelector(selectCities)
  /**
      * 
      * cities options
      */
  const cityOptions: OptionType[] = useMemo((): OptionType[] => {

    if (cities) {

      return cities.map((item) => ({
        value: String(item.id),
        label: item.name
      }))
    }
    return []
  }, [cities])



  // modal 
  const showModal = (flag: boolean) => {
    setisShowModal(flag);
  };

  const addUsers = () => {
    showModal(true);
    setItemId(undefined)
  };

  const editUser = (id: number) => {
    setItemId(id);

    showModal(true);
  };


  // fetch user 
  const getUsers = async () => {
    if (!has.current || loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await StaffService.getUsers({
        offset: offset.current,
        limit: 25,
        cityId: +cityValue,
        sort: Sort.ASC
      });

      if (!response.length) {
        has.current = false;
        setLoading(false)
        return;
      }

      offset.current = offset.current + response.length;

      setUsers([...currentUsers.current, ...response]);
    } catch (error) { }
    setLoading(false);
  };
  // const clearGetUsers = () => {
  //   offset.current = 0;
  //   has.current = true;
  //   setUsers(prev => []);
  //   getUsers().then();
  // };
  // local chenges and back 
  const deleteUsers = async (id: number) => {
    setLoading(true);
    try {
      const response = await StaffService.deleteUsers(id);
      if (response) {
        setUsers(prev => prev.filter(item => item.id !== id));
      }
    } catch (error) { }
    setLoading(false);
  };

  useEffect(() => {
    setOptionCity(cityOptions)
  }, [])

  const clearStaff = async () => {
    offset.current = 0;
    has.current = true;
    currentUsers.current = []
    setUsers([]);
    await getUsers()
  }


  useEffect(() => {
    clearStaff().then()
  }, [cityValue])

  return (

    <React.Fragment>
      <StaffContext.Provider value={[users, setUsers]}>

        <ScreenWrapper
          onEndReached={getUsers}
          title={"Пользователи"}
          topRightSide={<AddButtonSimple onClick={addUsers} />}
        >
          {user?.role === UserRole.ADMIN && <ItemHolder>
            <Dropdown
              value={
                [cityValue] || ['']
              }
              options={optionCity}
              onChange={(data) => setCityValue(data.value)}
              placeholder={"Выберите название города"}
            />
          </ItemHolder>}
          <ScrollBlock className={styles.loading} onReachedEnd={getUsers}>
            <table
              className={styles.table}
              style={{
                maxHeight: 100,
              }} >
              <thead className={styles.headerTable}>
                <tr>
                  <th className={styles.cell}>Роль</th>
                  <th className={styles.cell}>Имя</th>
                  <th className={styles.cell}>Телефон</th>
                  <th className={styles.cell}>E-mail</th>
                  <th className={styles.cell}>Город</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {users.map(user => {
                  return <StaffCard
                    onEdit={id => editUser(id)}
                    onDelete={id => deleteUsers(id)}
                    key={user.id}
                    cardInfo={user}
                  />
                }
                )}
              </tbody>
            </table>
          </ScrollBlock>
        </ScreenWrapper>
        {isShowModal &&
          <AddUsersModal
            id={itemId}
            onClose={() => showModal(false)}
          />}
      </StaffContext.Provider>
    </React.Fragment>

  );
};

export default Staff;
