import axios, { AxiosRequestConfig } from 'axios'
import { BASE_URL } from '../constants/location'

//https://www.npmjs.com/package/axios-auth-refresh
export type ErrorsTypes = {
    [field: string]: Array<string> | Array<{ [index: number]: Array<string> }>
}

export type ResponseType = {
    code: number
    success: boolean
    data?: any
    errors?: ErrorsTypes
}

const config: AxiosRequestConfig = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}

config.baseURL = BASE_URL.API_URL

// if (process.env.REACT_APP_API_URL) {
//     config.baseURL = process.env.REACT_APP_API_URL
// }

const instance = axios.create(config)


// Устанавливаем токен в заголовок при каждом запросе
instance.interceptors.request.use((request: any) => { 
    
    
    const token = localStorage.getItem('accessToken')
    request.headers['Authorization'] = `${token}`
    return request
})

// instance.interceptors.response.use((response: any) => {
//     return response
// }, (error: any) => {
//     if( error &&
//         error.response && 
//         error.response.data &&
//         error.response.data.status === 403){
            
//                 $message.error(error.response.data.message, [1500])
//             } else {
//                 const errors = error.response.data.errors

//                 for(let key of errors){
//                     $message.error(key, [1200])
//                 }
//             }
    
// } )

// Подключаем плагин для обновления токена
// createAuthRefreshInterceptor(instance, AuthService.refreshToken)

type AddData = {
    [field: string]: any
}

export type UploadFileResponse<DataType, MetaType> = ResponseType & {
    data: DataType
    meta: MetaType
}

export type UploadFileResult<DataType, MetaType> = {
    data: DataType
    meta: MetaType
}

export const uploadFile = async <DataType, MetaType>(
    file: File,
    endpoint: string,
    field: string = 'file',
    onUploadProgress: (percent: number) => void,
    addData?: AddData
): Promise<UploadFileResult<DataType, MetaType>> => {
    const config: AxiosRequestConfig = {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )

            if (onUploadProgress) {
                onUploadProgress(percentCompleted)
            }
        },
    }

    let data = new FormData()
    data.append(field, file)

    if (addData) {
        for (let field in addData) {
            data.append(field, addData[field])
        }
    }

    const response = await instance.post<
        UploadFileResponse<DataType, MetaType>
    >(endpoint, data, config)

    return {
        data: response.data.data,
        meta: response.data.meta,
    }
}

export default instance
