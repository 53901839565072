import { Endpoints } from "../constants/constants"
import { appSlice } from "../store/slice/app"
import { CityType } from "../types/types"
import api from '../utils/api'


export class CityService {
    static async fetchCities(data: {
        name?: string
        offset?: number
        limit?: number
    }): Promise<CityType[]> {
        const { name, offset = 0, limit = 30 } = data

        const query = [
            `offset=${offset}`,
            `limit=${limit}`
        ]
        if (name) {
            query.push(`name=${name}`)
        }

        let endpoint = `${Endpoints.GET_CITIES}?` + query.join('&')


        const response = await api.get(endpoint)

        return response.data.data
    }

    /**
     * create city
     */
    static async createCity(name: string): Promise<CityType> {
        const fd = new FormData()
        fd.append('name', name)
        const response = await api.post(Endpoints.CREATE_CITY, fd)
        return response.data.data
    }

    /**
     * delte city by id
     */
    static async deleteCity(id: number): Promise<boolean> {
        try {
            await api.delete(`${Endpoints.CITY_DELTE}/${id}`)
            return true
        } catch (error) {
            return false
        }
    }
}