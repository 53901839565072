export const _API_PREFIX = process.env.REACT_APP_API_URL;

// 'http://88.198.56.78:8091/'

export enum Endpoints {
  LOGIN = "/auth/login-admin",
  GET_USER = "/user/profile",
  GET_USERS = "/users",
  GET_USERS_ID = "/users/get",
  CREATE_USERS = "/users/create",
  UPDATE_USERS = "/users/update/",
  DELETE_USERS = "/users/delete/",

  GET_CITIES = "/cities/admin",
  GET_CITIES_ID = "/cities/get/",
  CREATE_CITY = "/cities/create",
  CITY_DELTE = "/cities/delete",
  GET_TRIP_TYPES = "/trip-types",
  CREATE_TRIP_TYPE = "/trip-types/create",
  DELETE_TRIP_TYPE = "trip-types/delete",
  GET_TRIPS = "/trips",
  GET_TRIP_BY_ID = "/trips/get",
  TRIP_DATES_BY_ID_GET = "/trips-dates",
  UPLOAD_PHOTO_TRIP = "/trips/photo-upload",
  DELETE_PHOTO_TRIP = "/trips/photo-delete",
  UPDATE_TRIP = "/trips/update",
  CREATE_TRIP = "/trips/create",

  TRANSFER_TYPES_GET = "/transfer-types",
  TRANSFER_TYPES_CREATE = "/transfer-types/create",
  TRANSFER_TYPES_UPDATE = "/transfer-types/update",
  TRANSFER_TYPES_DELETE = "/transfer-types/delete",
  TRANSFER_TYPES_GET_BY_ID = "/transfer-types/get",

  LOCATION_NOTES = "/locations",

  GET_ROLES = "/users/roles",

  NOTE_GET = "/notes",
  NOTE_READ = "/notes/read",
  NOTE_CREATE = "/notes/create",
  NOTE_UPDATE = "/notes/update",
  NOTE_DELETE = "/notes/delet",

  WIDGET_CREATE = "/widgets/create",
  WIDGET_UPDATE = "/widgets/update",
  WIDGET_GET = "/widgets",
  WIDGETS_DELETE = "/widgets/delete",

  TRIPS_REVIEWS = '/trips-reviews/admin',
  TRIPS_REVIEWS_DELETE = '/trips-reviews/delete/',
  TRIPS_REVIEWS_ANSWER = '/trips-reviews/answer/',
  TRIP_DELETE = '/trips/delete',
  TRIPS_REVIEWS_PUBLICH = '/trips-reviews/publish',


  GET_PROFILE = "/static-pages",
  UPDATE_PROFILE = "/static-pages/update/",


  OREDERS = 'orders',
  CREATE_ORDERS_ADMIN = '/orders/create-admin',
  ORDERS_ITEM = '/orders/items',
  ORDERS_CANCEL = '/orders/cancel',
}

export const DAYS_NAMES = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
export const DAYS_NAMES_FULL = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота"
];

export const DAY_IN_WEEK = 7;
export const _MONTHS = [
  {
    id: 1,
    label: "Январь",
    short: "Jan"
  },
  {
    id: 2,
    label: "Февраль",
    short: "Feb"
  },
  {
    id: 3,
    label: "Март",
    short: "Mar"
  },
  {
    id: 4,
    label: "Апрель",
    short: "Apr"
  },
  {
    id: 5,
    label: "Май",
    short: "May"
  },
  {
    id: 6,
    label: "Июнь",
    short: "Jun"
  },
  {
    id: 7,
    label: "Июль",
    short: "Jul"
  },
  {
    id: 8,
    label: "Август",
    short: "Aug"
  },
  {
    id: 9,
    label: "Сентябрь",
    short: "Sep"
  },
  {
    id: 10,
    label: "Октябрь",
    short: "Окт"
  },
  {
    id: 11,
    label: "Ноябрь",
    short: "Nov"
  },
  {
    id: 12,
    label: "Декабрь",
    short: "Dec"
  }
];

export const mailRe = /\S+@\S+\.\S{2}/;

export const emailDomens = [
  "gmail.com",
  "mail.ru",
  "inbox.ru",
  "vars.ru",
  "yandex.ru",
  "ya.ru"
];

export const colors: string[] = ["#FE435B", "#20C997"];
