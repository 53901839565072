import React, { FC, useContext, useState } from "react";
import { Button } from "../../../../simples/Button";
import { Input } from "../../../../simples/Input";
import { ItemHolder } from "../../../../simples/ItemHolder";
import { ModalCloseModeration } from "../ModalCloseModeration";
import Men from "../../../../../assets/images/men.png";

import styles from "./ModerationModal.module.scss";
import StarIcon from "../../../../simples/StarIcon/StarIcon";
import { ModerationType } from "../../../../../types/types";
import { ModerationService } from "../../../../../services/ModerationService";
import { ModerationContext } from "../../ModerationContext";
import { BASE_URL } from "../../../../../constants/location";
import { useAppSelector } from "../../../../../hooks/store";
import { selectUser } from "../../../../../store/slice/auth";

type Props = {
  onClose: () => void;
  answer: ModerationType | undefined;
  deleteLocalId: (id: number) => void
};

const ModerationModal: FC<Props> = ({ onClose, deleteLocalId, answer }) => {

  const [Moderation, setModeration] = useContext(ModerationContext)
  const user = useAppSelector(selectUser)

  const [loading, setLoading] = useState<boolean>(false);
  const [send, setsend] = useState<string>("");

  const sendAnswer = () => {
    answerReviews(answer!.id)
    onClose()
  };

  const deleteAnswer = () => {
    deleteLocalId(answer!.id)
    deleteReviews(answer!.id)
    onClose()
  }

  const answerReviews = async (id: number) => {
    setLoading(true);
    if (answer?.id !== undefined) {
      try {
        const response = await ModerationService.answerComments({ id, comment: send })
        const res = await ModerationService.publichComments(id)
        setModeration(prev => prev.map((item) => {
          if (item.id === res.id) {
            return res
          }
          return item
        })
        )

      } catch (error) {
        console.log(error);

      }

    }
    setLoading(false);
  }

  const deleteReviews = async (id: number) => {
    setLoading(true);
    if (answer?.id !== undefined) {
      const response = await ModerationService.deleteComments(id)
      onClose()
    }
    setLoading(false);
  }

  return (
    <ModalCloseModeration onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.photo}>
          <img src={BASE_URL.API_URL + answer?.userData.photoUrl} alt="Men" />
        </div>
        <div className={styles.reviewsInfo}>
          <div>
            <div className={styles.topTitle}>
              <div>
                <div className={styles.title}>{answer?.userData.name}</div>
                <div className={styles.excurtion}>
                  {" "}<p>{answer?.tripData.cityName}</p> <p>{answer?.tripData.name}</p>{" "}
                </div>
              </div>
              <div className={styles.date}>
                <p>{answer?.createdAt.split(' ')[0].split('-').reverse().join('.')} {answer?.createdAt.split(' ')[1]}</p>
              </div>
            </div>
            <div className={styles.description}>
              {answer?.comment}
            </div>
            <div className={styles.answerMain}>
              {console.log(answer?.answer)}
              {/* {console.log(answer?.answer[answer?.answer?.length - 1].comment)} */}
              <p>{`Менеджер ${user?.firstName || ''}:`}</p> <p>{answer?.answer && answer?.answer[answer?.answer?.length - 1]?.comment}</p>
            </div>
            <div className={styles.answerInput}>
              {!!!answer?.answer?.length && <ItemHolder>
                <Input placeholder={"Ответ"} onChange={setsend} value={send} />
              </ItemHolder>}
            </div>
          </div>
          <div className={styles.bottomModal}>
            <div className={styles.rate}>
              <StarIcon starCount={answer!.rating} />
            </div>
            <div className={styles.btn}>
              <button onClick={deleteAnswer} className={styles.delete}>Удалить</button>
              {!!!answer?.answer?.length && <button onClick={sendAnswer} className={styles.answer}>
                Ответить
              </button>}
            </div>
          </div>

        </div>
      </div>
    </ModalCloseModeration>
  );
};

export default ModerationModal;
