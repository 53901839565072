import { Endpoints } from "../constants/constants"
import { appSlice } from "../store/slice/app"
import { CityType, TransferType } from "../types/types"
import api from '../utils/api'


export class TRansferService {
    static async fetchTransferTypes(data: {
        name?: string
        offset?: number
        limit?: number
    }): Promise<TransferType[]> {
        const { name, offset = 0, limit = 30 } = data

        const query = [
            `offset=${offset}`,
            `limit=${limit}`
        ]
        if (name) {
            query.push(`name=${name}`)
        }

        let endpoint = `${Endpoints.TRANSFER_TYPES_GET}?` + query.join('&')


        const response = await api.get(endpoint)

        return response.data.data
    }

    // fetch by id
    static async fetchbyIdTransferTypes(id: number): Promise<TransferType> {

        const response = await api.get(`${Endpoints.TRANSFER_TYPES_GET_BY_ID}/${id}`)

        return response.data.data
    }

    /**
     * create transferType
     */
    static async createTransferType(name: string, svgIcon: string): Promise<TransferType> {
        const fd = new FormData()
        fd.append('name', name)
        fd.append('svgIcon', svgIcon)
        const response = await api.post(Endpoints.TRANSFER_TYPES_CREATE, fd)
        return response.data.data
    }

    /**
     * update transferType
     */
    static async updateTransferType(id: number, name: string, svgIcon: string): Promise<TransferType> {
        const fd = new FormData()
        fd.append('id', '' + id)
        fd.append('name', name)
        fd.append('svgIcon', svgIcon)
        const response = await api.post(`${Endpoints.TRANSFER_TYPES_UPDATE}/${id}`, fd)
        return response.data.data
    }

    /**
     * delete transfer type
     */
    static async deleteTransfer(id: number): Promise<boolean> {
        try {
            await api.delete(`${Endpoints.TRANSFER_TYPES_DELETE}/${id}`)
            return true
        } catch (error) {
            return false
        }
    }


}