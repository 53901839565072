import classNames from "classnames";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import RequestsModal from "./parts/modal/RequestsModal/RequestsModal";
import styles from "./Requests.module.scss";
import TableSellBody from "./parts/table/TableSellBody";
import { AddButton } from "../../complexes/AddButton";
import { Dropdown } from "../../simples/Dropdown";
import { ItemHolder } from "../../simples/ItemHolder";
import { OptionType } from "../../simples/Dropdown/Dropdown";
import { RequestsType, RequestsItem, TripType, UserRole } from "../../../types/types";
import { RequestsService } from "../../../services/RequestsService";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import { ScrollBlock } from "../../simples/ScrollBlock";
import { TripsService } from "../../../services/TripsService";
import { Input } from "../../simples/Input";
import produce from "immer";
import { RequestContext } from "./RequestContext";
import { useAppSelector } from "../../../hooks/store";
import { selectUser } from "../../../store/slice/auth";
import InputMask from 'react-input-mask';

type Props = {};
export type WidgetFilter = {
  tripId: string;
  dataStart: string
  dataEnd: string
};
const initDateMask = '__.__.____'
const Requests: FC<Props> = ({ }) => {
  const user = useAppSelector(selectUser)
  const [isShowModal, setisShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<RequestsType[]>([]);
  const [searchTrips, setSearchTrips] = useState<string>("");
  const [trips, setTrips] = useState<Array<OptionType>>([]);
  const hasTrips = useRef(true);
  const offsetTrips = useRef(0);
  const has = useRef(true);
  const offset = useRef(0);
  const [widget, setWidget] = useState<WidgetFilter>({
    tripId: '',
    dataStart: '',
    dataEnd: '',
  } as WidgetFilter);
  const [scrollBlockHeight, setScrollBlockHeight] = useState<number>(600)
  const [idActive, setidActive] = useState<number | undefined>();
  const [totalRequestsCount, settotalRequestsCount] = useState<number>()


  const modalShow = (flag: boolean) => {
    setisShowModal(flag);
  };
  /**
    * получение списка заявки
    */
  const getRequests = async () => {
    if (!has.current || loading) {
      return;
    }
    setLoading(true);
    try {
      const startD = widget.dataStart.length === 10 && widget.dataStart !== initDateMask ? widget.dataStart.split('.').reverse().join('-') : undefined
      const endD = widget.dataEnd.length === 10 && widget.dataEnd !== initDateMask ? widget.dataEnd.split('.').reverse().join('-') : undefined
      const response = await RequestsService.getRequests({
        offset: offset.current,
        limit: 30,
        tripId: widget.tripId,
        dateFrom: startD,
        dateTo: endD
      });

      if (!response.data.length) {
        has.current = false;
        setLoading(false);
        return;
      }

      offset.current = offset.current + response.data.length;

      setRequests(prev => [...prev, ...response.data]);
      settotalRequestsCount(response.data[0].id)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const subView = (id: number | undefined) => {
    setidActive(id);
  };


  /**
     * получение списка trips
     */
  const getTrips = useCallback(
    async () => {
      if (!hasTrips.current || loading) {
        return;
      }
      setLoading(true);
      try {
        const response = await TripsService.getTrips({
          offset: offsetTrips.current,
          limit: 100,
          citiesIds: user?.role === UserRole.MANAGER ? [String(user.cityId)] : undefined,
          name: searchTrips.length < 1 ? undefined : searchTrips,
          published: 2,
        });

        if (!response.length) {
          hasTrips.current = false;
          setLoading(false);
          return;
        }

        offsetTrips.current = offsetTrips.current + response.length;

        setTrips(prev => [...prev, ...createOptions(response)]);

      } catch (error) { }
      setLoading(false);
    },
    [loading, searchTrips, user]
  );


  const clearGetGuids = () => {
    offsetTrips.current = 0;
    hasTrips.current = true;
    setTrips(prev => []);
    getTrips().then();
  };



  const onChangeWidget = useCallback((value, key: keyof WidgetFilter) => {
    setWidget(produce(
      draft => {
        // const f: any = draft[key]
        draft[key] = value
      }
    ))
  }, [])

  useEffect(
    () => {
      clearGetGuids();
    },
    [searchTrips]
  );
  useEffect(() => {
    has.current = true;
    offset.current = 0
    setRequests([])
    getRequests();
  }, [widget.tripId])

  const reg = new RegExp('[^\_]+')

  useEffect(() => {
    if ((widget.dataStart && reg.exec(widget.dataStart)![0].length === 10) || widget.dataStart === '__.__.____') {
      has.current = true;
      offset.current = 0
      setRequests([])
      getRequests();
    }
  }, [widget.dataStart])

  useEffect(() => {
    if ((widget.dataEnd && reg.exec(widget.dataEnd)![0].length === 10) || widget.dataEnd === '__.__.____') {
      has.current = true;
      offset.current = 0
      setRequests([])
      getRequests();
    }
  }, [widget.dataEnd])

  // useEffect(() => {
  //   getTotalCountRequests()

  //   if (window !== undefined) {
  //     setScrollBlockHeight(window.innerHeight - 400);

  //   }
  // }, []);


  return (
    <>
      <RequestContext.Provider value={[requests, setRequests]}>
        <ScreenWrapper
          title={"Заявки"}
          topRightSide={
            <AddButton onClick={() => modalShow(true)}>Добавить заявку</AddButton>
          }
        >

          <div className={styles.cardWrapper}>
            <div className={styles.topFilter}>
              <div className={styles.dropDown}>
                <ItemHolder>
                  <Dropdown
                    withSearch
                    onRechedEnd={getTrips}
                    onSearch={setSearchTrips}
                    value={
                      [widget.tripId || '']
                    }
                    options={trips}
                    onChange={data => onChangeWidget(data.value, "tripId")}
                    placeholder={"Выберите название"}
                  />
                </ItemHolder>
              </div>
              <div className={styles.dateSort}>
                <div className={styles.dateSortItem}> <p>c</p>
                  <Input
                    mask={'99.99.9999'}
                    ml={10}
                    placeholder={'01.03.2022'}
                    onChange={data => {
                      onChangeWidget(data, 'dataStart')
                    }}
                    value={widget.dataStart}
                  />
                </div>
                <div className={styles.dateSortItem}> <p>до</p>

                  <Input
                    mask={'99.99.9999'}
                    ml={10}
                    placeholder={'02.03.2022'}
                    onChange={data => {
                      onChangeWidget(data, 'dataEnd')
                    }} value={widget.dataEnd}
                  />
                </div>
              </div>
            </div>
            <div className={styles.saleTable}>
              <div className={styles.tableCellHeader}>Номер заказа</div>
              <div className={styles.tableCellHeader}>Статус</div>
              <div className={styles.tableCellHeader}>Общая стоимость</div>
              <div className={styles.tableCellHeader}>Номер заказчика</div>
              <div
                className={classNames(
                  styles.tableCellHeader,
                  styles.dateColumn
                )}
              >
                Дата и время
              </div>
            </div>
            <ScrollBlock
              height={scrollBlockHeight}
              onReachedEnd={getRequests}
              className={styles.scroll}
            >
              {requests.map(item =>
                <TableSellBody
                  key={item.id}
                  id={idActive}
                  subActive={subView}
                  itemBody={item}
                />
              )}
            </ScrollBlock>
          </div>
          {isShowModal &&
            <RequestsModal
              title={`Группа ${totalRequestsCount! + 1}`}
              onClose={() => modalShow(false)}
            />}
        </ScreenWrapper>
      </RequestContext.Provider>
    </>
  );
};

export default Requests;

function createOptions(data: TripType[]): OptionType[] {
  return data.map(i => ({
    value: String(i.id),
    label: i.name
  }));
}
