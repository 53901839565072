import classNames from 'classnames';
import produce from 'immer';
import React, { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { TripsService, UpdateTripType } from '../../../services/TripsService';
import { createNewCity, createNewTransferType, createNewTripType, deleteCity, deleteTransferType, deleteTripType, selectCities, selectGuids, selectTransferTypes, selectTripTypes } from '../../../store/slice/storage';
import { BusyGuid, LocationType, ProfileType, TripType, UserRole } from '../../../types/types';
import { AddRecordModal } from '../../complexes/AddSignModal';
import { AddTransferTypeModal } from '../../complexes/AddTransferTypeModal';
import { DropZone } from '../../complexes/DropZone';
import ImagesBlock from '../../complexes/ImagesBlock/ImagesBlock';
import { InputNotes } from '../../complexes/InputNotes';
import { ScreenWrapper } from '../../complexes/ScreenWrapper';
import { Button } from '../../simples/Button';
import { Dropdown } from '../../simples/Dropdown';
import { OptionType } from '../../simples/Dropdown/Dropdown';
import { Input } from '../../simples/Input';
import { ItemHolder } from '../../simples/ItemHolder';
import { TextEditor } from '../../simples/TextEditor';
import { TextLine } from '../../simples/TextLine';
import styles from './EditExcurtion.module.scss'
import { DatePickerBlock } from './parts/DatePickerBlock';
import { ImportentInfoBlock } from './parts/ImportentInfoBlock';
import { ImportantInfoType } from './parts/ImportentInfoBlock/ImportentInfoBlock';
import { TimeEditor } from './parts/TimeEditor';
import InputMask from 'react-input-mask';
import { setTimeout } from 'timers/promises';
import { TypeInput } from '../../simples/Input/Input';
import { TRansferService } from '../../../services/TransferService';
import { selectUser } from '../../../store/slice/auth';
import { UsersService } from '../../../services/UsersService';



type Props = {

}


enum CreateModalType {
    TRIP_TYPE = 'trip_type',
    CITY = 'city'
}

enum AddRecordType {
    TYPE = 'type',
    CITY = 'city',
    TRANSFER = 'transfer'
}


export const ImportantInfoContext = createContext<[ImportantInfoType, React.Dispatch<React.SetStateAction<ImportantInfoType>>]>([
    {
        include: {
            enable: false,
            data: []
        },
        exclude: {
            enable: false,
            data: []
        },
        cancelConditions: {
            enable: false,
            data: []
        },
    },
    () => { }
])


type ErrorType = {
    phone?: string;
    transferTypeId?: string;
    cityId?: string;
    tripTypeId?: string;
    startLocationId?: string;
    endLocationId?: string;
    adults?: string;
    duration?: string;
    pricePerAdult?: string;
    pricePerChild?: string;
    name?: string
    guideId?: string
    dates?: string
    content?: string
    periodic?: string
    published?: string

}
const EditExcurtion: FC<Props> = ({ }) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    //errors validate
    const [errors, setErrors] = useState<ErrorType>({} as ErrorType)
    // trip id from query  

    const tripId = Number(location.search.split('=')[1])
    console.log('%cMyProject%cline:100%ctripId', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(248, 147, 29);padding:3px;border-radius:2px', tripId)
    // trip types
    const tripTypes = useAppSelector(selectTripTypes)

    // cties
    const cities = useAppSelector(selectCities)
    // // guids
    // const guids = useAppSelector(selectGuids)
    const [guids, setGuids] = useState<ProfileType[]>([])

    const fetchGuids = async () => {
        const response = await UsersService.getUsers({ role: UserRole.GUID, isBusy: BusyGuid.AVALIBLE, })
        setGuids(response)
    }
    useEffect(() => {
        fetchGuids()
    }, [])
    // const guids = guidsRedux.filter(item => item.cityId === currentTrip.cityId )c3adad8c7740cc7010ec07bf6c5842e447bd

    // transferTYpes
    const transferTypes = useAppSelector(selectTransferTypes)
    const [idTransfer, setIdTransfer] = useState<string | undefined>()
    // guids options
    const transferTypesOptions = useMemo((): OptionType[] => {
        return transferTypes.map((item) => ({
            value: String(item.id),
            label: item.name
        }))
    }, [transferTypes])


    // type trips options
    const typeTripsOptions = useMemo((): OptionType[] => {
        return tripTypes.map((item) => ({
            value: String(item.id),
            label: item.name
        }))
    }, [tripTypes])
    // type trips options
    const cityOptions = useMemo((): OptionType[] => {
        return cities.map((item) => ({
            value: String(item.id),
            label: item.name
        }))
    }, [cities])

    // error

    // user
    const user = useAppSelector(selectUser)

    const [loading, setLoading] = useState<boolean>(false)
    const [currentTrip, setCurrentTrip] = useState<TripType>({
        cityId: user?.role === UserRole.MANAGER && cities[0]?.id
    } as TripType)
    console.log(currentTrip.guide);

    const [dates, setDates] = useState<Array<string>>([])
    const [times, setTimes] = useState<Array<string>>([])

    //address options
    const [addressOptions, setAddressOptions] = useState<OptionType[]>([])

    const [importantInfo, setImportantInfo] = useState<ImportantInfoType>({
        include: {
            enable: false,
            data: []
        },
        exclude: {
            enable: false,
            data: []
        },
        cancelConditions: {
            enable: false,
            data: []
        },
    })
    console.log(importantInfo);


    // что мы добавляем . Модалка
    const [addRecordModal, setAddRecordModal] = useState<AddRecordType>()

    // title for addRecodModal
    const addRecordModalTitle = useMemo(() => {
        switch (addRecordModal) {
            case AddRecordType.TYPE:
                return 'Новый тип'
            case AddRecordType.CITY:
                return 'Новый город'
            case AddRecordType.TRANSFER:
                return 'Новый тип транспорта'

            default:
                return ''
        }
    }, [addRecordModal])


    // change current trip
    const onChangeTrip = useCallback((value: string | number, key: keyof UpdateTripType) => {
        setCurrentTrip(prev => ({ ...prev, [key]: value }))
        setErrors((prev) => ({ ...prev, [key]: '' }))
    }, [])



    /**
     * get one trip by id
     */
    const getTripById = useCallback(async (id: number) => {
        // setLoading(false)
        const response = await TripsService.getTripById(id)
        // setLoading(true)

        setCurrentTrip(response)
        if (response.content) {
            setImportantInfo(JSON.parse(response.content))

        }

        const getDate = await TripsService.getTripDatesById(id)
        if (getDate.length > 0) {
            setDates(getDate.map(i => i.date))
            setTimes(getDate[0].times.map(i => i.time))
        }
    }, [])

    /**
     * create trip and get id
     */
    const createTrip = useCallback(async () => {
        try {
            const response = await TripsService.createTrip()
            setCurrentTrip({ ...currentTrip, id: response })
        } catch (error) {

        }
    }, [currentTrip])


    useEffect(() => {
        if (tripId) {
            getTripById(tripId).then()

        } else {
            createTrip().then()
        }

    }, [getTripById, tripId])



    /**
     * upload files
     */
    const onUploadFiles = useCallback(async (data: Array<File>) => {
        for (let key of data) {
            const response = await TripsService.uploadImages(key, +currentTrip.id)

            setCurrentTrip(produce(
                draft => {
                    if ('images' in draft) {
                        draft.images.push(response)
                    } else {
                        draft.images = [response]
                    }
                }
            ))
        }

    }, [currentTrip.id])

    /**
     * delete photo
     */
    const onPhotoDelete = useCallback(async (id: number) => {
        try {
            const response = await TripsService.deleteImage(id)
            if (response) {
                setCurrentTrip(produce(
                    draft => {
                        draft.images = draft.images.filter(i => i.id !== id)
                    }
                ))
            }
        } catch (error) {

        }
    }, [])

    /**
     * update tripƒ
     */
    console.log(currentTrip.guideId);

    const updateTrip = async () => {
        let isError: boolean = false
        if (!currentTrip.phone) {
            setErrors((prev) => ({ ...prev, phone: 'Поле обязательно для заполнения' }))
            isError = true
        }
        if (currentTrip.transferTypeId === undefined) {
            setErrors((prev) => ({ ...prev, transferTypeId: 'Поле обязательно для заполнения' }))
            isError = true

        }
        if (!currentTrip.cityId) {
            setErrors((prev) => ({ ...prev, cityId: 'Поле обязательно для заполнения' }))
            isError = true

        }
        if (!currentTrip.name) {
            setErrors((prev) => ({ ...prev, name: 'Поле обязательно для заполнения' }))
            isError = true

        }
        if (!currentTrip.tripTypeId) {
            setErrors((prev) => ({ ...prev, tripTypeId: 'Поле обязательно для заполнения' }))
            isError = true

        }
        if (currentTrip.startLocationId === undefined) {
            setErrors((prev) => ({ ...prev, startLocationId: 'Поле обязательно для заполнения' }))
            isError = true

        }
        if (currentTrip.endLocationId === undefined) {
            isError = true
            setErrors((prev) => ({ ...prev, endLocationId: 'Поле обязательно для заполнения' }))
        }
        if (!currentTrip.adults) {
            isError = true
            setErrors((prev) => ({ ...prev, adults: 'Поле обязательно для заполнения' }))
        }
        if (!currentTrip.duration) {
            isError = true
            setErrors((prev) => ({ ...prev, duration: 'Поле обязательно для заполнения' }))
        }
        if (!currentTrip.pricePerAdult) {
            isError = true
            setErrors((prev) => ({ ...prev, pricePerAdult: 'Поле обязательно для заполнения' }))

        }
        if (!currentTrip.guideId) {
            isError = true
            setErrors((prev) => ({ ...prev, guideId: 'Поле обязательно для заполнения' }))

        }
        if (!!!times.length) {
            isError = true
            setErrors((prev) => ({ ...prev, dates: 'Поле обязательно для заполнения' }))

        }
        //  
        if (!isError) {
            const childCostFree: number = currentTrip.pricePerChild === undefined ? 0 : currentTrip.pricePerChild
            const sendTrip: UpdateTripType = {
                name: currentTrip.name,
                description: currentTrip.description,
                guideId: currentTrip?.guideId,
                phone: currentTrip.phone,
                id: currentTrip.id,
                transferTypeId: currentTrip.transferTypeId || 1,
                cityId: currentTrip.cityId,
                tripTypeId: currentTrip.tripTypeId,
                startLocationId: currentTrip.startLocationId,
                endLocationId: currentTrip.endLocationId,
                adults: currentTrip.adults,
                duration: currentTrip.duration,
                pricePerAdult: currentTrip.pricePerAdult,
                pricePerChild: childCostFree,
                dates: createDates(dates, times),
                content: JSON.stringify(importantInfo),
                periodic: currentTrip.periodic,
                published: 1

            }
            try {
                const response = await TripsService.updateTrip(sendTrip, currentTrip.id)
                navigate('/excurtions')

            } catch (error: any) {
                setErrors(error.response.data.errors);

            }
        }
    }

    const onAddTime = useCallback((value: string) => {
        setErrors((prev) => ({ ...prev, dates: '' }))
        if (!times.includes(value)) {
            setTimes(prev => [...prev, value])
        }
    }, [times])

    /**
     * adding new record
     */
    const onAddNewRecord = useCallback(async (value: string, svgIcon?: any) => {
        switch (addRecordModal) {
            case AddRecordType.TYPE:
                const respTT: any = await dispatch(createNewTripType(value))
                if (respTT.payload) {
                    setCurrentTrip(prev => ({ ...prev, tripTypeId: respTT.payload.id }))
                    setAddRecordModal(undefined)

                }
                break

            case AddRecordType.CITY:
                const respC: any = await dispatch(createNewCity(value))
                if (respC.payload) {
                    setCurrentTrip(prev => ({ ...prev, cityId: respC.payload.id }))
                    setAddRecordModal(undefined)

                }
                break
            case AddRecordType.TRANSFER:
                if (idTransfer) {

                } else {
                    const respT: any = await dispatch(createNewTransferType({ name: value, svgIcon }))
                    if (respC.payload) {
                        setCurrentTrip(prev => ({ ...prev, transferTypeId: respT.payload.id }))
                        setAddRecordModal(prev => undefined)
                    }
                }
                break

        }
    }, [addRecordModal, dispatch])

    /**
     * сбрасываем адрес при смене города
     */
    const resetAddress = useCallback(() => {
        setCurrentTrip(prev => ({
            ...prev,
            startLocationId: 0,
            endLocationId: 0,
            startLocation: {} as LocationType,
            endLocation: {} as LocationType,
        }))
    }, [])


    /**
     * удаления типа экскурсии
     */
    const onDeleteType = useCallback(async (value: string) => {
        dispatch(deleteTripType(+value))
    }, [dispatch])
    /**
     * удаления города
     */
    const onDeleteCity = useCallback(async (value: string) => {
        dispatch(deleteCity(+value))
    }, [dispatch])
    /**
     * удаления вида транспорта
     */
    const onDeleteVehicleType = useCallback(async (value: string) => {
        dispatch(deleteTransferType(+value))
    }, [dispatch])
    /**
     * удаления вида транспорта
     */
    const onEditVehicleType = useCallback(async (value: string) => {
        setAddRecordModal(AddRecordType.TRANSFER)
        setIdTransfer(value)
    }, [dispatch])




    // guids options
    const guidsOptions = useMemo((): OptionType[] => {
        let gid: OptionType[] = []
        if (!!tripId) {
            console.log(currentTrip.guide)
            const guide = currentTrip.guide
            console.log('%cMyProject%cline:480%cguide', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(96, 143, 159);padding:3px;border-radius:2px', guide)
            if (currentTrip?.guide?.id) {
                gid.push({
                    value: String(guide?.id),
                    label: `${guide.secondName || ''} ${guide.firstName || ''}`
                })
            }

        }
        if (currentTrip.cityId || user?.role === UserRole.MANAGER) {
            const id = user!.role === UserRole.MANAGER ? cities[0]?.id : currentTrip?.cityId

            guids.filter(item => {
                if (item.cityId === +id) {
                    gid.push({
                        value: String(item.id),
                        label: `${item.secondName || ''} ${item.firstName || ''}`
                    })
                }
            })
        }

        return gid
    }, [guids.length, currentTrip.cityId, currentTrip?.guide?.id, user?.role])
    // useEffect(() => {
    //     resetAddress();
    //     onChangeTrip(cities[0]?.id, 'cityId')
    // }, [cities.length])

    console.log(currentTrip.startLocationId);


    return (
        <>
            {currentTrip.id &&
                <ScreenWrapper
                    isLoading={loading}
                    title={'Экскурсии'}
                >
                    <div className={styles.wrapper}>
                        <ItemHolder>
                            <TextLine weight={700} size={20}>{tripId ? 'Редактирование экскурсии' : 'Создание экскурсии'}</TextLine>
                        </ItemHolder>
                        {/* Выбор типа трипа */}
                        <ItemHolder>
                            <Dropdown
                                error={errors.tripTypeId}
                                deleteModal={'Удаление приведёт к потере связанных экскурсий'}
                                rightLabel={
                                    <TextLine
                                        weight={600}
                                        color={'#FE435B'}
                                        onClick={() => setAddRecordModal(AddRecordType.TYPE)}
                                    >Создать тип</TextLine>
                                }
                                label={'Тип'}
                                classes={{
                                    root: styles.dropdownRoot
                                }}
                                onDelete={onDeleteType}
                                options={typeTripsOptions}
                                value={[String(currentTrip.tripTypeId)]}
                                onChange={({ value }) => onChangeTrip(value, 'tripTypeId')}
                            />
                        </ItemHolder>
                        {/* Выбор города */}
                        <ItemHolder>
                            <Dropdown
                                // disabled={user!.role === UserRole.MANAGER ? true : false}
                                error={errors.cityId}
                                deleteModal={'Удаление приведёт к потере связанных экскурсий'}
                                rightLabel={
                                    user!.role !== UserRole.MANAGER
                                        ?
                                        <TextLine
                                            weight={600}
                                            color={'#FE435B'}
                                            onClick={() => setAddRecordModal(AddRecordType.CITY)}
                                        >Добавить город</TextLine>
                                        :
                                        undefined
                                }
                                label={'Город'}
                                classes={{
                                    root: styles.dropdownRoot
                                }}
                                options={cityOptions}
                                value={user!.role === UserRole.MANAGER ? [cities[0].id.toString()] : [String(currentTrip.cityId)]}
                                // value={user!.role === UserRole.MANAGER ? [cityOptions[0].value] : [String(currentTrip.cityId)]}
                                onChange={({ value }) => {
                                    resetAddress();
                                    onChangeTrip(value, 'cityId')
                                }}
                                onDelete={onDeleteCity}
                            />
                        </ItemHolder>
                        {/* Изменение названия */}
                        <ItemHolder>
                            <Input
                                error={errors.name}
                                label={'Название'}
                                onChange={(value) => onChangeTrip(value, 'name')}
                                value={currentTrip.name || ''}
                            />
                        </ItemHolder>
                        {/* Изменение полного описания */}
                        <ItemHolder>
                            <TextEditor
                                label={'Полное описание'}
                                onChange={(value) => onChangeTrip(value, 'description')}
                                value={currentTrip.description || ''}
                            />
                        </ItemHolder>
                        {/* СТОимость для взрослых*/}
                        <ItemHolder>
                            <Input
                                typeProps={TypeInput.NUMBER}
                                error={errors.pricePerAdult}
                                label={'Стоимость экскурсии для одного взрослого туриста'}
                                placeholder={'Введите стоимость'}
                                onChange={(value) => onChangeTrip(value, 'pricePerAdult')}
                                value={`${currentTrip.pricePerAdult || ''}`}
                            />
                        </ItemHolder>
                        {/* СТОимость для детей*/}
                        <ItemHolder>
                            <Input
                                typeProps={TypeInput.NUMBER}

                                label={'Стоимость для детей'}
                                placeholder={'Введите стоимость'}
                                onChange={(value) => onChangeTrip(value, 'pricePerChild')}
                                value={`${currentTrip.pricePerChild || ''}`}
                            />
                        </ItemHolder>
                        {/* Колличество туристов (Max)*/}
                        <ItemHolder>
                            <Input
                                typeProps={TypeInput.NUMBER}

                                error={errors.adults}
                                placeholder='Введите колличество'
                                label={'Максимальное количество туристов в одной группе'}
                                onChange={(value) => onChangeTrip(value, 'adults')}
                                value={`${currentTrip.adults || ''}`}
                            />
                        </ItemHolder>

                        {/* Вид транспорта*/}
                        <ItemHolder>
                            <Dropdown
                                error={errors.transferTypeId}
                                rightLabel={
                                    <TextLine
                                        weight={600}
                                        color={'#FE435B'}
                                        onClick={() => setAddRecordModal(AddRecordType.TRANSFER)}
                                    >Создать тип транспорта</TextLine>
                                }
                                label={'Вид транспорта'}
                                classes={{
                                    root: styles.dropdownRoot
                                }}
                                options={transferTypesOptions}
                                value={[String(currentTrip.transferTypeId)]}
                                onChange={({ value }) => onChangeTrip(value, 'transferTypeId')}
                                onEdit={onEditVehicleType}
                                onDelete={onDeleteVehicleType}
                            />
                        </ItemHolder>
                        {/* Продолжительность экскурсии*/}
                        <ItemHolder>
                            <Input
                                error={errors.duration}
                                typeProps={TypeInput.NUMBER}
                                placeholder='Продолжительность экскурсии'
                                label={'Продолжительность экскурсии (часы)'}
                                onChange={(value) => onChangeTrip(value, 'duration')}
                                value={`${currentTrip.duration || ''}`}
                            />
                        </ItemHolder>
                        {/*  */}
                        <ItemHolder>
                            <TimeEditor
                                error={errors.dates}
                                onAddTime={onAddTime}
                                label={'Время начала экскурсии'}
                                values={times}
                                onDeleteTime={(time) => setTimes(prev => prev.filter((i) => i !== time))
                                } />
                        </ItemHolder>
                        {/* Адрес начала экскурсии */}
                        <ItemHolder>
                            <InputNotes
                                error={errors.startLocationId}
                                disabled={!currentTrip.cityId && user!.role !== UserRole.MANAGER}
                                cityId={currentTrip?.cityId}
                                placeholder='Введите адрес'
                                label={'Адрес начала экскурсии'}
                                onChange={(value) => onChangeTrip(value, 'startLocationId')}
                                value={currentTrip?.startLocation?.name || ''}
                                onCheckValue={({ value, label }) => onChangeTrip(value, 'startLocationId')}
                                options={addressOptions}
                            />
                        </ItemHolder>
                        {/* Адрес окнчания экскурсии */}
                        <ItemHolder>
                            <InputNotes
                                error={errors.endLocationId}
                                disabled={!currentTrip.cityId && user!.role !== UserRole.MANAGER}
                                cityId={user!.role === UserRole.MANAGER ? +cityOptions[0].value : +String(currentTrip.cityId)}
                                placeholder='Введите адрес'
                                label={'Адрес окнчания экскурсии'}
                                onChange={(value) => onChangeTrip(value, 'endLocationId')}
                                value={currentTrip?.endLocation?.name || ''}
                                onCheckValue={({ value, label }) => onChangeTrip(value, 'endLocationId')}
                                options={cityOptions}
                            />
                        </ItemHolder>
                        <ItemHolder>
                            <DatePickerBlock
                                key={dates.length}
                                isPeriod={currentTrip.periodic}
                                onChange={setDates}
                                values={dates}
                            />
                            <div className={styles.btnBlock}>
                                <Button
                                    onClick={() => onChangeTrip(0, 'periodic')}
                                    className={classNames(styles.dateBtn, {
                                        [styles.active]: !currentTrip.periodic
                                    })}
                                >Дата</Button>
                                <Button
                                    onClick={() => onChangeTrip(1, 'periodic')}
                                    className={classNames(styles.dateBtn, {
                                        [styles.active]: currentTrip.periodic
                                    })}
                                >Период</Button>
                            </div>
                        </ItemHolder>
                        {/* загрузка фото */}
                        <ItemHolder>
                            <DropZone onUpload={onUploadFiles} label={'Фото статьи'} />
                        </ItemHolder>

                        {/* отображение фото */}
                        <ItemHolder>
                            <ImagesBlock onDelete={onPhotoDelete} images={currentTrip.images} />
                        </ItemHolder>

                        {/* важная информации */}
                        <ItemHolder>
                            <ImportantInfoContext.Provider value={[importantInfo, setImportantInfo]}>
                                <ImportentInfoBlock />
                            </ImportantInfoContext.Provider>
                        </ItemHolder>
                        {/* Выбор типа гида */}
                        <ItemHolder>
                            <Dropdown
                                disabled={!!!currentTrip.cityId}
                                error={errors.guideId}
                                label={'Гид'}
                                classes={{
                                    root: styles.dropdownRoot
                                }}
                                options={guidsOptions}
                                value={[String(currentTrip.guideId)]}
                                onChange={({ value }) => onChangeTrip(value, 'guideId')}
                            />
                        </ItemHolder>
                        {/* Продолжительность экскурсии*/}
                        <ItemHolder>
                            <Input
                                error={errors.phone}
                                placeholder='Номер телефона'
                                label={'Остались вопросы?'}
                                onChange={(value) => onChangeTrip(value.replace(/\D/g, ''), 'phone')}
                                value={`${currentTrip.phone || ''}`}
                                mask={'+7(999)999-99-99'}
                            >

                            </Input>
                        </ItemHolder>

                        <ItemHolder>
                            <div className={styles.btnBlock}>
                                <Button className={styles.btn} onClick={updateTrip}>Сохранить</Button>
                            </div>
                        </ItemHolder>


                        {
                            addRecordModal && addRecordModal !== AddRecordType.TRANSFER && <AddRecordModal
                                onSubmit={onAddNewRecord}
                                onClose={() => setAddRecordModal(undefined)}
                                title={addRecordModalTitle}
                            />
                        }
                        {
                            addRecordModal === AddRecordType.TRANSFER && <AddTransferTypeModal
                                id={idTransfer}
                                onSubmit={onAddNewRecord}
                                onClose={() => setAddRecordModal(undefined)}
                                title={addRecordModalTitle}

                            />
                        }
                    </div>
                </ScreenWrapper>
            }
        </>

    )
}
export default EditExcurtion

function createDates(dates: Array<string>, times: Array<string>) {
    let sendTimes: Array<{
        date: string
        times: Array<string>
    }> = []

    sendTimes = dates.map((date) => ({
        date,
        times
    }))
    return JSON.stringify(sendTimes)
}