import { Endpoints } from "../constants/constants"
import { TripTypesType } from "../types/types"
import api from "../utils/api"



export class TripTypesService {
    static async fetchTripTypes(data: {
        name?: string
        offset?: number
        limit?: number
    }): Promise<TripTypesType[]> {
        const {name, offset = 0, limit = 30} = data

        const query = [
            `offset=${offset}`,
            `limit=${limit}`
        ] 
        if (name) {
            query.push(`name=${name}`)
        }

        let endpoint = `${Endpoints.GET_TRIP_TYPES}?` + query.join('&')


        const response = await api.get(endpoint)

        return response.data.data
    }

    /**
     * created new type trip
     */
    static async creteNewTripType(name: string): Promise<TripTypesType> {
        const fd = new FormData()
        fd.append('name', name)
        const response = await api.post(Endpoints.CREATE_TRIP_TYPE, fd)

        return response.data.data
    }

    /**
     * delete type 
     */
    static async deleteType(id: number):Promise<boolean> {
        try {
            await api.delete(`${Endpoints.DELETE_TRIP_TYPE}/${id}`)
            return true
        } catch (error) {
            return false
        }
    }
}