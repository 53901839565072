import { Endpoints } from "../constants/constants";
import { sendFormData, toQueryString } from "../constants/helper";
import { GuideType, RequestsType, TripType } from "../types/types";

import api from "../utils/api";

type RequestResponse = {
  data: Array<RequestsType>
  totalCount: number
}
export class RequestsService {
  /**
     * get excursion
     */
  static async getRequestsExcursion(data: {
    offset?: number;
    limit?: number;
    published: number,
    deleted?: number,
    citiesIds?: Array<string>;

  }): Promise<Array<TripType>> {
    const response = await api.get(
      `${Endpoints.GET_TRIPS}${toQueryString(data)}`
    );

    return response.data.data;
  }

  /**
    get Request
   */

  static async getRequests(data: {
    offset?: number;
    limit?: number;
    tripId?: string;
    dateFrom?: string;
    dateTo?: string;
  }): Promise<RequestResponse> {
    const { dateTo, dateFrom, tripId, limit, offset } = data;
    const query: any[] = [];

    if (tripId || dateFrom || dateTo) {
      if (tripId) {
        query.push(`tripId=${tripId}`);
      }
      if (dateFrom) {
        query.push(`dateFrom=${dateFrom}`);
      }
      if (dateTo) {
        query.push(`dateTo=${dateTo}`);
      }
    } else {
      if (tripId) {
        query.push(`tripId=${tripId}`);
      }
      if (dateFrom) {
        query.push(`dateFrom=${dateFrom}`);
      }
      if (dateTo) {
        query.push(`dateTo=${dateTo}`);
      }
      if (offset) {
        query.push(`offset=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
    }

    let endpoint = `${Endpoints.OREDERS}?${query.join("&")}`;

    const response = await api.get<RequestResponse>(endpoint);

    return response.data;
  }
  // static async getTotalCountRequests(): Promise<number> {
  //   const response = await api.get(`${Endpoints.OREDERS}`);

  //   return response.data.totalCount;
  // }

  // create postRequest
  static async postCreateRequests(data: any): Promise<RequestsType> {
    const response = await api.post(Endpoints.CREATE_ORDERS_ADMIN, sendFormData(data));

    return response.data.data;
    // return demoRequests;
  }


  // get guide 

  static async getGuide(): Promise<GuideType[]> {
    const response = await api.get(`${Endpoints.GET_USERS}?role=guide`)
    return response.data.data
  }

  static async cancelRequest(id: number): Promise<any> {
    const response = await api.get(`${Endpoints.ORDERS_CANCEL}/${id}`)
    return response.data.data
  }
}
