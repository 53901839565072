import React, { FC, useMemo } from 'react';
import styles from './Btn.module.scss'

export enum BtnType {
    PLUS = 'plus',
    MINUS = 'minus'
}

type Props = {
    onClick: () => void
    disabled?: boolean
    type: BtnType
}

const Btn: FC<Props> = ({ onClick, disabled, type }) => {

    const mark: JSX.Element = useMemo(() => {
        switch (type) {
            case BtnType.PLUS:
                
                return <span className={styles.plus}></span>

            case BtnType.MINUS:
                
                return <span className={styles.minus}></span>
        
            default:
                return <span className={styles.plus}></span>
        }
    },[type])
    return (
        <div onClick={onClick} className={styles.root}>
            {mark}
        </div>
    )
}
export default Btn