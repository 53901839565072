import React, { FC } from 'react';
import { OptionType } from '../../simples/Dropdown/Dropdown';
import styles from './InputNotes.module.scss'



type Props = {
    option: OptionType
    onClick: (option: OptionType) => void
}

const OptionItem: FC<Props> = ({ option, onClick }) => {
    return (
        <div onClick={() => onClick(option)} className={styles.item} >
            <div className={styles.itemLabel}>{option.label}</div>
        </div>
    )
}
export default React.memo(OptionItem)