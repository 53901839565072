import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../store/slice/auth'
import appReducer from '../store/slice/app'
import widgetsReducer  from '../store/slice/widgets'
import storageReducer  from '../store/slice/storage'
import { widgetsApi } from '../services/WidgetService'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { noteApi } from '../services/NoteService'
// import { ordersApi } from '../services/OrderService'


export const store = configureStore({
    reducer: {
        auth: authReducer,
        app: appReducer,
        widgets: widgetsReducer,
        storage: storageReducer,

        [widgetsApi.reducerPath]: widgetsApi.reducer,
        [noteApi.reducerPath]: noteApi.reducer,
        // [ordersApi.reducerPath] : ordersApi.reducer

    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat(widgetsApi.middleware)
        .concat(noteApi.middleware)
        // .concat(ordersApi.middleware)
    }
})

setupListeners(store.dispatch)

// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
