import React, { FC, useEffect, useRef, useState } from "react";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import styles from "./Moderation.module.scss";
import ModerationModal from "./parts/ModerationModal/ModerationModal";
import { ModerationType } from "../../../types/types";
import ModerationItem from "./ModerationItem";
import { ModerationService } from "../../../services/ModerationService";
import { ScrollBlock } from "../../simples/ScrollBlock";
import { ModerationContext } from "./ModerationContext";

type Props = {};

const Moderation: FC<Props> = ({ }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isShowModal, setisShowModal] = useState<boolean>(false)
  const [Moderation, setModeration] = useState<ModerationType[]>([])
  const [moderationItem, setModerationItem] = useState<ModerationType | undefined>()
  const has = useRef(true);
  const offset = useRef(0);
  const closeModal = () => {
    setisShowModal(false)
  }
  const [scrollBlockHeight, setScrollBlockHeight] = useState<number>(1000)

  const openModal = (item: ModerationType) => {
    setisShowModal(true)
    setModerationItem(item)
  }

  // ModerationService
  const getModeration =
    async () => {
      if (!has.current || loading) {
        return;
      }
      setLoading(true);
      try {
        const response = await ModerationService.Comments({
          offset: offset.current,
          limit: 30,
          published: 2
        });




        if (!response.length) {
          has.current = false;
          setLoading(false);
          return;
        }

        offset.current = offset.current + response.length;

        setModeration([...Moderation, ...response]);
      } catch (error) { }
      setLoading(false);
    }

  useEffect(() => {
    getModeration()
  }, [])
  const deleteLocalId = (id: number) => {
    setModeration(prev => prev.filter(item => item.id !== id))
  }
  return (
    <>
      <ModerationContext.Provider value={[Moderation, setModeration]}>
        <ScreenWrapper title={"Модерация"}>
          <h1 className={styles.titlePage}>Коментарии</h1>
          <ScrollBlock
            height={scrollBlockHeight}
            className={styles.cardWrapper}
            onReachedEnd={getModeration}
          >
            {Moderation.map(item => <ModerationItem key={item.id} deleteLocalId={deleteLocalId} item={item} show={openModal} />)}
          </ScrollBlock>

        </ScreenWrapper>

        {isShowModal && <ModerationModal
          deleteLocalId={deleteLocalId}
          answer={moderationItem}
          onClose={closeModal} />}
      </ModerationContext.Provider>
    </>
  );
};

export default Moderation;
