import React, { FC, useCallback } from 'react';
import { NoteService, useReadNoteMutation } from '../../../services/NoteService';
import { NoteType } from '../../../types/types';
import { Button } from '../../simples/Button';
import { ModalClose } from '../../simples/ModalClose';
import { TextLine } from '../../simples/TextLine';
import styles from './NoteModal.module.scss'

type Props = {
    note: NoteType
    onClose: (readed?: {
        message: string,
        success: boolean
    }) => void
}

const NoteModal: FC<Props> = ({ onClose, note }) => {

    const [readNote, result] = useReadNoteMutation()

    const onReadNote = useCallback(async () => {
        if(note.id) await readNote(note.id)

        onClose()
    }, [note.id, onClose, readNote])
    return (
        <ModalClose
            onClose={onClose}
            title={'Заметка'}
        >
            <div className={styles.root}>

                <TextLine>{note.content}</TextLine>

                <div className={styles.btn}><Button onClick={onReadNote} className={styles.btn}>ПРОЧИТАЛ</Button></div>
            </div>

        </ModalClose>
    )
}
export default NoteModal