import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import produce from 'immer'
import { DataWidgetType, ExcurtionWidget } from '../../components/complexes/EditExcurtionWidgetModal/EditExcurtionWidgetModal'
import { OrderType } from '../../types/types'
import { RootState } from '../index'

type State = {
    widgets: Array<ExcurtionWidget 
    // | GuidWidget
    >
    orders: Array<OrderType>
}

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState: State = {
    widgets: [],
    orders: []
}



/**
 * Создание слайса App
 */
export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        setWidget:(state, action: PayloadAction<ExcurtionWidget>) => {
            const find = state.widgets.find((i) => i.uid === action.payload.uid) as ExcurtionWidget as any
            const aI = action.payload  as ExcurtionWidget as any
            if(find){
                for(let key in find){
                    find[key] = aI[key]
                }
            }
        }
    },
    
  
})




export const {
    setWidget
} = widgetsSlice.actions

export const selectWidgets = (state: RootState) => state.widgets.widgets
// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default widgetsSlice.reducer
