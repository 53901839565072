import { ChangeEvent, FC, MouseEventHandler, useCallback, useState } from 'react';
import { OptionType } from './Dropdown';
import styles from './Dropdown.module.scss'
// import { ReactComponent as Shevron } from '../../../assets/icons/Arrows-Vector.svg'
import { CheckBox } from '../CheckBox';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";

import { JsxChild } from 'typescript';
import { ModalClose } from '../ModalClose';



type Props = {
    option: OptionType
    onClick: (data: {
        value: string,
        keyName?: string
    }) => void
    isMultiple?: boolean
    isChecked?: boolean
    onDelete?: (value: string) => void
    onEdit?: (value: string) => void
    deleteModal?: string
}

const Item: FC<Props> = ({
    option,
    onClick,
    isMultiple,
    isChecked,
    onEdit,
    onDelete,
    deleteModal
}) => {
    const [isShowModal, setisShowModal] = useState<boolean>(false)

    const onEditItem = useCallback((event: ChangeEvent<any>) => {
        event.stopPropagation()
        // onDelete && onDelete(option.value)
        onEdit && onEdit(option.value)

    }, [onEdit, option.value])
    const onDeleteItem = useCallback((event: ChangeEvent<any>) => {
        event.stopPropagation()
        onDelete && onDelete(option.value)
    }, [onDelete, option.value])
    const ondelete = useCallback((event: ChangeEvent<any>) => {
        event.stopPropagation()
        setisShowModal(true)
    }, [])

    return (
        <>
            <div className={styles.itemRoot}>
                <div className={styles.itemWrapper}>
                    <div
                        // onMouseEnter={() => setShowExtra(true)}
                        // onMouseLeave={() => setShowExtra(false)}
                        onClick={(e) => {
                            e.stopPropagation()
                            onClick({ value: option.value, keyName: option.keyName })
                        }}
                        className={styles.options}
                    >
                        {option.label}
                        {isMultiple && <span className={styles.checkbox}><CheckBox onChange={() => onClick({ value: option.value, keyName: option.keyName })} checked={isChecked} /></span>}
                        <>
                            {!!deleteModal ?
                                onDelete && <DeleteIcon className={styles.delete} onClick={ondelete} />
                                :
                                onDelete && <div> 
                                    <EditIcon stroke={'#000'} className={styles.edit} onClick={onEditItem} />
                                    <DeleteIcon className={styles.delete} onClick={onDeleteItem} />
                                </div>
                            }
                        </>
                        {
                        }

                    </div>
                    <div className={styles.extraWrapper}>
                        {option.options &&
                            option.options?.length > 0 &&
                            <div className={styles.extraMenu}>
                                {option.options.map((item) => {
                                    return <Item key={item.value} option={item} onClick={() => onClick({ value: item.value, keyName: item.keyName })} />
                                })
                                }
                            </div>}
                    </div>
                </div>
            </div>
            {isShowModal && !!deleteModal && <ModalClose classes={{
                modal: styles.modal
            }} onClose={() => setisShowModal(false)} >
                <h3>Внимание</h3>
                <p>{deleteModal}</p>
                <div className={styles.brtnWrapper}>
                    <button onClick={() => setisShowModal(false)} className={styles.close}>Отмена</button>
                    <button onClick={onDeleteItem} className={styles.delete}>Удалить</button>
                </div>
            </ModalClose >}
        </>

    )
}
export default Item