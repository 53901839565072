import classNames from 'classnames';
import React, { FC } from 'react';
import { TextLine } from '../../../../simples/TextLine';
import styles from './ExcurtionItem.module.scss'



type Props = {
    onClick: () => void
    isActive: boolean
    name: string
    color: string
}

const ExcurtionItem: FC<Props> = ({ onClick, isActive, name, color }) => {
    return (
        <div
            className={classNames(styles.root, {
                [styles.active]: isActive
            })}
            onClick={onClick}>
            <span
                style={{
                    backgroundColor: color
                }}
                className={styles.color}
            ></span>
            <span>{name}</span>
        </div>
    )
}
export default React.memo(ExcurtionItem)