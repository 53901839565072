import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import { Button, ButtonColors } from '../../simples/Button';
import styles from './AddButton.module.scss'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'



type Props = RouteProps &{
    onClick: () => void
}

const AddButton:FC<Props> = ({onClick, children}) => {
 return (
    <Button className={styles.addBtn} onClick={onClick} color={ButtonColors.Secondary}><PlusIcon /> <span className={styles.addBtnTaxt}>{children}</span></Button>
)}
export default React.memo(AddButton)