import axios, { AxiosRequestConfig } from 'axios'
import { BASE_URL } from '../constants/location'

const config: AxiosRequestConfig = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
}
config.baseURL = BASE_URL.API_URL
// if (process.env.REACT_APP_API_URL) {
//     config.baseURL = process.env.REACT_APP_API_URL
// }

const instance = axios.create(config)

export default instance
