import React, { FC, useCallback, useState } from 'react';
import styles from './Layout.module.scss'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as Excursions } from '../../../assets/icons/excurtions.svg'
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/editor.svg'
import { ReactComponent as NoteIcon } from '../../../assets/icons/note.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/user.svg'
import { ReactComponent as LogOutIcon } from '../../../assets/icons/log_out.svg'

import { Routes } from '../../../constants/config';
import MenuItem, { MenuItemType } from './parts/MenuItem/MenuItem';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { logout, selectUser } from '../../../store/slice/auth';
import { RouteProps } from 'react-router-dom';
import { MainPreloader } from '../../simples/MainPreloader';
import { selectIsLoading } from '../../../store/slice/app';
import { ScrollBlock } from '../../simples/ScrollBlock';
import { TextLine } from '../../simples/TextLine';
import { UserRole } from '../../../types/types';
import { NoteModal } from '../NoteModal';
import { useGetNotesQuery, useReadNoteMutation } from '../../../services/NoteService';



type Props = RouteProps & {
    title?: string
    loading?: boolean
    classes?: {
        content?: string
    }
    onEndReached?: () => void
}

const _MENU: MenuItemType[] = [
    {
        icon: <HomeIcon />,
        label: 'Главная',
        route: Routes.HOME
    },
    {
        icon: <Excursions />,
        label: 'Экскурсии',
        route: Routes.EXCURTIONS
    },
    {
        icon: <UsersIcon />,
        label: 'Пользователи',
        route: Routes.STAFF
    },
    {
        icon: <EditIcon />,
        label: 'Модерация',
        route: Routes.EDITOR
    },
    {
        icon: <NoteIcon />,
        label: 'Заявки',
        route: Routes.REQUESTS
    },
    {
        icon: <ProfileIcon />,
        label: 'Профиль',
        route: Routes.PROFILE
    },
]

const Layout: FC<Props> = ({ title, loading, classes, onEndReached, children }) => {
    const dispatch = useAppDispatch()
    // const isLoading = useAppSelector(selectIsLoading)
    const user = useAppSelector(selectUser)
    

    const [showNoteModal, setShowNoteModal] = useState<boolean>(false)
    const { data } = useGetNotesQuery({readed: 0},{
        pollingInterval: user?.role !== UserRole.ADMIN ? 10000 : 0
    })


    /**
 * open note to read
 */
    const onOpenNote = useCallback(() => {
        setShowNoteModal(true)
    }, [])

    /**
* close nite modal
*/
    const onHandleCloseModal = (isReaded?: {
        message: string,
        success: boolean
    }) => {
        setShowNoteModal(false)
    }
    /**
     * logout 
     */
    const onLogOut = useCallback(async () => {
        dispatch(logout())
        window.location.reload();
    }, [dispatch])

    return (
        <div className={styles.root}>

            <div className={styles.left}>
                <div className={classNames(styles.block, styles.top)}>
                    <img src={'logo.png'} alt={'Logo'} className={styles.logo} />
                </div>
                <div className={styles.leftMenuWrapper}>
                    <div className={styles.menu}>
                        {
                            _MENU.map((menuItem) => <MenuItem key={menuItem.route} data={menuItem} />)
                        }
                    </div>

                    <div className={styles.logOutBlock}>
                        <LogOutIcon className={styles.logOut} onClick={onLogOut} />
                    </div>

                </div>
            </div>
            <div className={styles.right}>
                <div className={classNames(styles.block, styles.top)}>
                    {(user?.role === UserRole.MANAGER && data) && <div className={styles.noteRead}>
                        <TextLine weight={500} onClick={onOpenNote} color='#DC3545'>У ваc не прочитанная заметка</TextLine>
                    </div>}
                </div>
                <div className={styles.innerContent}>

                    {children}

                </div>
            </div>

            {
                showNoteModal && data &&
                <NoteModal
                    onClose={onHandleCloseModal}
                    note={data}
                />
            }

        </div>
    )
}
export default Layout
