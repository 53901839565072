import classNames from 'classnames';
import React, { FC, forwardRef, useMemo, useState } from 'react';
import styles from './Input.module.scss'
import InputMask from 'react-input-mask';
import { ReactComponent as Eye } from '../../../assets/icons/eyePassword.svg'
import { validInputNumber } from '../../../constants/helper';
export enum TypeInput {
    PASSWORD = 'password',
    NUMBER = 'number',
    NONE = 'none'
}
export type InputProps = {
    onChange: (e: string) => void
    value: string
    disabled?: boolean
    placeholder?: string
    classes?: {
        input?: string
    }
    mask?: string | any
    error?: string | undefined
    label?: string
    ml?: number
    before?: string
    typeProps?: TypeInput
}
const Input: FC<InputProps> = forwardRef<HTMLInputElement & InputMask ,InputProps>(({
    onChange,
    value,
    disabled,
    placeholder,
    classes,
    mask,
    label,
    before,
    error,
    ml,
    typeProps = TypeInput.NONE
}, ref) => {
    const [pass, SetPass] = useState<boolean>(true)

    const clickEye = () => {
        SetPass(prev => !prev)
    }
    const typeFun = useMemo((): TypeInput => {
        return (!pass && typeProps === TypeInput.PASSWORD) ? TypeInput.NONE : typeProps
  
    }, [typeProps, pass])
    const modeinput = (str: string) => {
        if (typeProps === TypeInput.NUMBER) {
            onChange(validInputNumber(str))
        }
        else {
            onChange(str)
        }
    }
    return (
        <div className={styles.root}>
            {label && <div className={styles.label}>{label}</div>}
            {
                <div className={classNames(styles.inputWrapper, {
                    [styles.error]: !!error
                })}>
                    {before && <span className={styles.before}>{before}</span>}
                    {!!!mask ?
                        <div style={{
                            width: '100%'
                        }}>

                            <input
                            ref={ref}
                                type={typeFun}
                                maxLength={ml ?? ml}
                                disabled={disabled}
                                className={classNames(styles.input, {
                                    [styles.disabled]: disabled,
                                    // [styles.error]: !!error
                                }, classes?.input)}
                                placeholder={placeholder}
                                onChange={(e) => modeinput(e.target.value)}
                                value={value}
                                min={0}
                            />
                            {typeProps === TypeInput.PASSWORD && <Eye className={styles.eye} onClick={clickEye} />}
                        </div>
                        :
                        <InputMask
                        ref={ref}
                        disabled={disabled}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            mask={mask}
                            className={classNames(styles.input, {
                                [styles.disabled]: disabled,
                                // [styles.error]: !!error
                            }, classes?.input)} />
                    }

                </div>
            }
            {error && <div className={styles.errorText}>{error}</div>}
        </div>
    )
})

export default Input;
