import React, { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScreenWrapper } from '../../complexes/ScreenWrapper';
import { Button, ButtonColors } from '../../simples/Button';
import styles from './Excurtions.module.scss'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ScrollBlock } from '../../simples/ScrollBlock';
import { TripType, UserRole } from '../../../types/types';
import { TripsService } from '../../../services/TripsService';
import { AddButton } from '../../complexes/AddButton';
import { TopFilter } from './parts/TopFilter';
import { arrayToQuery } from '../../../constants/helper';
import { TripCard } from './parts/TripCard';
import classNames from 'classnames';
import { useNavigate, useRoutes } from 'react-router-dom';
import { Routes } from '../../../constants/config';
import { useAppSelector } from '../../../hooks/store';
import { selectCities } from '../../../store/slice/storage';
import { selectUser } from '../../../store/slice/auth';



type Props = {

}

export type ExcurtionFilter = {

    cities: Array<string>
    tripTypes: Array<string>
}

export const FilterContext = createContext<[ExcurtionFilter, React.Dispatch<React.SetStateAction<ExcurtionFilter>>]>([
    {
        cities: [],
        tripTypes: []
    },
    () => { }
])

const Excurtions: FC<Props> = ({ }) => {

    const navigate = useNavigate()


    const [loading, setLoading] = useState<boolean>(false)

    const user = useAppSelector(selectUser)
    const cities = useAppSelector(selectCities)
    const [scrollBlockHeight, setScrollBlockHeight] = useState<number>(1000)

    const [filter, setFilter] = useState<ExcurtionFilter>({
        cities: [],
        tripTypes: []
    })

    const has = useRef(true)
    const offset = useRef(0)

    const [trips, setTrips] = useState<TripType[]>([])

    const currentTrips = useRef<Array<TripType>>(trips)
    currentTrips.current = trips

    /**
    * получение списка экскурсий
    */

    const selectCity = useMemo(() => {
        if (cities.length) {
            if (user!.role !== UserRole.MANAGER) {
                return filter.cities
            } else {
                return ['' + cities[0].id || '']
            }
        } else {
            return filter.cities
        }
    }, [user, cities, filter])
    // console.log(selectgit ity);

    const getTrips = useCallback(async () => {

        if (!has.current || loading) {
            return
        }
        setLoading(true)
        try {
            const response = await TripsService.getTrips({
                offset: offset.current,
                limit: 100,
                tripTypesIds: filter.tripTypes,
                published: 2,
                citiesIds: selectCity

            })

            if (!response.length) {
                has.current = false
                setLoading(false)
                return
            }

            offset.current = offset.current + response.length

            setTrips([...currentTrips.current, ...response])
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }

    }, [filter.cities, filter.tripTypes, loading])

    const clear = () => {
        has.current = true
        setTrips([])
        currentTrips.current = []
        setLoading(false)
        offset.current = 0

        getTrips().then()

    }

    useEffect(() => {
        clear()
    }, [filter.cities, filter.tripTypes])


    const onAddTrip = useCallback(() => {
        navigate(Routes.EXCURTION_CREATE)
    }, [navigate])

    /**
     * open trip
     */
    const onClickOnTrip = useCallback((id: number) => {
        return navigate(`${Routes.EXCURTION_EDIT}?id=${id}`, { state: {}, replace: false, });

    }, [navigate])
    /**
     * open trip
     */
    const onDeleteTrip = useCallback(async (id: number) => {

        const response = await TripsService.deleteTrip(id)
        if (response) {
            setTrips(prev => prev.filter(i => i.id !== id))
        }


    }, [])


    const rootRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        if (window !== undefined) {
            setScrollBlockHeight(window.innerHeight - 240);

        }
    }, [])

    return (
        <FilterContext.Provider value={[filter, setFilter]}>
            <ScreenWrapper
                isLoading={loading}
                title={'Экскурсии'}
                topRightSide={<TopFilter onAddTrip={onAddTrip} />}
            >

                <ScrollBlock
                    height={scrollBlockHeight}
                    className={classNames(styles.content)}
                    onReachedEnd={getTrips}
                >
                    <div className={styles.cardsBlock}>
                        {
                            trips.map((trip, index) => <TripCard key={trip.id} trip={trip} onClick={onClickOnTrip} onDelete={onDeleteTrip} />)
                        }
                    </div>

                </ScrollBlock>

            </ScreenWrapper>
        </FilterContext.Provider>
    )
}
export default Excurtions