import { Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import { Profile, ProfileTab, Sort, StaffType, UserRole } from "../types/types";
import api from "../utils/api";
import { sendFormData } from '../constants/helper'


export class ProfileService {
    static async getUsers(): Promise<Profile> {
        const response = await api.get(
            `${Endpoints.GET_USER}`
        );

        return response.data.data;
    }
    static async getTabText(): Promise<ProfileTab[]> {
        const response = await api.get(
            `${Endpoints.GET_PROFILE}`
        );

        return response.data.data;
    }
    static async updateTabTextItem(id: number, data: ProfileTab): Promise<any> {
        const response = await api.post(Endpoints.UPDATE_PROFILE + id, sendFormData(data))
        return response.data.data;
    }


}
