import React, { FC } from 'react'
import { prindTimeDate, thousand } from '../../../../../constants/helper'
import { OrdersTimeType, TripStateType } from '../../../../../types/types'
import { TextLine } from '../../../../simples/TextLine'
import styles from './SalesTable.module.scss'

type Props = {
    data: OrdersTimeType
}

const TableItem: FC<Props> = ({ data }) => {

    return (
        <>
            {data && <tr className={styles.saleItem}>
                <td className={styles.saleItemCell}>
                    <TextLine display={'block'} size={12} weight={500}>{data?.trip?.name || ''}</TextLine>
                    <TextLine color={'rgba(19, 26, 47, 0.7)'} display={'block'} size={11} weight={500}>Город {data?.trip?.guide?.city?.name || ''}</TextLine>
                </td>
                <td className={styles.saleItemCell}>
                    <span>{`${thousand(data?.trip?.pricePerAdult || 0)} ₽`}</span>
                    <span>{" "}({data?.trip?.pricePerChild})</span>
                </td>
                <td className={styles.saleItemCell}>{data?.adults + data?.children}  {data?.adults + data?.children > data?.trip?.adults ? '' : `/ ${data?.trip?.adults}`} </td>
                <td className={styles.saleItemCell}>{`${thousand(data?.price || 0)} ₽`}</td>
                <td className={styles.saleItemCell}>
                    <TextLine display={'block'} size={12} weight={500}>{prindTimeDate(data?.tripDate || '').split(" ")[0]}{' '}{data?.tripTime}</TextLine>
                </td>
            </tr>}
        </>
    )
}

export default React.memo(TableItem)