import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as RoutesDom
} from "react-router-dom";
import styles from "./App.module.scss";
import { Routes } from "../../constants/config";

import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getUser,
  selectAuthChecking,
  selectIsAuth
} from "../../store/slice/auth";
import { MainPreloader } from "../simples/MainPreloader";
import { Auth } from "../pages/Auth";
import { Layout } from "../complexes/Layout";
import { MainPage } from "../pages/MainPage";
import { Staff } from "../pages/Staff";
import {
  fetchCities,
  fetchGuids,
  fetchRole,
  fetchTransferTypes,
  fetchTripTypes
} from "../../store/slice/storage";
import { Excurtions } from "../pages/Excurtions";
import { EditExcurtion } from "../pages/EditExcurtion";
import Moderation from "../pages/Moderation/Moderation";
import { useGetWidgetsQuery } from "../../services/WidgetService";
import { Requests } from "../pages/Requests";
import { Profile } from "../pages/Profile";

const App = () => {
  const preloading = useAppSelector(selectAuthChecking);
  const dispatch = useAppDispatch();


  const isAuth = useAppSelector(selectIsAuth);

  useEffect(
    () => {
      
      let isRemember = localStorage.getItem("remember");
      if (isRemember === "yes") {
        dispatch(fetchCities());
        dispatch(fetchTripTypes());
        dispatch(fetchGuids());
        dispatch(fetchTransferTypes());
        dispatch(fetchRole());
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("remeber");

      }

      dispatch(getUser()).then();
     
    },
    [dispatch, isAuth]
  );

  if (preloading) {
    return <MainPreloader />;
  }

  return (
    <div className={styles.app}>
      <Router>
        {!isAuth
          ? <RoutesDom>
            <Route path={Routes.AUTH} element={<Auth />} />
            <Route path="*" element={<Navigate replace to={Routes.AUTH} />} />
          </RoutesDom>
          : <Layout>
            <RoutesDom>
              <Route path={Routes.HOME} element={<MainPage />} />
              <Route path={Routes.EXCURTIONS} element={<Excurtions />} />
              <Route
                path={Routes.EXCURTION_CREATE}
                element={<EditExcurtion />}
              />
              <Route
                path={`${Routes.EXCURTION_EDIT}`}
                element={<EditExcurtion />}
              />
              <Route path={`${Routes.STAFF}`} element={<Staff />} />
              <Route path={`${Routes.EDITOR}`} element={<Moderation />} />
              <Route path={`${Routes.REQUESTS}`} element={<Requests />} />
              <Route path={`${Routes.PROFILE}`} element={<Profile />} />

              <Route
                path="*"
                element={<Navigate replace to={Routes.HOME} />}
              />
            </RoutesDom>
          </Layout>}
      </Router>
    </div>
  );
};

export default App;
