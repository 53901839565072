import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { getSendDate } from '../../../../../constants/helper';
import { BlockWrapper } from '../../../../simples/BlockWrapper';
import { Button } from '../../../../simples/Button';
import styles from './DatePickerBlock.module.scss'



type Props = {
    isPeriod: 1 | 0
    onChange: (dates: Array<string>) => void
    values: Array<string>
}

enum DateType {
    DATE = 'date',
    PERIOD = 'period'
}

const DatePickerBlock: FC<Props> = ({ isPeriod, onChange, values }) => {

    const [dateRange, setDateRange] = useState<[any, any]>([null, null]);

    // const [highligts, setHightlights] = useState<Array<string>>(values)

    // useEffect(() => {
    //     setHightlights(values)
    // },[values])
    const [startDate, endDate] = dateRange;

    const getDateFromRange = (startDate: any, endDate: any) => {
        const date = new Date(startDate.getTime());

        const dates: string[] = [];

        while (date <= endDate) {
            dates.push(getSendDate(new Date(date)));
            date.setDate(date.getDate() + 1);
        }

        onChange(dates);
    }


    const getDay = (date: any) => {
        const dayNumber = new Date(date).getDay()
        return dayNumber === 6 || dayNumber === 0 ? 'weekend' : 'workday'
    }

    useEffect(() => {

        if (startDate && endDate) {
            getDateFromRange(startDate, endDate)
        }

    }, [endDate, startDate])

    useEffect(() => {
        if(isPeriod){
            const sDate: string = values[0]
            const eDate: string = values[values.length - 1]
            setDateRange([new Date(sDate), new Date(eDate)])
        } else {

        }
    },[isPeriod])

    const onChangeDate = (date: Date) => {
        if(values.includes(getSendDate(date))){
            onChange(values.filter(i => i !== getSendDate(date)))
        } else {
            onChange([...values, getSendDate(date)])
        }
    }


    
    
    return (

        <div className={styles.root}>
            <div className={styles.block}>
                <div className={styles.calendarBlock}>
                    {
                        !!isPeriod ?
                            <DatePicker
                                inline
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update: any) => {
                                    setDateRange(update);
                                }}
                                dateFormat={('dd-MM-yyyy')}
                                isClearable={true}
                                locale={'ru'}
                                dayClassName={getDay}
                            />
                            :
                            <DatePicker
                                inline
                                selected={values && values.length > 0 ? new Date(values[values.length - 1]) : new Date()}
                                // startDate={}
                                onChange={onChangeDate}
                                highlightDates={values.map(i => new Date(i))}
                                locale={'ru'}
                            />
                    }
                </div>

            </div>
        </div>


    )
}
export default DatePickerBlock