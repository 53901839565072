import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CityService } from '../../services/CityService'
import { TRansferService } from '../../services/TransferService'
import { TripTypesService } from '../../services/TripTypesService'
import { UsersService } from '../../services/UsersService'
import { CityType, ProfileType, TransferType, TripTypesType, UserRole } from '../../types/types'
import { RootState } from '../index'

type State = {
    cities: Array<CityType>
    tripTypes: Array<TripTypesType>
    guids: Array<ProfileType>
    transferTypes: Array<TransferType>
    role: Array<UserRole>
}

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState: State = {
    cities: [],
    tripTypes: [],
    guids: [],
    transferTypes: [],
    role: []
}

/**
 * получение городов
 */
export const fetchCities = createAsyncThunk('storage/fetchCities',
    async () => {
        let data: Array<CityType> = []
        let isLoad = true
        let offset = 0

        while (isLoad) {
            const response = await CityService.fetchCities({ offset })
            if (response.length === 0) {
                isLoad = false
            }
            offset += response.length
            data.push(...response)
        }

        return data
    })
/**
 * получение role
 */
export const fetchRole = createAsyncThunk('storage/fetchRole',
    async () => {
        try {
            const response = await UsersService.getRoles()
            return response;
        } catch (error) {
            return []
        }
    })
/**
 * получение городов
 */
export const fetchTransferTypes = createAsyncThunk('storage/fetchTransferTypes',
    async () => {
        let data: Array<TransferType> = []
        let isLoad = true
        let offset = 0

        while (isLoad) {
            const response = await TRansferService.fetchTransferTypes({ offset })
            if (response.length === 0) {
                isLoad = false
            }
            offset += response.length
            data.push(...response)
        }

        return data
    })
/**
 * получение типов экскурсий
 */
export const fetchTripTypes = createAsyncThunk('storage/fetchTripTypes',
    async () => {
        let data: Array<TripTypesType> = []
        let isLoad = true
        let offset = 0

        while (isLoad) {
            const response = await TripTypesService.fetchTripTypes({ offset })
            if (response.length === 0) {
                isLoad = false
            }
            offset += response.length
            data.push(...response)
        }

        return data
    })

export const fetchGuids = createAsyncThunk('storage/fetchGuids',
    async () => {
        let data: Array<ProfileType> = []
        let isLoad = true
        let offset = 0

        while (isLoad) {
            const response = await UsersService.getUsers({ role: UserRole.GUID, offset })
            if (response.length === 0) {
                isLoad = false
            }
            
            offset += response.length
            data.push(...response)
        }
        return data
    })
export const createNewTripType = createAsyncThunk('storage/createNewTripType',
    async (name: string) => {
        const response = await TripTypesService.creteNewTripType(name)
        return response
    })
export const deleteTripType = createAsyncThunk('storage/deleteTripType',
    async (id: number) => {
        const response = await TripTypesService.deleteType(id)
        return id
    })
export const createNewCity = createAsyncThunk('storage/createNewCity',
    async (name: string) => {
        const response = await CityService.createCity(name)
        return response
    })
export const deleteCity = createAsyncThunk('storage/deleteCity',
    async (id: number) => {
        const response = await CityService.deleteCity(id)
        return id
    })
export const editTransferType = createAsyncThunk('storage/editTransferType ',
    async (data: { name: string, id: string, svgIcon: string }) => {
        const response = await TRansferService.updateTransferType(+data.id, data.name, data.svgIcon)
        return response
    })
export const createNewTransferType = createAsyncThunk('storage/createNewTransferType',
    async (data: { name: string, svgIcon: string }) => {
        const response = await TRansferService.createTransferType(data.name, data.svgIcon)
        return response
    })

export const deleteTransferType = createAsyncThunk('storage/deleteTransferType',
    async (id: number) => {
        const response = await TRansferService.deleteTransfer(id)
        return id
    })



/**
 * Создание слайса App
 */
export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

        builder.addCase(fetchCities.fulfilled,
            (state, action: PayloadAction<CityType[]>) => {
                state.cities = action.payload
            })
        builder.addCase(fetchTransferTypes.fulfilled,
            (state, action: PayloadAction<TransferType[]>) => {
                state.transferTypes = action.payload
            })
        builder.addCase(createNewTransferType.fulfilled,
            (state, action: PayloadAction<TransferType>) => {
                state.transferTypes = [...state.transferTypes, action.payload]
            })
        builder.addCase(editTransferType.fulfilled,
            (state, action: PayloadAction<TransferType>) => {
                state.transferTypes = state.transferTypes.filter(item => item.id !== action.payload.id)
                state.transferTypes = [...state.transferTypes, action.payload]
            })
        builder.addCase(deleteTransferType.fulfilled,
            (state, action: PayloadAction<number>) => {
                state.transferTypes = state.transferTypes.filter(i => i.id !== action.payload)
            })
        builder.addCase(createNewCity.fulfilled,
            (state, action: PayloadAction<CityType>) => {
                state.cities = [...state.cities, action.payload]
            })
        builder.addCase(deleteCity.fulfilled,
            (state, action: PayloadAction<number>) => {
                state.cities = state.cities.filter(i => i.id !== action.payload)
            })
        builder.addCase(createNewCity.rejected,
            () => {
                alert('Нельзя создать город с таким имененм. Возможно город уже существует.')

            })
        builder.addCase(fetchTripTypes.fulfilled,
            (state, action: PayloadAction<TripTypesType[]>) => {
                state.tripTypes = action.payload
            })
        builder.addCase(createNewTripType.fulfilled,
            (state, action: PayloadAction<TripTypesType>) => {
                state.tripTypes = [...state.tripTypes, action.payload]
            })
        builder.addCase(deleteTripType.fulfilled,
            (state, action: PayloadAction<number>) => {
                state.tripTypes = state.tripTypes.filter(i => i.id !== action.payload)
            })
        builder.addCase(fetchGuids.fulfilled,
            (state, action: PayloadAction<ProfileType[]>) => {
                state.guids = action.payload
            })
        builder.addCase(fetchRole.fulfilled,
            (state, action: PayloadAction<UserRole[]>) => {
                state.role = action.payload
            })
    }
})




export const {
} = storageSlice.actions
export const selectCities = (state: RootState) => state.storage.cities
export const selectTripTypes = (state: RootState) => state.storage.tripTypes
export const selectGuids = (state: RootState) => state.storage.guids
export const selectTransferTypes = (state: RootState) => state.storage.transferTypes
export const selectRole = (state: RootState) => state.storage.role
// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default storageSlice.reducer
