import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Endpoints } from "../constants/constants";
import { sendFormData, toQueryString } from "../constants/helper";
import {  GuideType, OrdersTimeType, OrderType, PeriodSaleType, RequestsType, TripType } from "../types/types";

import api from "../utils/api";

type GetOrdersType = {
    userId?: number
    tripId?: number
    dateFrom?: string
    dateTo?: string
    offset?: number;
    limit?: number;
    period?: PeriodSaleType
}

export class OrderService {
  /**
     * get orders
     */
  static async getOrders(data: GetOrdersType): Promise<Array<OrderType>> {
    const response = await api.get(
      `${Endpoints.OREDERS}${toQueryString(data)}`
    );
    return response.data.data;
  }
  /**
     * get orders items
     */
  static async getOrdersItems(data:  GetOrdersType): Promise<Array<OrdersTimeType>> {
    const response = await api.get(
      `${Endpoints.ORDERS_ITEM}${toQueryString(data)}`
    );
    return response.data.data;
  }
}

// export const ordersApi = createApi({
//     reducerPath: 'ordersApi',
//     baseQuery: fetchBaseQuery({
//         baseUrl: '/',
//         prepareHeaders: (headers, { getState }) => {
//             const token = localStorage.getItem('accessToken')
//             if (token) {
//                 headers.set('Authorization', `${token}`)
//             }
//             return headers
//         },
//     }),
//     tagTypes: ['Orders'],

//     endpoints: (builder) => ({
//         getOrders: builder.query({
//             query: (data: GetOrdersType) => `${Endpoints.OREDERS}${toQueryString(data)}`,
//             transformResponse: (rawResult: { data: OrderType[] }, meta) => {
//                 return rawResult.data
//             },
//             providesTags: ['Orders']
//         }),
    

//     })
// })

// export const {useGetOrdersQuery} = ordersApi

