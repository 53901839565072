import classNames from 'classnames';
import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import { MainPreloader } from '../../simples/MainPreloader';
import { ScrollBlock } from '../../simples/ScrollBlock';
import styles from './ScreenWrapper.module.scss'



type Props = RouteProps & {
    isLoading?: boolean
    title?: string
    topRightSide?: JSX.Element
    classes?: {
        content?: string
        root?: string
    }
    onEndReached?: () => void
}

const ScreenWrapper: FC<Props> = ({ isLoading, title, topRightSide, classes, onEndReached, children }) => {
    return (
        <div className={classNames(styles.root, classes?.root)}>
            <div className={styles.top}>
                <h1 className={styles.title}>{title}</h1>
                <div className="">
                    {topRightSide}
                </div>
            </div>
            <div className={classNames(styles.content, classes?.content)}>
                {isLoading && <MainPreloader />}

                {children}

                
            </div>
        </div>
    )
}
export default ScreenWrapper