import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './BlockWrapper.module.scss'



type Props = RouteProps & {
 isFull?: boolean
}

const BlockWrapper: FC<Props> = ({isFull, children}) => {
    return (
        <div style={{width: isFull ? '100%' : ''}} className={styles.root}>
            <div className={styles.content}>
            {children}
            </div>
        </div>
    )
}
export default React.memo(BlockWrapper)