import classNames from 'classnames'
import React, {
    ChangeEvent,
    FC,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import styles from './Dropdown.module.scss'
import { ReactComponent as Mark } from '../../../assets/icons/drop.svg'

import Item from './Item'
import { Button, ButtonColors, ButtonVariants } from '../Button'
import { ModalClose } from '../ModalClose'


export type OptionType = {
    label: string
    value: string
    keyName?: string
    options?: Array<OptionType>
}
type Props = {
    error?: string
    label?: string
    placeholder?: string
    value: string[]

    keyValue?: {
        value: string
        keyName: string
    }
    options: Array<OptionType>
    onChange: (data: {
        value: string,
        keyName?: string
    }) => void
    isOdd?: boolean
    disabled?: boolean
    icon?: JSX.Element
    labelInner?: string
    cascade?: boolean
    classes?: {
        root?: string
        label?: string
        drop?: string
    }
    isMultiple?: boolean
    onAdd?: (name: string) => Promise<boolean>
    withSearch?: boolean
    searchPlaceholder?: string
    rightLabel?: JSX.Element

    onSearch?: (value: string) => void
    onRechedEnd?: () => void
    onDelete?: (value: string) => void
    onEdit?: (value: string) => void
    deleteModal?: string

}

/**
 *
 * @param label
 * @param placeholder
 * @param value
 * @param options
 * @param onChange
 * @param onAdd
 * @param withSearch
 * @param searchPlaceholder
 * @returns
 */
const Dropdown: FC<Props> = ({
    error,
    label,
    placeholder,
    onEdit,
    value,
    options,
    onChange,
    isOdd = false,
    disabled,
    icon,
    keyValue,
    labelInner,
    cascade,
    classes,
    isMultiple = false,
    onAdd,
    withSearch,
    searchPlaceholder,
    rightLabel,
    onSearch,
    onRechedEnd,
    deleteModal,
    onDelete
}) => {
    const [openStatus, setOpenStatus] = useState<boolean>(false)
    const optionsBlock = useRef<HTMLDivElement>(null)
    const mainBlock = useRef<HTMLDivElement>(null)

    const inputRef = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState<string>('')

    const onHandleChange = useCallback(
        (e: {
            value: string
            keyName?: string
        }) => {
            onChange(e)
            if (!isMultiple) {
                setOpenStatus(false)
            }
        },
        [isMultiple, onChange]
    )

    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        if (optionsBlock && optionsBlock.current) {
            optionsBlock.current.blur()
        }
    }, [])

    // Метод закрытия селектора при клике вне самого селектора
    const closeSelectOutOfBlock =
        (event: any) => {
            if (mainBlock && mainBlock.current) {
                // Проверка добавлена для устранения бага в Firefox
                if (!mainBlock.current.contains(event.target)) {
                    setOpenStatus(false)
                    loseFocus()
                }
            }
        }

    // Раскрытие пунктов меню с опциями при фокусе на селекторе
    const onHandleFocus = useCallback(() => {
        if (!disabled) setOpenStatus(true)
    }, [disabled])

    //закрытие по второму тапу
    const onToggleOpen = useCallback(() => {
        if (disabled) {
            return
        }
        setOpenStatus(!openStatus)
    }, [openStatus, disabled])

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [])

    useEffect(() => {
        if (openStatus && withSearch) {
            inputRef.current?.focus()
        }
    }, [withSearch, openStatus])






    const initLabel = useMemo(() => {
        let title: Array<string> = []
        options.map((i) => {
            if (value.includes(i.value)) {
                title.push(i.label)
            }
        })
        // title = options.find((i) => i.value === value)
        let t = labelInner || title?.join(', ') || placeholder
        return t
    }, [labelInner, options, placeholder, value])




    // search
    const onHandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (onSearch) {
            onSearch(e.target.value)
        }
        setSearch(e.target.value)
    }

    // filter options
    // const filterOptions: OptionType[] = useMemo((): OptionType[] => {
    //     return options.filter(i => i.label.toLowerCase().includes(search.toLowerCase()))
    // }, [options, search])

    // add new item
    const onHandleAdd = useCallback(() => {
        if (onAdd) {
            onAdd(search).then((data) => {
                if (data) {
                    setSearch('')
                    setOpenStatus(false)
                }
            })
        }
    }, [onAdd, search])

    const onHandleOpenoptions = (e: SyntheticEvent<HTMLDivElement>) => {
        if (disabled) return
        // e.stopPropagation(); 
        setOpenStatus((prev) => !prev)
    }
    const reached = useRef(false)
    /**
    *
    * dynamic pagination
    */
    const handleScroll = () => {
        if (!optionsBlock.current) {
            return
        }

        const contentHeight = optionsBlock.current.offsetHeight
        const scrollHeight = optionsBlock.current.scrollHeight

        const scrollTop = optionsBlock.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true
            onRechedEnd && onRechedEnd()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    return (
        <>
            {label && <div className={styles.labelTop}><div className={styles.label}>{label}</div>{rightLabel}</div>}
            <div
                ref={mainBlock}
                className={classNames(styles.select, classes?.root, {
                    [styles.disabled]: disabled,
                    [styles.error]: !!error
                })}
                onClick={onHandleOpenoptions}
            >
                <div className={styles.topLabel}>

                    <div className={classNames(styles.value, {
                        [styles.placeholder]: placeholder === initLabel
                    })}>{initLabel}</div>


                    {icon ?
                        <div className={classNames(styles.mark, {
                            [styles.activeMark]: openStatus,
                        })}>{icon}</div> :
                        <Mark
                            className={classNames(styles.mark, {
                                [styles.activeMark]: openStatus && !disabled,
                            })}
                        />}
                </div>
                {openStatus && !disabled && (

                    <div
                        ref={optionsBlock}
                        onScroll={handleScroll}
                        className={classNames(styles.drop, {
                            [styles.bgcOdd]: isOdd,
                            [styles.oneLevel]: !cascade
                        }, classes?.drop)}
                    >
                        {withSearch &&
                            <div onClick={(e) => { e.stopPropagation() }} className={styles.inputBlock}>
                                <input
                                    className={styles.input}
                                    value={search}
                                    placeholder={searchPlaceholder}
                                    // onFocus={() => setOpenStatus(true)}
                                    ref={inputRef}
                                    onChange={onHandleSearch}
                                />
                                {
                                    onAdd && search && <Button
                                        onClick={onHandleAdd}
                                        color={ButtonColors.Main}
                                        variant={ButtonVariants.Contained}>Добавить</Button>
                                }
                            </div>


                        }
                        {options.map((i) => (
                            <Item
                                deleteModal={deleteModal}
                                onDelete={onDelete}
                                onEdit={onEdit}
                                isChecked={value.includes(i.value)}
                                isMultiple={isMultiple}
                                key={i.value}
                                onClick={onHandleChange}
                                option={i}
                            />
                        ))}
                    </div>
                )}
            </div>
            {error && <div className={styles.errorText}>{error}</div>}

        </>
    )
}

export default Dropdown
