import React, { FC, useState } from "react";
import styles from "./StaffCard.module.scss";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { StaffType, UserRole } from "../../../../types/types";
import { ModalClose } from "../../../simples/ModalClose";
import classNames from "classnames";
type Props = {
  cardInfo: StaffType;
  onEdit: (id: number) => void
  onDelete: (id: number) => void
};
type RuRoles = {
  [key in UserRole]: string
}
const Translate: RuRoles | any = {
  'admin': 'Админ',
  'guide': 'Гид',
  'manager': 'Менеджер',
  'user': "Пользователь"
}

const StaffCard: FC<Props> = ({ cardInfo, onEdit, onDelete }) => {
  const [isShowModalClose, setIsShowModalClose] = useState<boolean>(false)
  const showModal = (flag: boolean) => {
    setIsShowModalClose(flag)
  }
  const delUser = () => {
    showModal(true)
  }
  return (
    <>
      <tr className={styles.cardInfoWrapper}>
        <td className={styles.cell}>
          {Translate[cardInfo.role]}
        </td>
        <td className={styles.cell}>
          {cardInfo?.firstName}
        </td>
        <td className={styles.cell}>
          {cardInfo?.phone}
        </td>
        <td className={styles.cell}>
          {cardInfo?.email}
        </td>
        <td className={styles.cell}>
          {cardInfo?.city?.name}
        </td>
        <td className={classNames(styles.cell, styles.iconCard)}>
          <span onClick={() => onEdit(cardInfo.id)}>
            {<EditIcon />}
          </span>
          <span onClick={delUser}>
            {<DeleteIcon />}
          </span>
        </td>
      </tr>
      {isShowModalClose &&
        cardInfo.role === UserRole.GUID && <ModalClose classes={{
          modal: styles.modalHard
        }} onClose={() => showModal(false)} >
          <h3>Внимание</h3>
          <p>Удаление приведёт к потере связанных экскурсий</p>
          <div className={styles.brtnWrapperHard}>
            <button onClick={() => showModal(false)} className={styles.closeHard}>Отмена</button>
            <button onClick={() => onDelete(cardInfo.id)} className={styles.deleteHard}>Удалить</button>
          </div>
        </ModalClose >}

      {isShowModalClose &&
        cardInfo.role !== UserRole.GUID && <ModalClose onClose={() => showModal(false)} classes={{
          modal: styles.modal
        }} >
          <span> Вы действительно хотите <br />удалить пользователя ?</span>
          <div className={styles.btnWrapper}>
            <button onClick={() => showModal(false)} className={classNames(styles.btn, styles.btnClose)}>ОТМЕНА</button>
            <button onClick={() => onDelete(cardInfo.id)} className={classNames(styles.btn, styles.btnDel)}>УДАЛИТЬ</button>
          </div>
        </ModalClose>}
    </>
  );
};

export default StaffCard;
