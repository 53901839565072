import classNames from "classnames";
import React, { FC, useCallback, useState } from "react";
import { DatePickerBlock } from "../../../../EditExcurtion/parts/DatePickerBlock";
import styles from "./DataPickerRequest.module.scss"
import DatePicker from 'react-datepicker';
import { getSendDate } from "../../../../../../constants/helper";

type Props = {
  onChange?: (e: string) => void
  value: string
  disabled?: boolean
  placeholder?: string
  classes?: {
    input?: string
  }
  mask?: string | any
  error?: string
  label?: string
  before?: string
  onSubmit: (date: string) => void
};

const DataPickerRequest: FC<Props> = ({
  onChange,
  value,
  disabled,
  placeholder,
  classes,
  mask,
  label,
  before,
  error,
  onSubmit
}) => {

  const [isShowDate, setIsShowDate] = useState<boolean>(false)
  const [dates, setDates] = useState<string>('')

  const [currentTrip, setCurrentTrip] = useState<any>({})
  const [startDate, setStartDate] = useState<Date | null>(null);


  const isShowDatePicker = (flag: boolean, del: number = 0) => {
    setIsShowDate(flag)
    if (del === 1) setDates('')


  }
  const selectDate = (e: string) => {
    setDates(e.split('-').reverse().join('.'))
    isShowDatePicker(false)
    onSubmit(e.split('-').reverse().join('.') || '')
  }

  const checkDate = useCallback((date: Date) => {
    setStartDate(date)
    onSubmit(getSendDate(date))
  },[onSubmit])


  return (
    <div className={styles.wrapperPickerDate}>
      <div className={styles.root}>
     
        {label && <div className={styles.label}>{label}</div>}
        {
          <div className={styles.inputWrapper}>
            {before && <span className={styles.before}>{before}</span>}

            <DatePicker
            placeholderText="Выберите дату"
              selected={startDate}
              onChange={checkDate}
              dateFormat={'dd.MM.yyyy'}
              highlightDates={[startDate || new Date()]}
              locale={'ru'}
            />
            {/* <input
                      
                      onFocus={() => isShowDatePicker(true, 1)}
                      disabled={disabled}
                      className={classNames(styles.input, {
                          [styles.disabled]: disabled,
                          [styles.error]: !!error
                      }, classes?.input)}
                      placeholder={placeholder}
                      // onChange={(e) => onChange(e.target.value)}
                      value={dates} /> */}

          </div>
        }
        {error && <span className={styles.errorText}>{error}</span>}
      </div>
    </div>
  )

};

export default DataPickerRequest;

function createDates(dates: Array<string>, times: Array<string>) {
  let sendTimes: Array<{
    date: string
    times: Array<string>
  }> = []

  sendTimes = dates.map((date) => ({
    date,
    times
  }))
  return JSON.stringify(sendTimes)
}