import { Endpoints } from "../constants/constants"
import { toQueryString } from "../constants/helper"
import { appSlice } from "../store/slice/app"
import { CityType, LocationType, TransferType } from "../types/types"
import api from '../utils/api'


export class LocationService {

    /**
     * get notes
     */
    static async getTransferNotes(data: {
        name: string
        cityId: number
        offset?: number
        limit?: number
    }): Promise<LocationType[]> {
        const response = await api.get(`${Endpoints.LOCATION_NOTES}${toQueryString(data)}`)
        return response.data.data
    }
}