import { FC, useCallback, useState } from 'react';
import { ScreenWrapper } from '../../complexes/ScreenWrapper';
import styles from './MainPage.module.scss'
import { BlockWrapper } from '../../simples/BlockWrapper';
import { Widget } from '../../complexes/Widget';
import { useAppSelector } from '../../../hooks/store';
import { selectUser } from '../../../store/slice/auth';
import { IsAll, NoteType, UserRole, WidgetFilterBy, WidgetKind, WidgetType } from '../../../types/types';
import { Note } from '../../complexes/Note';
import { SalesTable } from './parts/SalesTable';
import { AddWidgetModal } from '../../complexes/AddWidgetModal';
import { EditExcurtionWidgetModal } from '../../complexes/EditExcurtionWidgetModal';
import { AddButton } from '../../complexes/AddButton';
import { useCreateWidgetMutation, useDeleteWidgetMutation, useGetWidgetsQuery } from '../../../services/WidgetService';
import { NoteModal } from '../../complexes/NoteModal';

const _INIT_WIDGET: WidgetType = {
    type: WidgetKind.TRIPS,
    cityIds: [],
    allCities: IsAll.NOT_ALL,
    guideIds: [],
    allGuides: IsAll.NOT_ALL,
    tripIds: [],
    allTrips: IsAll.NOT_ALL,
    tripTypesIds: [],
    allTripTypes: IsAll.NOT_ALL,
    filterBy: WidgetFilterBy.SUMM,
    data: [],
    startDate: '',
    endDate: ''
}

type Props = {

}

enum WidgetTypeCreate {
    GUID_TYPE = 'guid',
    EXCURTION_TYPE = 'excurtion'
}

const Main: FC<Props> = ({ }) => {


    const { data = [] } = useGetWidgetsQuery('', {})
    const [createWidget, result] = useCreateWidgetMutation()
    const [deleteWidget] = useDeleteWidgetMutation()

    // const widgets = useAppSelector(selectWidgets)
    const user = useAppSelector(selectUser)

    const [addWidgetModalShow, setAddWidgetModalShow] = useState<boolean>(false)
    const [createWidgetType, setCreateWidgetType] = useState<WidgetKind>()

    const onClickExcurtion = useCallback(() => {
        setCreateWidgetType(WidgetKind.TRIPS)
    }, [])

    const onClickGuid = useCallback(() => {
        setCreateWidgetType(WidgetKind.GUID)
    }, [])

    const onClose = useCallback(() => {
        setAddWidgetModalShow(false)
    }, [])

    /**
     * open modal for adding widget
     */

    const onAddWidget = useCallback(() => {
        setAddWidgetModalShow(true)
    }, [])

    /**
     * create widget
     */
    const onCreateTripWidget = useCallback(async (data: WidgetType) => {

        await createWidget(data)
        setCreateWidgetType(undefined)
        setAddWidgetModalShow(false)

    }, [createWidget])

    /**
     * delete widget
     */
    const onDeleteWidget = useCallback(async (id: number) => {
        deleteWidget(id)
    }, [deleteWidget])

    // console.log('main page data', data)

    return (
        <ScreenWrapper
            title={'Главная'}
            topRightSide={<AddButton onClick={onAddWidget}>Добавить виджет</AddButton>}
        >
            {user && <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
                <div className="">
                    <div className={styles.widgetWrapper}>
                        <div className={styles.widget}>
                            <BlockWrapper>
                                <Widget onDelete={data.length > 1 ? onDeleteWidget : undefined} widget={data.length > 0 ? data[0] : _INIT_WIDGET} />
                            </BlockWrapper>
                        </div>
                        {user?.role === UserRole.ADMIN && <BlockWrapper>
                            <Note />
                        </BlockWrapper>}
                    </div>
                    {
                        data?.length > 1 && data.slice(1)?.map((item, index) => {

                            return (
                                <div key={item.id} className={styles.widgetWrapper}>
                                    <BlockWrapper isFull>
                                        <Widget onDelete={onDeleteWidget} widget={item} />
                                    </BlockWrapper>
                                </div>
                            )

                        })
                    }

                </div>

                <div className="">
                    <BlockWrapper>
                        <SalesTable />
                    </BlockWrapper>
                </div>


                {
                    addWidgetModalShow && <AddWidgetModal
                        onClickExcurtion={onClickExcurtion}
                        onClickGuid={onClickGuid}
                        onClose={onClose}
                    />
                }
                {
                    createWidgetType !== undefined && <EditExcurtionWidgetModal
                        widgetFilter={{ ..._INIT_WIDGET, type: createWidgetType }}
                        onClose={() => setCreateWidgetType(undefined)}
                        onSubmit={onCreateTripWidget} />
                }
            </div>}
        </ScreenWrapper>
    )
}
export default Main