import React, { FC, useCallback, useEffect } from 'react';
import styles from './DropZone.module.scss'
// import Dropzone from 'react-dropzone-uploader'
import { useDropzone } from 'react-dropzone'
import { TextLine } from '../../simples/TextLine';
import classNames from 'classnames';
import { ReactComponent as ImageIcon } from '../../../assets/icons/uploadImage.svg'
import { ImageType } from '../../../types/types';
import { MAX_UPLOADING_PHOTO_SIZE } from '../../../constants/config';



type Props = {
  label?: string
  maxFiles?: number
  onUpload: (files: Array<File>) => void
  disabled?: boolean
}

const DropZone: FC<Props> = ({ label, maxFiles, onUpload, disabled }) => {
  const handleSubmit = (files: any, allFiles: any) => {
    // allFiles.forEach(f => f.remove())
  }

  //size validation
  const sizeValidator = (file: File) => {
    if (file.size > MAX_UPLOADING_PHOTO_SIZE) {
      return {
        code: "size-too-large",
        message: `Размер файла превышает 2мб`
      };
    }
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    !disabled && onUpload(acceptedFiles);

    // Do something with the files
  }, [onUpload])

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles,
    //@ts-ignore
    validator: sizeValidator
  })

  const fileRejectionItems = () => {
    fileRejections.map(({ file, errors }) => {
      const messages = errors.map(i => i.message).join('\n')
      alert(messages)
    });
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejectionItems()
    }

  }, [fileRejections])


  return (
    <div className={styles.root}>

      {label && <div className={styles.label}>{label}</div>}

      <div className={classNames(styles.container, {
        [styles.activeContainer]: isDragActive,
        [styles.reject]: isDragReject,
        [styles.disabled]: disabled
      })} {...getRootProps()}>
        <input  {...getInputProps()} />
        <div className={styles.contentInner}>
          <ImageIcon />

          {
            isDragActive ?
              <div className="">
                {isDragAccept && !disabled && <p>Опустите файлы сюда</p>}
                {isDragReject && <p>Файлы не поддерживаются</p>}
              </div>
              :
              <div className="">
                <p><TextLine weight={600}>Перетащите ваше изображение сюда</TextLine></p>
                <p><TextLine weight={600}>или</TextLine> <TextLine weight={600} color='red'>кликните на ссылку</TextLine></p>
              </div>
          }
        </div>
      </div>

    </div>
  )
}
export default DropZone

