import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Button } from "../../../../../simples/Button";
import { Input } from "../../../../../simples/Input";
import { ItemHolder } from "../../../../../simples/ItemHolder";
import { ModalCloseRequests } from "../ModalCloseRequests";

import styles from "./RequestsModal.module.scss";
import { Dropdown } from "../../../../../simples/Dropdown";
import { OptionType } from "../../../../../simples/Dropdown/Dropdown";
import { CheckBox } from "../../../../../simples/CheckBox";
import classNames from "classnames";
import { DataPickerRequest } from "../DataPickerSpecialForModalRequest";
import { RequestsService } from "../../../../../../services/RequestsService";
import { BusyGuid, ProfileType, TripType, UserRole } from "../../../../../../types/types";
import { useAppSelector } from "../../../../../../hooks/store";
import { selectCities, selectGuids, selectRole } from "../../../../../../store/slice/storage";
import { TypeInput } from '../../../../../simples/Input/Input'
import { RequestContext } from "../../../RequestContext";
import produce from "immer";
import { selectUser } from "../../../../../../store/slice/auth";
import { UsersService } from "../../../../../../services/UsersService";
type Props = {
  onClose: () => void;
  title?: string;
};
export type FilterTypeRequestModal = {
  tripTypes: string;
  date: string;
  guide: string;
  quantityOld: string;
  quantityJunior: string;
  buyOnPlace: boolean;
  cost: string;
  time: string;
  numberGuide: string;
  numberForBuy: string;
};
type RefCostType = {
  costChild: number
  costAdult: number
}
const RequestsModal: FC<Props> = ({ onClose, title }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<OptionType[]>([]);
  const [guide, setGuide] = useState<OptionType[]>([]);
  const [widget, setWidget] = useState<FilterTypeRequestModal>({} as FilterTypeRequestModal);
  const [requests, setRequests] = useContext(RequestContext)


  const user = useAppSelector(selectUser)
  const cities = useAppSelector(selectCities)

  const [error, setError] = useState<FilterTypeRequestModal>({} as FilterTypeRequestModal)

  const has = useRef(true);
  const offset = useRef(0);
  const [trips, setTrips] = useState<OptionType[]>([]);
  const [tripsAll, setTripsAll] = useState<TripType[]>([]);
  const currentTrips = useRef<OptionType[]>(trips);
  currentTrips.current = trips;

  const selectCity = useMemo(() => {
    if (user!.role !== UserRole.MANAGER) {
      return undefined
    } else {
      return [cities[0].id + '']

    }
  }, [user, cities])

  /**
    * получение списка экскурсий
    */
  const getTrips = async () => {
    if (!has.current || loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await RequestsService.getRequestsExcursion({
        offset: offset.current,
        published: 2,
        citiesIds: selectCity
      });

      if (!response.length) {
        has.current = false;
        setLoading(false);
        return;
      }

      offset.current = offset.current + response.length;
      setTripsAll([...tripsAll, ...response])
      setTrips([...currentTrips.current, ...createOptions(response)]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  /**
    * получение списка role
    */

  const roles = useAppSelector(selectRole)
  const getRoles = async () => {
    setRole(createOptionsForRoles(roles));
  };

  const onChangeWidget = useCallback((value: string | boolean, key: keyof FilterTypeRequestModal) => {
    setError(prev => ({ ...prev, [key]: '' }))
    setWidget(prev => ({ ...prev, [key]: value }));
  }, [])

  const sendAnswer = async () => {
    if (!widget.guide) {
      setError(prev => ({ ...prev, guide: 'Поле обязательно для заполнения' }))
    }
    if (!widget.cost) {
      setError(prev => ({ ...prev, cost: 'Поле обязательно для заполнения' }))

    }
    if (!widget.tripTypes) {
      setError(prev => ({ ...prev, tripTypes: 'Поле обязательно для заполнения' }))

    }
    if (!widget.time) {
      setError(prev => ({ ...prev, time: 'Поле обязательно для заполнения' }))

    }
    if (!widget.quantityOld) {
      setError(prev => ({ ...prev, quantityOld: 'Поле обязательно для заполнения' }))

    }
    if (!widget.quantityJunior) {
      setError(prev => ({ ...prev, quantityJunior: 'Поле обязательно для заполнения' }))

    }
    if (!widget.numberForBuy) {
      setError(prev => ({ ...prev, numberForBuy: 'Поле обязательно для заполнения' }))

    }
    if (!widget.date) {
      setError(prev => ({ ...prev, date: 'Поле обязательно для заполнения' }))

    }
    if (!widget.numberGuide) {
      setError(prev => ({ ...prev, numberGuide: 'Поле обязательно для заполнения' }))
    }

    if (widget.guide && widget.cost && widget.tripTypes && widget.time && widget.quantityOld && widget.quantityJunior && widget.numberForBuy && widget.date) {
      let sendData = {
        guideId: widget.guide,
        price: widget.cost,
        tripId: widget.tripTypes,
        tripTime: widget.time,
        adults: widget.quantityOld,
        children: widget.quantityJunior,
        paymentPhone: widget.numberForBuy,
        payFact: widget.buyOnPlace ? 1 : 0,
        tripDate: widget.date
      }


      const response = await RequestsService.postCreateRequests(sendData)
      setRequests(produce(
        draft => {
          draft.unshift(response)
        }
      ))
      onClose()

    }


  };

  const [guids, setGuids] = useState<ProfileType[]>([])
  const fetchGuids = async () => {
    const response = await UsersService.getUsers({ role: UserRole.GUID, isBusy: BusyGuid.AVALIBLE })
    setGuids(response)
  }
  useEffect(() => {
    fetchGuids()
  }, [])
  const gid = guids

  const getGuide = async () => {
    setGuide(createOptionsGuide(gid))


  }
  const datePicker = useCallback((date: string) => {
    onChangeWidget(date, "date")
  }, [onChangeWidget])

  useEffect(() => {
    onChangeWidget(gid.find(item => item.id === +widget.guide)?.phone || '', 'numberGuide')
  }, [widget.guide])

  useEffect(() => {
    getTrips();
    getRoles();
    getGuide()
  }, []);

  const onHandleChange = (e: string) => {
    if (widget.time) {
      const hours = e.split(':')[0]
      const min = e.split(':')[1]
      if (hours && +hours > 23) {
        onChangeWidget(`23:${e.split(':')[1]}`, 'time')
        return
      }
      if (min && +min > 59) {
        onChangeWidget(`${e.split(':')[0]}:59`, 'time')
        return
      }
    }
    onChangeWidget(e, 'time')
  }

  const costHuman: any = useRef({
    costAdult: 0,
    costChild: 0
  } as RefCostType)

  if (tripsAll) {
    costHuman.current.costChild = tripsAll.find(item => +item.id === +widget.tripTypes)?.pricePerChild
    costHuman.current.costAdult = tripsAll.find(item => +item.id === +widget.tripTypes)?.pricePerAdult
  }


  useEffect(() => {
    let costAdult = costHuman.current.costAdult * +widget.quantityOld
    let costChild = costHuman.current.costChild * +widget.quantityJunior
    const a: number = (!!costAdult ? costAdult : 0) + (!!costChild ? costChild : 0)

    const sum = a ? a : a * 1
    onChangeWidget(sum.toString(), 'cost')
  }, [widget.quantityOld, widget.tripTypes, widget.quantityJunior])

  return (
    <ModalCloseRequests title={title} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.columnWrapper}>
          <div className={styles.left}>
            <div className={styles.modal_item}>
              <ItemHolder>
                <Dropdown
                  error={error.tripTypes}
                  label="Экскурсия"
                  placeholder={"Выберете"}
                  value={[widget.tripTypes]}
                  onRechedEnd={getTrips}
                  options={trips}
                  onChange={data => onChangeWidget(data.value, "tripTypes")}
                />
              </ItemHolder>
            </div>
            <div
              className={classNames(
                styles.modal_item,
                styles.dataPickerWrapper
              )}
            >
              <ItemHolder>
                <DataPickerRequest
                  error={error.date}
                  value={widget.date}
                  onSubmit={datePicker}
                  placeholder={"Выберите дату"}
                  label={"Дата"}
                />
              </ItemHolder>
            </div>
            <div className={styles.modal_item}>
              <ItemHolder>
                <Dropdown
                  error={error.guide}
                  label="Гид"
                  value={[widget.guide || ""]}
                  options={guide}
                  onChange={data => onChangeWidget(data.value, "guide")}
                  placeholder={"Выберете"}
                />
              </ItemHolder>
            </div>
            <div className={classNames(styles.modal_item, styles.labelInput)}>
              <ItemHolder>
                <Input
                  error={error.quantityOld}
                  label={"Колличество взрослых"}
                  onChange={data => onChangeWidget(data, 'quantityOld')}
                  // placeholder={"20"}
                  value={widget.quantityOld}
                />
              </ItemHolder>
            </div>
            <div className={styles.modal_item_checkbox}>
              <ItemHolder>
                <CheckBox checked={widget.buyOnPlace} onChange={(data) => onChangeWidget(data, 'buyOnPlace')} label={"Оплатить на месте"} />
              </ItemHolder>
            </div>
          </div>
          <div className={styles.right}>
            <div className={classNames(styles.modal_item, styles.labelInput)}>
              <ItemHolder>
                <Input
                  error={error.cost}
                  label={"Итоговая стоимость"}
                  typeProps={TypeInput.NUMBER}
                  onChange={data => onChangeWidget(data, 'cost')}
                  // placeholder={"45 990"}
                  value={widget.cost}
                />
              </ItemHolder>
            </div>
            <div
              className={classNames(
                styles.modal_item,
                styles.time,
                styles.labelInput
              )}
            >
              <ItemHolder>
                <Input
                  error={error.time}
                  label={"Время"}
                  onChange={onHandleChange}
                  // placeholder={"00:00"}
                  mask={'99:99'}
                  value={widget.time}
                />
              </ItemHolder>
              {/* <ItemHolder>
                {<TimeMaskaRequests error={error.time} onSubmit={e => onChangeWidget(e, 'time')} />}
              </ItemHolder> */}
            </div>

            <div className={classNames(styles.modal_item, styles.labelInput)}>
              <ItemHolder>
                <Input
                  disabled
                  // error={error.numberGuide}
                  label={"Номер телефона гида"}
                  onChange={() => { }}
                  // placeholder={"+7 911 492 92 19"}
                  mask={'+7(999)999-99-99'}
                  value={widget.numberGuide}
                />
              </ItemHolder>
            </div>
            <div className={classNames(styles.modal_item, styles.labelInput)}>
              <ItemHolder>
                <Input
                  error={error.quantityJunior}
                  label={"Колличество детей"}
                  onChange={data => onChangeWidget(data, 'quantityJunior')}
                  // placeholder={"10"}
                  value={widget.quantityJunior}
                />
              </ItemHolder>
            </div>
            <div className={classNames(styles.modal_item, styles.labelInput)}>
              <ItemHolder>
                <Input
                  error={error.numberForBuy}
                  label={"Номер заказчика"}
                  onChange={data => onChangeWidget(data, 'numberForBuy')}
                  // placeholder={"+7911 499 99 21"}
                  mask={'+7(999)999-99-99'}
                  value={widget.numberForBuy}
                />
              </ItemHolder>
            </div>
          </div>
        </div>
        <div className={styles.wrapperButton}>
          <Button onClick={sendAnswer} className={styles.button}>
            Подтвердить
          </Button>
        </div>
      </div>
    </ModalCloseRequests>
  );
};

export default RequestsModal;

function createOptionsGuide(data: ProfileType[]): OptionType[] {
  return data.map(i => ({
    value: String(i.id),
    label: i.firstName
  }));
}
function createOptions(data: TripType[]): OptionType[] {
  return data.map(i => ({
    value: String(i.id),
    label: i.name
  }));
}
function createOptionsForRoles(data: string[]): OptionType[] {
  return data.map(i => ({
    value: String(i),
    label: i
  }));
}
