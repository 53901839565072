import { Endpoints } from "../constants/constants"
import { WidgetFilterBy, WidgetKind, WidgetType } from "../types/types"
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { sendFormData } from "../constants/helper"
import { BASE_URL } from "../constants/location"

type CreateWidgetType = {
    name?: string
    type: WidgetKind
    cityIds?: number[]
    allCities?: 0 | 1
    guideIds?: number[]
    allGuids?: 0 | 1
    tripTypesIds?: number[]
    allTripTypes?: 0 | 1
    tripIds?: number[]
    allTrips?: 0 | 1
    startDate: string // Y_M_D
    endDate: string
    filterBy: WidgetFilterBy
}


export const widgetsApi = createApi({
    reducerPath: 'widgetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken')
            if (token) {
                headers.set('Authorization', `${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Widgets'],

    endpoints: (builder) => ({
        getWidgets: builder.query({
            query: () => `${Endpoints.WIDGET_GET}`,
            transformResponse: (rawResult: { data: WidgetType[] }, meta) => {
                return rawResult.data
            },
            providesTags: ['Widgets']
        }),
        createWidget: builder.mutation({
            query: (widget: WidgetType) => ({
                url: Endpoints.WIDGET_CREATE,
                method: 'POST',
                body: sendFormData(widget)
            }),
            invalidatesTags: ['Widgets']

        }),
        updateWidget: builder.mutation({
            query: (widget: WidgetType) => ({
                url: `${Endpoints.WIDGET_UPDATE}/${widget.id}`,
                method: 'POST',
                body: sendFormData(widget)
            }) ,
            invalidatesTags: ['Widgets']
        }),
        deleteWidget: builder.mutation({
            query:(id: number) => ({
                url: `${Endpoints.WIDGETS_DELETE}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Widgets']
        })

    })
})

export const { useGetWidgetsQuery, useCreateWidgetMutation, useUpdateWidgetMutation, useDeleteWidgetMutation } = widgetsApi

// export class WidgetService {
//     static async getWidgets(data: CreateWidgetType): Promise<WidgetType>{
//         const response = await api.post(Endpoints)
//     }
// }