import { Endpoints } from "../constants/constants";
import { sendFormData, toQueryString } from "../constants/helper";
import { appSlice } from "../store/slice/app";
import { CityType, ModerationType } from "../types/types";
import api from "../utils/api";

export class ModerationService {
  static async Comments(data: {
    offset?: number;
    limit?: number;
    published: number;
    sort?: string
  }): Promise<ModerationType[]> {
    const response = await api.get(
      `${Endpoints.TRIPS_REVIEWS}${toQueryString(data)}&sort=desc`
    );


    return response.data.data;
  }
  static async answerComments(data: {
    id: number;
    comment: string;
  }): Promise<ModerationType> {
    const response = await api.post(
      `${Endpoints.TRIPS_REVIEWS_ANSWER}${data.id}`,
      sendFormData(data)
    );

    return response.data.data;
  }
  static async deleteComments(id: number): Promise<string[]> {
    const response = await api.delete(
      `${Endpoints.TRIPS_REVIEWS_DELETE}${id}`
    );

    return response.data.data;
  }
  static async publichComments(id: number): Promise<ModerationType> {
    const response = await api.get(
      `${Endpoints.TRIPS_REVIEWS_PUBLICH}/${id}`
    );

    return response.data.data;
  }
}
