import { FC, useCallback, useEffect, useRef, useState } from "react";
import { OrderService } from "../../../../../services/OrderService";
import { OrdersTimeType, PeriodSaleType } from "../../../../../types/types";
import { ScrollBlock } from "../../../../simples/ScrollBlock";
import { Selector } from "../../../../simples/Selector";
import { OptionType } from "../../../../simples/Selector/Selector";
import { TextLine } from "../../../../simples/TextLine";
import styles from "./SalesTable.module.scss";
import TableHeader from "./TableHeader";
import TableItem from "./TableItem";


type Props = {};

const PeriodOptions: OptionType[] = [
  {
    value: PeriodSaleType.YEAR,
    label: "За год"
  },
  {
    value: PeriodSaleType.MONTH,
    label: "За месяц"
  },
  {
    value: PeriodSaleType.WEEK,
    label: "За неделю"
  },
  // {
  //   value: PeriodSaleType.DAY,
  //   label: "За день"
  // }
];

export interface IListQueryResponse {
  items: any[];
  total: number;
  page: number;
  size: number;
}

const SalesTable: FC<Props> = ({ }) => {
  const [period, setPeriod] = useState<PeriodSaleType>(PeriodSaleType.YEAR);
  const [loading, setLoading] = useState<boolean>(false)



  const [list, setList] = useState<OrdersTimeType[]>([])
  const orders = useRef<OrdersTimeType[]>([])
  orders.current = list

  const has = useRef<boolean>(true)
  const offset = useRef<number>(0)


  /**
   * get orders
   */
  const getOrders = useCallback(async () => {
    if (!has.current || loading) {
      return
    }
    try {
      const response = await OrderService.getOrdersItems({
        period,
        offset: offset.current,
        limit: 10,
        // dateFrom: '',
        // dateTo: ''
      })

      if (!response.length || !Array.isArray(response)) {
        has.current = false
        setLoading(false)
        return
      }

      offset.current = offset.current + response.length

      setList([...orders.current, ...response])
      setLoading(false)

    } catch (error) {

    }
  }, [loading, period])

  useEffect(() => {
    getOrders().then()
  }, [getOrders])

  const clearFunction = useCallback(async () => {
    has.current = true
    offset.current = 0
    setList([])
    orders.current = []
    setLoading(false)
    await getOrders()

  }, [getOrders])

  useEffect(() => {
    clearFunction()
  }, [clearFunction, period])


  /**
     * change period
     */
  const onChangePeriod = useCallback(period => {

    setPeriod(period);
  }, []);



  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <TextLine weight={700} size={24}>
          Таблица продаж
        </TextLine>
        <Selector
          placeholder={"Период"}
          value={period || ''}
          options={PeriodOptions}
          onChange={onChangePeriod}
        />
      </div>

      <ScrollBlock onReachedEnd={getOrders} className={styles.table}>
        <table className={styles.saleTable}>
          <TableHeader />
          <tbody className={styles.tableBody}>

            {list.map((item, index) => <TableItem key={index} data={item} />)}
          </tbody>
        </table>
      </ScrollBlock>
    </div>
  );
};
export default SalesTable;
