import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../hooks/store';
import { LocationService } from '../../../services/LocationService';
import { selectCities } from '../../../store/slice/storage';
import { OptionType } from '../../simples/Dropdown/Dropdown';
import { Input } from '../../simples/Input';
import { InputProps } from '../../simples/Input/Input';
import styles from './InputNotes.module.scss'
import OptionItem from './OptionItem';



type Props = InputProps & {
    value: string
    onChange: (value: string) => void
    onCheckValue: (option: OptionType) => void
    options: Array<OptionType>
    cityId: number
    error?: string | undefined
}

const InputNotes: FC<Props> = ({
    value,
    cityId,
    error,
    onChange,
    // options,
    onCheckValue,
    ...props
}) => {
    const cities = useAppSelector(selectCities)

    const cityName = useMemo(() => {
        return cityId ? cities.find(i => i.id === +cityId)?.name : 'Сначала выберите город'
    }, [cityId, cities])
    const [showOptions, setShowOptions] = useState<boolean>(false)


    const [search, setSearch] = useState<string>(value)
    const searchRef = useRef<string>(search)
    searchRef.current = search

    const [options, setOptions] = useState<OptionType[]>([])

    const onHandleCheckOption = useCallback((option: OptionType) => {
        setShowOptions(false)
        onCheckValue(option)
        setSearch(option.label)
    }, [onCheckValue])

    /**
     * изменение строки поиска
     */
    const onSearchOptions = useCallback(async (search: string) => {
        setShowOptions(true)
        setSearch(search)
    }, [])

    const getNotes = async () => {
        const response = await LocationService.getTransferNotes({
            name: searchRef.current,
            cityId
        })

        setOptions(response.map(i => ({
            value: String(i.id),
            label: i.name
        })))
    }

    useEffect(() => {
        getNotes().then()
    }, [search])
    useEffect(() => {
        setSearch(value)
    }, [value])

    /**
     * isOpen options
     */

    const isOpen = useMemo(() => {
        return !!search && options.length > 0 && showOptions
    }, [options.length, search, showOptions])


    // const currentOptions = useMemo 
    return (
        <div className={styles.root}>
            <Input
                error={error}
                before={cityName}
                onChange={onSearchOptions}
                value={search}
                {...props}
            />
            {isOpen && <div className={classNames(styles.optionsBlock, {
                [styles.error]: !!error
            })}>
                {
                    options.map((item) => <OptionItem key={item.value} option={item} onClick={onHandleCheckOption} />)
                }
            </div>}
        </div>
    )
}
export default InputNotes