import React, { FC } from 'react';
import { TextLine } from '../../simples/TextLine';
import styles from './AddWidgetModal.module.scss'



type Props = {
    onClick: () => void
    icon: JSX.Element
    title: string
    subtitle?: string
}

const ChooseButton: FC<Props> = ({ onClick, title, subtitle, icon }) => {
    return (
        <div onClick={onClick} className={styles.chooseBtn}>
            <div className={styles.chooseBtnItself}>
                <div className={styles.btnIcon}>{icon}</div>
            </div>
            <div className={styles.btnNames}>
                <TextLine display={'block'} mb={4} weight={600}>{title}</TextLine>
                {subtitle && <TextLine size={12} color={'#A2A3A5'}>{subtitle}</TextLine>}
            </div>
        </div>
    )
}
export default React.memo(ChooseButton)