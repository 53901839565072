import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthService } from "../../services/AuthService";
import { ProfileType } from "../../types/types";
import { ErrorsTypes } from "../../utils/api";
import { RootState, store } from "../index";

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
  /** Объект авторизованного пользователя. */
  user: null as ProfileType | null,
  accessToken: "",
  /** Состояние: проверка токена пользователя */
  checking: true,
  /** Состояние: проверка токена пользователя была произведена */
  checked: false,
  /** Состояние: пользователь авторизован */

  isAuth: false,
  /** Состояние: идет обновление данных пользователя */
  updating: false,
  /** Состояние: данные пользователя были обновлены */
  updated: false,
  /** Состояние: было ли обновление пользователя с ошибкой */
  errorUpdate: false,
  /** Объект ошибки при обновлении пользователя */
  errorUpdateData: undefined as ErrorsTypes | undefined
};

// -------------------------------------------
// Async Thunks
// -------------------------------------------
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data: { login: string; password: string; remember: boolean }) => {
    const response = await AuthService.login(data);

    if (data.remember) {
      localStorage.setItem("accessToken", response);
      localStorage.setItem("remember", "yes");
    } else {
      localStorage.setItem("accessToken", response);
      localStorage.setItem("remember", "no");
    }
    return response;
  }
);
export const getUser = createAsyncThunk("auth/getUser", async () => {
  const response = await AuthService.getUSer();
  return response;
});

// -------------------------------------------
// Slice
// -------------------------------------------

/**
 * Создание слайса Auth.
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus: state => {
      state.isAuth = !state.isAuth;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    logout: state => {
      state.user = null;
      state.isAuth = false;

      localStorage.removeItem("accessToken");
    }
  },
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => {
      // state.checking = true;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.checking = false;
      state.isAuth = !!action.payload;
      state.accessToken = action.payload;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.checking = false;
      state.isAuth = !!action.payload;
    });
    builder.addCase(getUser.pending, state => {
      state.checking = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.checking = false;
      state.isAuth = !!action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.checking = false;
      state.isAuth = false;
    });
  }
});

// -------------------------------------------
// Export action creators
// -------------------------------------------

// -------------------------------------------
// Selectors
// -------------------------------------------

/**
 * Селектор, идет ли в текущий момент проверка токена.
 * @param state Объект стора
 */
export const selectAuthChecking = (state: RootState) => state.auth.checking;
/**
 * Селектор, была ли проверка токена.
 * @param state Объект стора
 */
// export const selectAuthChecked = (state: RootState) => state.auth.checked
/**
 * Селектор, авторизован ли пользователь.
 * @param state Объект стора
 */
export const selectIsAuth = (state: RootState) => state.auth.isAuth;
export const selectUser = (state: RootState) => state.auth.user;

/**
 * Селектор объекта авторизованного пользователя.
 * @param state Объект стора
 */
// export const selectAuthUser = (state: RootState) => state.auth.user

export const { setAuthStatus, updateUser, logout } = authSlice.actions;

// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default authSlice.reducer;

export let getToken = () => {
  return initialState.accessToken;
};
