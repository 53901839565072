import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './Selector.module.scss'
import { ReactComponent as Mark } from '../../../assets/icons/drop.svg'
import { CheckBox } from '../CheckBox'

export type OptionType = {
    label: string
    value: string
}
type Props = {
    label?: string
    placeholder?: string
    value: string
    options: Array<OptionType>
    onChange: (value: string) => void
    isOdd?: boolean
    disabled?: boolean
    isColor?: boolean
    classes?: {
        root?: string
        label?: string
    }
    values?: Array<number>
    isMultiple?: boolean
}

/**
 * 
 * @param label 
 * @param placeholder 
 * @param value 
 * @param options 
 * @param onChange 
 * @returns 
 */
const Selector: FC<Props> = ({
    label,
    placeholder,
    value,
    options,
    onChange,
    isOdd = false,
    disabled,
    isColor,
    classes,
    values,
    isMultiple
}) => {

    const [openStatus, setOpenStatus] = useState<boolean>(false)

    const optionsBlock = useRef<HTMLDivElement>(null)
    const mainBlock = useRef<HTMLDivElement>(null)

    const optionsCurrent = useMemo(() => {
        const o = options.filter(i => i.value !== value)
        return o
    }, [options, value])

    const onHandleChange = useCallback((e: string) => {
        onChange(e)
        if (!isMultiple) {
            setOpenStatus(false)
        }

    }, [isMultiple, onChange])

    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        if (optionsBlock && optionsBlock.current) {
            optionsBlock.current.blur()
        }
    }, [])

    // Метод закрытия селектора при клике вне самого селектора
    const closeSelectOutOfBlock = useCallback(
        (event: any) => {
            if (mainBlock && mainBlock.current) {
                // Проверка добавлена для устранения бага в Firefox
                if (!mainBlock.current.contains(event.target)) {
                    setOpenStatus(false)
                    loseFocus()
                }
            }
        },
        [loseFocus]
    )

    // Раскрытие пунктов меню с опциями при фокусе на селекторе
    const onHandleFocus = useCallback(() => {
        if (!disabled) setOpenStatus(true)
    }, [disabled])

    //закрытие по второму тапу
    const onToggleOpen = useCallback(() => {
        if (disabled) {
            return
        }
        setOpenStatus(!openStatus)
    }, [openStatus, disabled])

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [closeSelectOutOfBlock])

    const initLabel = useMemo((): JSX.Element => {
        let title = options.find(i => i.value === value)?.label
        let t = title || label || placeholder
        if (!isColor) {

            return <div>{t}</div>
        } else {
            return <div

                className={classNames(styles.colorLabel)}>
                <div style={{
                    backgroundColor: t
                }} className={classNames(styles.color, {
                    [styles.isWhite]: t === '#FFFFFF' || t === "#ffffff" || t === "#fff" || t === "#FFF"
                })}></div>
            </div>
        }

    }, [isColor, label, options, placeholder, value])

    return (
        <div
            ref={mainBlock}
            className={classNames(styles.select, classes?.root, {
                [styles.open]: openStatus
            })}
            onClick={() => !disabled ? setOpenStatus(prev => !prev) : undefined}
        >
            <div className={classNames(styles.topLabel)}>
                <div className={classNames(styles.label, classes?.label)}>{initLabel}</div>
                {!disabled && <Mark className={classNames(styles.mark, {
                    [styles.activeMark]: openStatus
                })} />}
            </div>
            {openStatus && <div ref={optionsBlock}
                className={classNames(styles.drop, {
                    [styles.bgcOdd]: isOdd
                })}>
                {optionsCurrent.map((i) => <div key={i.value} onClick={(e) => {
                    e.stopPropagation()
                    onHandleChange(i.value)
                }} className={styles.options}>{
                        !isColor ?
                            <div className={styles.checkFrame}>
                                {isMultiple && <CheckBox
                                    onChange={() => onChange(i.value)}
                                    checked={!!values?.includes(+i.value)}
                                />}
                                <span>{i.label}</span>
                            </div> :
                            <div
                                style={{
                                    backgroundColor: i.label
                                }}
                                className={classNames(styles.color, {
                                    [styles.isWhite]: i.label === '#FFFFFF' || i.label === "#ffffff" || i.label === "#fff" || i.label === "#FFF"
                                })}></div>
                    }</div>)}
            </div>}


        </div>
    )
}

export default Selector