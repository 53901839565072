import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './ItemHolder.module.scss'



type Props = RouteProps & {
    mb?: number
}

const ItemHolder: FC<Props> = ({ mb = 30, children }) => {
    return (
        <div
            style={{
                marginBottom: mb
            }}
            className={styles.root}>
            {children}
        </div>
    )
}
export default React.memo(ItemHolder)