import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonColors } from '../../../../simples/Button';
import { TextLine } from '../../../../simples/TextLine';
import styles from './TimeEditor.module.scss'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/del.svg'
// @ts-ignore
import InputMask from 'react-input-mask';
import classNames from 'classnames';


type Props = {
    label?: string
    values?: Array<string>
    onAddTime?: (time: string) => void
    onDeleteTime: (value: string) => void
    error?: string
}

const TimeEditor: FC<Props> = ({ label, values, error, onAddTime, onDeleteTime }) => {
    const [time, setTime] = useState<string>('')

    const onHandleChange = (e: string) => {
        if (time) {
            const hours = e.split(':')[0]
            const min = e.split(':')[1]
            if (hours && +hours > 23) {
                setTime(`23:${e.split(':')[1]}`)
                return
            }
            if (min && +min > 59) {
                setTime(`${e.split(':')[0]}:59`)
                return
            }
        }

        setTime(e)
    }

    const onHandleAddTime = useCallback(() => {
        onAddTime && time && onAddTime(time)
        setTime('')
    }, [onAddTime, time])

    return (
        <div>
            {label && <span className={styles.label}>{label}</span>}
            <div className={classNames(styles.root, {
                [styles.error]: !!error
            })}>
                <div className={styles.addTimeBlock}>
                    <div className={styles.inputBlock}>
                        <InputMask
                            placeholder={'00:00'}
                            className={classNames(styles.input)}
                            value={time}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e.target.value)}
                            mask={'99:99'}

                        />
                    </div>

                    <Button onClick={onHandleAddTime} className={styles.btn} color={ButtonColors.Secondary}>Добавить</Button>
                </div>
                {
                    values?.map((item) => <TimeItem key={item} time={item} onDelete={onDeleteTime} />)
                }
            </div>
        </div>
    )
}
export default TimeEditor

type ItemProps = {
    time: string
    onDelete: (time: string) => void
}
const TimeItem: FC<ItemProps> = ({ time, onDelete }) => {
    return (
        <div className={styles.timeItem}>
            <TextLine display={'block'}>{time}</TextLine>
            <CloseIcon onClick={() => onDelete(time)} className={styles.delIcon} />
        </div>
    )
}