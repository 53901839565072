import React, { FC, useContext } from 'react';
import { TextLine } from '../../../../simples/TextLine';
import { ImportantInfoContext } from '../../EditExcurtion';
import styles from './ImportentInfoBlock.module.scss'
import InfoItem, { InfoItemType } from './InfoItem';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/addPlus.svg'
import { ReactComponent as MinusIcon } from '../../../../../assets/icons/minus.svg'
import { ReactComponent as StopIcon } from '../../../../../assets/icons/stop.svg'
import produce from 'immer';


export type ImportantInfoType = {
    include: InfoItemType
    exclude: InfoItemType
    cancelConditions: InfoItemType
}

type InfoData = {
   include: {
       icon: JSX.Element,
       label: string
   }
   exclude: {
       icon: JSX.Element,
       label: string
   }
   cancelConditions: {
       icon: JSX.Element,
       label: string
   }
}

const _INFO_DATA: InfoData = {
    include: {
        icon: <PlusIcon />,
        label: 'В стоимость включено'
    },
    exclude: {
        icon: <MinusIcon />,
        label: 'В стоимость не включено'
    },
    cancelConditions: {
        icon: <StopIcon />,
        label: 'Условия отмены'
    },
}

type Props = {
}

const ImportentInfoBlock: FC<Props> = ({ }) => {
    const [content, setContent] = useContext<[ImportantInfoType, React.Dispatch<React.SetStateAction<ImportantInfoType>>]>(ImportantInfoContext)

    const onChangeEnable = (key: keyof ImportantInfoType) => {
        setContent(produce(
            draft => {
                draft[key].enable = !draft[key].enable
            }
        ))
    }
    const onDeleteItem = (key: keyof ImportantInfoType, value: string) => {
        setContent(produce(
            draft => {
                draft[key].data = draft[key].data.filter(i => i !== value)
            }
        ))
    }
    const onAddItem = (key: keyof ImportantInfoType, value: string) => {
        setContent(produce(
            draft => {
                draft[key].data.push(value)
            }
        ))
    }


    return (
        <div className={styles.root}>
            <TextLine mb={12} ml={16} weight={500} size={20} display={'block'}>Важная информация</TextLine>
            {
            
            Object.entries(content).map(([key, value]) : JSX.Element => (
                    <InfoItem
                    key={key}
                        info={value}
                        label={_INFO_DATA[key as keyof InfoData].label}
                        icon={_INFO_DATA[key as keyof InfoData].icon}
                        onEnable={() => onChangeEnable(key as keyof ImportantInfoType)}
                        onDelete={(value) => onDeleteItem(key as keyof ImportantInfoType, value)}
                        onAdd={(value) => onAddItem(key as keyof ImportantInfoType, value)}
                    />
                ))
            }
        </div>
    )
}
export default ImportentInfoBlock