import { Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import { CityType, ProfileType, Sort, StaffType, UserRole, UsersCrearte } from "../types/types";
import api from "../utils/api";
import { sendFormData } from "../constants/helper";

type GetUsersType = {
  search?: string;
  role?: UserRole;
  sort?: Sort;
  offset?: number;
  limit?: number;
  cityId?: number
};

export type dataModalUserType = {
  id?: number
  cityId: string;
  email: string;
  firstName: string;
  password: string;
  role: string;
  active: number
  phone: string
};


export class StaffService {
  static async getUsers(data: GetUsersType): Promise<Array<StaffType>> {
    const response = await api.get(
      `${Endpoints.GET_USERS}${toQueryString(data)}`
    );
    return response.data.data;
  }

  static async createUsers(
    data: dataModalUserType
  ): Promise<StaffType> {
    const response = await api.post(Endpoints.CREATE_USERS, sendFormData(data));
    return response.data.data;
  }

  static async updateUsers(
    id: number,
    data: dataModalUserType
  ): Promise<StaffType> {
    const response = await api.post(
      Endpoints.UPDATE_USERS + id,
      sendFormData(data)
    );
    return response.data.data;
  }

  /**
       * delte city by id
       */
  static async deleteUsers(id: number): Promise<boolean> {
    try {
      await api.delete(`${Endpoints.DELETE_USERS}${id}`);
      return true;
    } catch (error) {
      return false;
    }
  }

  static async getUsersId(id: number): Promise<StaffType> {
    const response = await api.get(`${Endpoints.GET_USERS_ID}/${id}`);

    return response.data.data;
  }

  static async getCitiesId(id: number): Promise<CityType> {
    const response = await api.get(`${Endpoints.GET_CITIES_ID}/${id}`);

    return response.data.data;
  }
}
