import React, { FC } from 'react';
import { ProfileType } from '../../../types/types';
import styles from './Note.module.scss'



type Props = {
    user: ProfileType
}

const UserItem: FC<Props> = ({ user }) => {
    return (
        <div className={styles.userItem}>
            <span>{user.lastName || ''}</span>
            <span>{user.firstName || ''}</span>
            <span>{user.secondName || ''}</span>
        </div>
    )
}
export default React.memo(UserItem)