import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { CheckBox } from '../../../../simples/CheckBox';
import { TextLine } from '../../../../simples/TextLine';
import { ReactComponent as DeleteIcon} from '../../../../../assets/icons/del.svg'
import styles from './ImportentInfoBlock.module.scss'
import classNames from 'classnames';


export type InfoItemType = {
    enable: boolean,
    data: Array<string>
}
type Props = {
    info: InfoItemType
    label: string
    icon: JSX.Element
    onEnable: () => void
    onDelete: (item: string) => void
    onAdd: (item: string) => void
}

const InfoItem: FC<Props> = ({ info, label, icon, onEnable, onDelete, onAdd}) => {
    const [newItem, setNewItem] = useState<string>('')
    const onHandleAdd = useCallback(() => {
       !!newItem && onAdd(newItem)
       setNewItem('')
    },[newItem, onAdd])

    const onHandleChangeNewItem = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNewItem(event.target.value)
    },[setNewItem])
    return (
        <div className={styles.infoItem}>
            <div className={styles.infoItemTop}>
                <CheckBox classes={{
                    root: styles.checkbox
                }} checked={info.enable} onChange={onEnable} />
                <div className={classNames(styles.label, {
                    [styles.disabled] : !info.enable
                })}>
                    {icon}
                    <TextLine display={'block'} ml={12} color={'#FB8328'} weight={500}>{label}</TextLine>
                </div>
            </div>
            <div className={classNames(styles.list,{
                 [styles.disabled] : !info.enable
            })}>
                {
                    info.data.map((item) => <div key={item} className={styles.listItem}>
                        <DeleteIcon className={styles.delIcon} onClick={() => onDelete(item)} />
                        <TextLine display={'block'} ml={6} weight={500} size={14}>{item}</TextLine>
                        </div>)
                }
            </div>
            <div className={classNames(styles.infoItemAddBlock,{
                 [styles.disabled] : !info.enable
            })}>
                <span onClick={onHandleAdd} className={classNames(styles.addBtn,{
                    [styles.disabledAddBtn] : !info.enable
                })} />
                <input disabled={!info.enable} className={styles.input} value={newItem} onChange={onHandleChangeNewItem} />
            </div>
        </div>
    )
}
export default InfoItem
