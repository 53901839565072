import classNames from "classnames";
import produce from "immer";
import React, { FC, MouseEventHandler, SyntheticEvent, useContext, useMemo } from "react";
import { prindTimeDate, thousand } from "../../../../../constants/helper";
import { RequestsService } from "../../../../../services/RequestsService";
import { RequestsItem, RequestStatusType, RequestsType } from "../../../../../types/types";
import { TextLine } from "../../../../simples/TextLine";
import { RequestContext } from "../../RequestContext";
import styles from "../../Requests.module.scss";

type Props = {
  trips: RequestsItem;
};

const TableSellBodySub: FC<Props> = ({ trips }) => {
  const [requests, setRequests] = useContext(RequestContext)
  console.log('%cMyProject%cline:16%crequests', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(1, 77, 103);padding:3px;border-radius:2px', requests)

  const cancel = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const response = await RequestsService.cancelRequest(trips.id)
    const { orderStatus, ...rest } = response
    setRequests(produce(
      draft => {
        let find: any = draft.find(i => i.id === trips.orderId)

        const fffIndex = find?.items.findIndex((i: any) => i.id === trips.id)
        find.items[fffIndex] = rest
        find.status = orderStatus
      }
    ))
  }

  const statusText = useMemo(() => {

    switch (trips.status) {
      case RequestStatusType.NEW:
        return 'Не оплачено'

      case RequestStatusType.PAIED:
        return 'Оплачено'

      case RequestStatusType.CANCELED:
        return 'Отменен'

      case RequestStatusType.COMPLITED:
        return 'Завершен'

      case RequestStatusType.PAIED_BY_CASH:
        return 'Оплачен на месте'

      default:
        return ''
    }

  }, [trips])

  const statusStyles = useMemo(() => {
    return classNames('', {
      [styles.isBuyPlace]: trips.status === RequestStatusType.PAIED_BY_CASH || trips.status === RequestStatusType.PAIED,
      [styles.noBuyPlace]: trips.status === RequestStatusType.NEW,
      [styles.decline]: trips.status === RequestStatusType.CANCELED

    })
  }, [trips])



  return (

    <div className={styles.saleItem}>
      <div className={styles.saleItemCell}>
        <span style={{ width: "232px" }}>
          <TextLine display={"block"} size={12} weight={500}>
            {trips.trip.name}
            {/* {trips.id} */}
            {/* {trips.trip.name} */}
          </TextLine>
        </span>
        <TextLine display={"block"} size={12} weight={500}>
          <span className={styles.city}>
            {trips.trip.startLocation?.address || ''}
          </span>
        </TextLine>
      </div>
      <div className={classNames(styles.saleItemCell)}>
        <TextLine display={"block"} size={12} weight={500}>
          Взрослых: {trips.adults}
        </TextLine>
        <TextLine display={"block"} size={12} weight={500}>
          Детей: {trips.children}
        </TextLine>
      </div>
      <div className={styles.saleItemCell}>
        {`${thousand(trips.price)} ₽`}
      </div>
      <div className={classNames(styles.saleItemCell)}>
        <TextLine display={"block"} size={12} weight={500}>
          {prindTimeDate(trips.tripDate).split(" ")[0]}   {trips.tripTime}
        </TextLine>
      </div>
      <div
        className={classNames(
          styles.saleItemCell,
          styles.dateColumn,
          styles.btnBuy
        )}
      >
        <div className={statusStyles}>{statusText}</div>
        {trips.status !== RequestStatusType.CANCELED && <button
          onClick={ e => cancel(e)} className={styles.btnClose}>Отменить</button>}

      </div>
    </div>
  );
};

export default TableSellBodySub;
