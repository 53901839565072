import classNames from 'classnames';
import React, { AllHTMLAttributes, FC, useRef } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './ScrollBlock.module.scss'



type Props = RouteProps & AllHTMLAttributes<HTMLDivElement> & {
    onReachedEnd?: () => void
    height?: number
}

const ScrollBlock: FC<Props> = ({ onReachedEnd, className, height, children }) => {

    const optionsBlock = useRef<HTMLDivElement>(null)
    const reached = useRef(false)

    /**
    *
    * dynamic pagination
    */
    const handleScroll = () => {
        if (!optionsBlock.current) {
            return
        }

        const contentHeight = optionsBlock.current.offsetHeight
        const scrollHeight = optionsBlock.current.scrollHeight

        const scrollTop = optionsBlock.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true
            onReachedEnd && onReachedEnd()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    return (
        <div
            className={classNames(styles.root,className)}
            ref={optionsBlock}
            onScroll={handleScroll}
            style={{
                maxHeight: `100%`
            }}
        >
            {children}
        </div>
    )
}
export default ScrollBlock