import produce from "immer";
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { registerLocale } from "react-datepicker";

import { Dropdown } from "../../../../simples/Dropdown";
import { OptionType } from "../../../../simples/Dropdown/Dropdown";
import { ItemHolder } from "../../../../simples/ItemHolder";
import { ModalClose } from "../../../../simples/ModalClose";
import styles from "./addUsersModal.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import { Button } from "../../../../simples/Button";
import { CityType, ProfileType, StaffType, TripType, UserRole } from "../../../../../types/types";
import { Input } from "../../../../simples/Input";
import { UsersService } from "../../../../../services/UsersService";
// import { goModalUsers } from "../../Staff";

import { selectCities, selectRole } from "../../../../../store/slice/storage";
import { useAppSelector } from "../../../../../hooks/store";
import { dataModalUserType, StaffService } from "../../../../../services/StaffService";
import { StaffContext } from "../../StaffContexts";
import { Preview } from "react-dropzone-uploader";
import { selectUser } from "../../../../../store/slice/auth";

registerLocale("ru", ru);

type Pass = {
  password: string
  confirmPassword: string
}



type Props = {
  onClose: () => void;
  id?: number
};

type Error = {
  email?: string
  firstName?: string
  password?: string
  confirmPassword?: string
  role?: string
  cityId?: string
  phone?: string
}
type ruRoles = {
  [key in UserRole]: string
}
type UniversalStaffUserType = StaffType & Pass

const newRole: ruRoles = {
  'admin': 'Админ',
  'guide': 'Гид',
  'manager': 'Менеджер',
  'user': "Пользователь"
}


const AddUsersModal: FC<Props> = ({ onClose, id }) => {

  // redux and context 
  const cities = useAppSelector(selectCities)
  const roleFromStorage = useAppSelector(selectRole)
  const user = useAppSelector(selectUser)
  const [users, setUsers] = useContext(StaffContext)

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UniversalStaffUserType>({
    cityId: user?.role === UserRole.MANAGER && cities[0]?.id.toString(),
    role: user?.role === UserRole.MANAGER && UserRole.GUID
  } as UniversalStaffUserType)
  const [cityId, setCity] = useState<OptionType[]>([]);
  const [error, setError] = useState<Error>({} as Error)

  // function 
  const optionsRole: OptionType[] = useMemo(() => {

    let options: OptionType[] = []
    const removeAdmin = roleFromStorage.filter(item => item)


    for (let key of removeAdmin) {
      options.push({
        value: key,
        label: newRole[key]
      })
    }
    return options
  }, [roleFromStorage])
  const getCity = async () => {
    setCity(createOptions(cities));
  }
  const getUserByID =
    async (id: number) => {
      setLoading(true);
      // я оставлю все-таки запрос, если его в друг захотят изменить сразу 2 пользователя 
      try {
        const response = await StaffService.getUsersId(id);
        changeUser(id, 'id')
        changeUser(response.role || '', 'role')
        changeUser(response.city?.id + '' || '', 'cityId')
        changeUser(response.firstName || '', 'firstName')
        changeUser(response.email || '', 'email')
        changeUser(response.phone || '', 'phone')
      } catch (error) { }
      setLoading(false);
    }

  const saveData = async () => {
    //check field
    if (!id) {
      if (!currentUser.password) {
        setError(prev => ({ ...prev, password: 'Поле обязательно для заполнения' }))
      }
      if (!currentUser.confirmPassword) {
        setError(prev => ({ ...prev, confirmPassword: 'Поле обязательно для заполнения' }))
      }
    } else {
      if (currentUser.password !== currentUser.confirmPassword) {
        setError(prev => ({ ...prev, confirmPassword: 'Пароли должны совпадать' }))
      }
    }
    if (!currentUser.email) {
      setError(prev => ({ ...prev, email: 'Поле обязательно для заполнения' }))
    }
    if (!currentUser.firstName) {
      setError(prev => ({ ...prev, firstName: 'Поле обязательно для заполнения' }))
    }
    if (!currentUser.role) {
      setError(prev => ({ ...prev, role: 'Выберите роль' }))
    }
    if (!currentUser.cityId) {
      setError(prev => ({ ...prev, cityId: 'Выберите город' }))
    }
    if (!currentUser.phone) {
      setError(prev => ({ ...prev, phone: 'Поле обязательно для заполнения' }))
    }
    if (currentUser.confirmPassword !== currentUser.password) {
      setError(prev => ({ ...prev, confirmPassword: 'Пароли должны совпадать' }))
    }
    //  send data if validate success
    if ((id && currentUser.confirmPassword === currentUser.password || (currentUser.password && currentUser.confirmPassword && currentUser.confirmPassword === currentUser.password)) && currentUser.phone && currentUser.firstName && currentUser.email && currentUser.role && currentUser.cityId) {
      manipulationsUsers()

    }
  };


  const changeUser = useCallback((value: string | number, key: keyof UniversalStaffUserType) => {
    setError(prev => ({ ...prev, [key]: '' }))
    setCurrentUser(prev => ({ ...prev, [key]: value }))

  }, [])
  // const selectCity = cities.find(item => item.id === currentUser.cityId)
  const manipulationsUsers = useCallback(async () => {
    const cityResponse = cities.find(item => item.id === +currentUser.cityId)
    if (currentUser.id) {
      setLoading(true);
      try {
        const response = await StaffService.updateUsers(currentUser.id, {
          id: currentUser.id,
          cityId: currentUser.cityId,
          email: currentUser.email,
          firstName: currentUser.firstName,
          password: currentUser.password,
          role: currentUser.role,
          phone: currentUser.phone,
          active: 1
        });
        setUsers(produce(
          draft => {
            const find: any = draft.find(item => item.id === currentUser.id)
            for (const key in find) {
              //  @ts-ignore
              find[key] = currentUser[key]

            }
            find.city = cityResponse

          }
        ))
        onClose()

      } catch (errors: any) {
        console.log(errors)
      }

      setLoading(false);
    } else {
      setLoading(true);
      try {
        const response = await StaffService.createUsers({
          id: currentUser.id,
          cityId: currentUser.cityId,
          email: currentUser.email,
          firstName: currentUser.firstName,
          password: currentUser.password,
          role: currentUser.role,
          phone: currentUser.phone,
          active: 1
        });
        setUsers(prev => [response, ...prev])

        onClose()

      } catch (error: any) {
        const err = error.response.data.errors
        if ('cityId' in err) {
          setError(prev => ({ ...prev, cityId: err['cityId'] }))
        }
        if ('email' in err) {
          setError(prev => ({ ...prev, email: err['email'] }))
        }

      }
      setLoading(false);
    }
  }, [id, currentUser])



  useEffect(() => {
    if (id) {
      getUserByID(id)
    }
    getCity()
  }, [])

  // console.log(optionsRole);
  // console.log(user?.role === UserRole.MANAGER);
  // console.log(currentUser.role);

  // console.log(cityId[0].value);
  console.log(currentUser.role);

  return (
    <ModalClose
      title={
        !currentUser.id
          ? "Новый пользователь"
          : "Редактировать пользователя"
      }
      onClose={onClose}

    >
      <ItemHolder>
        <Dropdown
          disabled={user?.role === UserRole.MANAGER}
          error={error.role}
          label="Роль"
          value={[currentUser.role || ""]}
          options={optionsRole}
          onChange={data => changeUser(data.value, 'role')}
          placeholder={"Выберите"}
        />
      </ItemHolder>
      <ItemHolder>
        <Input
          error={error.firstName}
          label={"Имя"}
          onChange={(e) => changeUser(e, 'firstName')}
          placeholder={"Введите имя"}
          value={currentUser.firstName}
        />
      </ItemHolder>
      <ItemHolder>
        <Input
          error={error.password}
          label={"Пароль"}
          onChange={(e) => changeUser(e, 'password')}
          value={currentUser.password}
        />
      </ItemHolder>
      <ItemHolder>
        <Input
          error={error.confirmPassword}
          label={"Подтвердить пароль"}
          onChange={e => changeUser(e, 'confirmPassword')}
          value={currentUser.confirmPassword}
        />
      </ItemHolder>
      <ItemHolder>
        <Input
          error={error.email}
          label={"Почта"}
          value={currentUser.email}
          onChange={e => changeUser(e, 'email')}
          placeholder={"email@mail.ru"}
        />
      </ItemHolder>
      <ItemHolder>
        <Input
          error={error.phone}
          label={"Номер телефона"}
          value={currentUser.phone}
          onChange={e => changeUser(e, 'phone')}
          placeholder={"Введите номер телефона"}
          mask={'+7(999)999-99-99'}

        />
      </ItemHolder>
      <ItemHolder>
        <Dropdown
          disabled={user?.role === UserRole.MANAGER}
          error={error.cityId}
          label="Город"
          value={[currentUser?.cityId || ""]}
          // value={['8']}
          options={cityId}
          onChange={data => changeUser(data.value, 'cityId')}
          placeholder={"Выберите"}
        />
      </ItemHolder>

      <Button onClick={saveData} className={styles.button}>
        Подтвердить
      </Button>
    </ModalClose >
  );
};
export default AddUsersModal;
// генерирует options for cityId
function createOptions(data: CityType[]): OptionType[] {
  return data.map(i => ({
    value: String(i.id),
    label: i.name
  }));
}
