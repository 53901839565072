import { Endpoints } from "../constants/constants";
import { arrayToQuery, sendFormData, toQueryString } from "../constants/helper";
import {
  ImageType,
  ProfileType,
  Sort,
  SortBy,
  TripType,
  UserRole
} from "../types/types";
import api from "../utils/api";

type GetUsersType = {
  search?: string;
  role?: UserRole;
  sort?: Sort;
  offset?: number;
  limit?: number;
  isBusy?: number
};

export class UsersService {
  static async getUsers(data: GetUsersType): Promise<Array<ProfileType>> {
    const response = await api.get(
      `${Endpoints.GET_USERS}${toQueryString(data)}`
    );

    return response.data.data;
  }

  static async getRoles(): Promise<Array<UserRole>> {
    const response = await api.get(`${Endpoints.GET_ROLES}`);

    return response.data.data;
  }
}
