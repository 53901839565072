import classNames from "classnames";
import React, { FC, useMemo, useState } from "react";
import { prindTimeDate, sumDate, thousand } from "../../../../../constants/helper";
import { RequestsType, RequestsItem } from "../../../../../types/types";
import { TextLine } from "../../../../simples/TextLine";
import styles from "../../Requests.module.scss";
import TableSellBodySub from "./TableSellBodySub";

type Props = {
  itemBody: RequestsType;
  subActive: (id: number | undefined) => void;
  id: number | undefined;
};

const TableSellBody: FC<Props> = ({ itemBody, subActive, id }) => {
  const showSub = () => {
    if (itemBody.id === id) {
      subActive(undefined)
      return
    }
    subActive(itemBody.id);
  };




  const buyClass = useMemo(() => {
    switch (itemBody.status) {
      case 0:
        return styles.notBuy
      case 1:
        return styles.buy
      case 2:
        return styles.red
      case 3:
        return styles.buy
      case 4:
        return styles.buy
      case 5:
        return styles.someBuy
      default:
        return styles.notBuy
    }
  }, [itemBody.status])
  const buyText = useMemo(() => {
    switch (itemBody.status) {
      case 0:
        return 'Новый'
      case 1:
        return 'Оплачено'
      case 2:
        return 'Отменен'
      case 3:
        return 'Завершен'
      case 4:
        return 'Оплачен на месте'
      case 5:
        return 'Оплачен частично'
      default:
        return 'Не оплачено '
    }
  }, [itemBody.status])
  return (
    <div onClick={showSub} className={styles.wrapperCard}>
      <div className={classNames(styles.wrapperCardItem, {
        [styles.borderWrapper]: id === itemBody.id
      })}>
        <div className={styles.tableSellBody}>
          <div className={classNames(styles.tableCellHeaderBody)}>
            {itemBody.id}
          </div>
          <div
            className={classNames(styles.tableCellHeaderBody, buyClass)}
          >
            {buyText}
          </div>

          <div className={styles.tableCellHeaderBody}>
            {`${thousand(itemBody.price)} ₽`}
          </div>
          <div className={styles.tableCellHeaderBody}>
            {itemBody.userData.phone}
          </div>
          <div
            className={classNames(
              styles.tableCellHeaderBody,
              styles.dateColumn,
              styles.dateBody
            )}
          >
            <TextLine size={12} weight={500}>

              {prindTimeDate(itemBody.createdAt).split(" ")[0]}
              {` ${sumDate(prindTimeDate(itemBody.createdAt).split(" ")[1], new Date().getTimezoneOffset())}`}
            </TextLine>
          </div>
        </div>
        <div
          className={classNames({
            [styles.tableSellBodySub]: id !== itemBody.id,
            [styles.__activeTableSellBodySub]: id === itemBody.id
          })}
        >

          {itemBody.items.map(item => <TableSellBodySub key={item.id} trips={item} />)}
        </div>
      </div>
    </div>
  );
};

export default TableSellBody;
