import React, { FC, useState } from 'react';
import { RoutesProps } from 'react-router-dom';
import { Button } from '../../simples/Button';
import { Input } from '../../simples/Input';
import { ItemHolder } from '../../simples/ItemHolder';
import { ModalClose } from '../../simples/ModalClose';
import styles from './AddRecordModal.module.scss'



type Props = RoutesProps & {
    title: string
    onClose: () => void
    onSubmit: (value: string) => void
}

const AddRecordModal: FC<Props> = ({ title, onSubmit, onClose, children }) => {
    const [value, setVlue] = useState<string>('')
    const onHandleSubmit = () => {
        onSubmit(value)
    }
    // console.log('value', value);

    return (
        <ModalClose classes={{
            body: styles.modal
        }} onClose={onClose} title={title}>
            <ItemHolder>
                <Input onChange={setVlue} value={value} />
            </ItemHolder>
            <Button className={styles.btn} onClick={onHandleSubmit}>Создать</Button>
        </ModalClose>
    )
}
export default AddRecordModal