import React, { FC } from "react";
import { RouteProps } from "react-router-dom";
import { Button, ButtonColors } from "../../simples/Button";
import styles from "./AddButtonSimple.module.scss";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

type Props = RouteProps & {
  onClick: () => void;
};

const AddButtonSimple: FC<Props> = ({ onClick, children }) => {
  return (
    <div
      className={styles.addBtn}
      onClick={onClick}
      color={ButtonColors.Secondary}
    >
      <PlusIcon />
    </div>
  );
};
export default React.memo(AddButtonSimple);
