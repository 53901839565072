import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../constants/config';
import styles from './MenuItem.module.scss'

export type MenuItemType = {
    icon: JSX.Element
    label: string
    route: Routes
}

type Props = {
    data: MenuItemType
}

const MenuItem: FC<Props> = ({ data }) => {

    const route: string = useLocation().pathname

    const isActive: boolean = useMemo(() => {

        return route.includes(data.route)
    }, [data.route, route])

    return (
        <div
            style={{
                opacity: isActive ? 1 : 0.5
            }}
            className={classNames(styles.root, {
                [styles.activeMenu]: isActive
            })}>
            <div className={classNames(styles.icon, {
                [styles.activeIcon]: isActive
            })}>{data.icon}</div>
            <NavLink
                className={({ isActive }) =>
                    isActive ? styles.activeLink : styles.link
                }
                to={data.route}>{data.label}</NavLink>
        </div>
    )
}
export default React.memo(MenuItem)