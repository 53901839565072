import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useAppSelector } from '../../../../../hooks/store';
import { selectUser } from '../../../../../store/slice/auth';
import { selectCities, selectTripTypes } from '../../../../../store/slice/storage';
import { UserRole } from '../../../../../types/types';
import { AddButton } from '../../../../complexes/AddButton';
import { Dropdown } from '../../../../simples/Dropdown';
import { OptionType } from '../../../../simples/Dropdown/Dropdown';
import { ExcurtionFilter, FilterContext } from '../../Excurtions';
import styles from './TopFilter.module.scss'



type Props = {
    onAddTrip: () => void
}

const TopFilter: FC<Props> = ({ onAddTrip }) => {

    const [filter, setFilter] = useContext(FilterContext)
    const user = useAppSelector(selectUser)

    const cities = useAppSelector(selectCities)
    const tripTypes = useAppSelector(selectTripTypes)

    const citiesOptions = useMemo((): OptionType[] => {
        return cities.map((item) => ({
            value: String(item.id),
            label: item.name
        }))
    }, [cities])
    const tripTypesOptions = useMemo((): OptionType[] => {
        return tripTypes.map((item) => ({
            value: String(item.id),
            label: item.name
        }))
    }, [tripTypes])

    const changeFilter = useCallback((value: string, key: keyof ExcurtionFilter) => {
        if (key === 'tripTypes' || key === 'cities') {
            const names = filter[key]
            if (names.includes(value)) {
                setFilter({ ...filter, [key]: filter[key].filter(i => i !== value) })
            } else {
                setFilter({ ...filter, [key]: [...filter[key], value] })
            }
        }
    }, [filter, setFilter])

    return (
        <div className={styles.root}>
            <div className={styles.filter}>
                {user!.role !== UserRole.MANAGER && < Dropdown
                    classes={{
                        root: styles.dropdown
                    }}
                    placeholder={'По городу'}
                    isMultiple
                    value={filter.cities}
                    options={citiesOptions}
                    onChange={({ value }) => changeFilter(value, 'cities')}
                />}
                <Dropdown
                    classes={{
                        root: styles.dropdown
                    }}
                    isMultiple
                    placeholder={'Тип экскурсии'}
                    value={filter.tripTypes}
                    options={tripTypesOptions}
                    onChange={({ value }) => changeFilter(value, 'tripTypes')}
                />
            </div>
            <AddButton onClick={(onAddTrip)}>Добавить экскурсию</AddButton>
        </div>
    )
}
export default TopFilter