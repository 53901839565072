import { ApexOptions } from 'apexcharts';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Widget.module.scss'
import { Btn } from './parts/Btn';
import { BtnType } from './parts/Btn/Btn';
import { TextLine } from '../../simples/TextLine';
import './Chart.scss'
import { ExcurtionItem } from './parts/ExcurtionItem';
import ReactApexChart from 'react-apexcharts';
import { EditExcurtionWidgetModal } from '../EditExcurtionWidgetModal';
import { ExcurtionWidget } from '../EditExcurtionWidgetModal/EditExcurtionWidgetModal';
import { WidgetType } from '../../../types/types';
import { useCreateWidgetMutation, useUpdateWidgetMutation } from '../../../services/WidgetService';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/deleteIcon.svg'
import { setWidget } from '../../../store/slice/widgets';
import { utimesSync } from 'fs';



type Props = {
    widget: WidgetType
    onDelete?: (id: number) => void
}

//  основные настройки
let options: ApexOptions = {
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },

    xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                colors: ['#333'],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },


        },
        crosshairs: {
            show: true,
            width: 1,
            position: 'back',
            opacity: 0.9,
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 3,
            },
            fill: {
                type: 'solid',
                color: '#B1B9C4',
                gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                },
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 10,
                opacity: 0.4,
            },
        },
        tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
                fontSize: '0',
                fontFamily: '0',

            },
        },
    },
    markers: {
        size: [4]
    },

};

const Widget: FC<Props> = ({ widget, onDelete }) => {
    // console.log(widget);

    const charRef = useRef<ReactApexChart>(null)
    const [createWidget, result] = useCreateWidgetMutation()
    const [updateWidget] = useUpdateWidgetMutation()

    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [activeExcurtion, setActiveExcurtion] = useState<number>()

    const [widgetTrips, setWidgetTrips] = useState(widget.data)

    const trips = useMemo(() => {
        if (widgetTrips)
            return widgetTrips?.map((item) => ({
                name: item?.name || '',
                data: Object.values(item.items),

            }))
        else
            return []
    }, [widgetTrips])

    useEffect(() => {
        setWidgetTrips(widget.data)
    }, [widget.data, widget.id])

    const xaxis = useMemo(() => {
        if (widget.data && widget.data.length > 0)
            return Object.keys(widget.data[0].items)
        else
            return []
    }, [widget])


    /**
     * прозрачность для графиков
     */
    const [opacity, setOpacity] = useState<Array<number>>([])

    /**
     * цвета графиков
     */
    const [colors, setColors] = useState<Array<string>>([])


    const tooltip: ApexTooltip = {
        // theme: 'light',

        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {

            const items = w.globals.seriesNames.map((item: any, index: number) => {
                return (
                    `<div key={${index}} class="item">
                        <span class="tooltip_text">
                            <span style="
                                background-color: ${w.globals.colors[index]}
                            " class="tooltip_color"></span>
                            <span class="tooltip_name">
                            ${item || ''}
                            </span> 
                        </span>
                        <span style="border-color:  ${w.globals.colors[index]}" class="tooltip_line"></span>
                        <span class="tooltip_value">${w.globals.series[index][dataPointIndex]}</span>
                    </div>
                    `
                )
            })

            return (
                '<div class="barTooltip">' +
                '<div class="barTooltipTop">' +
                w.globals.categoryLabels[dataPointIndex] +
                '</div>' + items.join('') +

                '</div>'
            )
        },
        followCursor: true,
        // shared: false
    }

    const onChangeWidget = useCallback(() => {

        setOpenFilter(true)

    }, [])

    /**
     * удаление выбранной экскурсии
     */
    const onDeleteExcurtion = useCallback(() => {

        setWidgetTrips(prev => prev?.filter((i, index) => index !== activeExcurtion))

    }, [activeExcurtion])

    const onChartMounted = (chartContext: any, config: any) => {

        const labelDiv = document.querySelector('.apexcharts-xaxistooltip-text')
        if (labelDiv) {

            // @ts-ignore
            labelDiv.style.backgroundColor = 'yellow';
        }
        setColors(config.globals.colors);
        let data = config.globals.series
        setOpacity(data.map((i: any) => 1))

    }
    const onDataPointEnter = (event: any, chartContext: any, config: any) => {

    }

    /**
     * 
     * @param index выделение экскурсии
     */
    const onExcurtionClick = (index: number) => {
        if (index !== activeExcurtion) {
            setOpacity(prev => prev.map((i, ind) => ind === index ? 1 : 0.2))
            setActiveExcurtion(index)
        } else {
            setOpacity(prev => prev.map((i, ind) => 1))
            setActiveExcurtion(undefined)
        }

    }

    /**
     * закрытие модалки фильтра
     */
    const onCloseFilterModal = useCallback(() => {
        setOpenFilter(false)
    }, [])

    /**
     * применение фильтра
     */
    const onSaveFilter = useCallback(async (filter: WidgetType) => {
        if (filter.id) {
            await updateWidget(filter)
        } else {
            await createWidget(filter)
        }
        // await createWidget(filter)
        setOpenFilter(false)
    }, [createWidget, updateWidget])


    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <TextLine size={26} weight={600}>{widget.type === 1 ? 'График по экскурсии' : 'График по гиду'}</TextLine>
                <div className={styles.topRight}>
                    <div className={styles.excurtions}>
                        {
                            widgetTrips?.map((item, index) => <ExcurtionItem
                                key={index}
                                color={colors[widgetTrips.indexOf(item)]}
                                name={item?.name || ''}
                                isActive={index === activeExcurtion}
                                onClick={() => onExcurtionClick(index)}
                            />)
                        }
                    </div>
                    <div className={styles.btns}>
                        <div className={styles.plusBtn}>
                            <Btn type={BtnType.PLUS} onClick={onChangeWidget} />
                        </div>
                        <Btn type={BtnType.MINUS} onClick={onDeleteExcurtion} />
                        {onDelete && widget.id && <div onClick={() => onDelete(widget.id || 0)} className={styles.delIcon}><DeleteIcon width={16} /></div>}
                    </div>
                </div>
            </div>
            <div id={'chart'} className={styles.chartWrapper}>
                <ReactApexChart
                    ref={charRef}
                    options={{
                        ...options,
                        legend: {
                            show: true,
                        },
                        tooltip: tooltip,
                        xaxis: { categories: xaxis },
                        chart: {
                            events: {

                                markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => { onExcurtionClick(seriesIndex) },
                                mounted: onChartMounted,
                                dataPointMouseEnter: onDataPointEnter,
                            },
                            toolbar: {
                                show: false
                            },
                            selection: {
                                enabled: false
                            },
                            zoom: {
                                enabled: false
                            }

                        },

                        plotOptions: {

                            area: {
                                fillTo: 'end',
                            }
                        },
                        fill: {
                            opacity: opacity,
                        },
                    }}
                    series={trips || []}
                    width={'100%'}
                    height={350}
                    type={'line'}
                />

                {/* <div className={styles.blurWrapper}
                    style={{
                        left: 150
                    }}

                >
                    <div

                        className={styles.blurBlock} />
                </div> */}

            </div>
            {openFilter && <EditExcurtionWidgetModal
                widgetFilter={widget}
                onSubmit={onSaveFilter}
                onClose={onCloseFilterModal}
            />
            }
        </div>
    )
}
export default Widget