import { Endpoints } from "../constants/constants";
import { arrayToQuery, sendFormData, toQueryString } from "../constants/helper";
import {
  DateTripType,
  ImageType,
  Sort,
  SortBy,
  TripType
} from "../types/types";
import api from "../utils/api";

type getTripType = {
  name?: string;
  persons?: string;
  adults?: string;
  children?: string;
  tripsIds?: Array<number>;
  citiesIds?: Array<string>;
  tripTypesIds?: Array<string>;
  published?: 0 | 1 | 2; // 2- all
  deleted?: 0 | 1 | 2; // 2- all
  sortBy?: SortBy;
  sort?: Sort;
  offset?: number;
  limit?: number;
};

export type UpdateTripType = {
  id: number;
  name?: string;
  guideId?: number;
  phone?: string;
  transferTypeId: number;
  description?: string;
  content?: string;
  cityId: number;
  tripTypeId: number;
  startLocationId: number;
  endLocationId: number;
  adults: number;
  duration: number;
  pricePerAdult: number;
  pricePerChild: number;
  dates?: string;
  published?: 0 | 1;
  periodic?: 0 | 1;
};

export class TripsService {
  static async getTrips(data: getTripType): Promise<Array<TripType>> {

    const response = await api.get(`${Endpoints.GET_TRIPS}${toQueryString(data)}`);

    return response.data.data;
  }

  // one trip by id
  static async getTripById(id: number): Promise<TripType> {
    const response = await api.get(`${Endpoints.GET_TRIP_BY_ID}/${id}`);
    return response.data.data;
  }

  // one trip dates by id
  static async getTripDatesById(id: number): Promise<DateTripType[]> {
    const response = await api.get(
      `${Endpoints.TRIP_DATES_BY_ID_GET}?tripId=${id}&limit=1000&isGuide=1`
    );
    return response.data.data;
  }

  //upload photos
  static async uploadImages(file: File, tripId: number): Promise<ImageType> {
    const fd = new FormData();
    fd.append("file", file);
    const response = await api.post(
      `${Endpoints.UPLOAD_PHOTO_TRIP}/${tripId}`,
      fd
    );
    return response.data.data;
  }

  //delete photos
  static async deleteImage(photoId: number): Promise<boolean> {
    const response = await api.get(`${Endpoints.DELETE_PHOTO_TRIP}/${photoId}`);
    return !!response.data.data;
  }

  // create trip
  static async createTrip(): Promise<number> {
    const response = await api.get(Endpoints.CREATE_TRIP);
    // return trip id
    return response.data.data.id;
  }

  // update trip
  static async updateTrip(trip: UpdateTripType, id: number): Promise<TripType> {
    const response = await api.post(
      `${Endpoints.UPDATE_TRIP}/${id}`,
      sendFormData(trip)
    );
    return response.data.data;
  }
  //delete trip
  static async deleteTrip(tripId: number):Promise<boolean> {
    try {
      await api.delete(`${Endpoints.TRIP_DELETE}/${tripId}`)
      return true
    } catch (error) {
      return false
    }
    return false
  }
}
