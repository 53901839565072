import React, { FC, useCallback } from 'react';
import { ImageType } from '../../../types/types';
import styles from './ImagesBlock.module.scss'
import Item from './Item';



type Props = {
    label?: string
    onDelete?: (id: number) => void
    onClick?: (id: number) => void
    images?: Array<ImageType>
}

const ImagesBlock: FC<Props> = ({ label, onDelete, onClick, images }) => {

    const onHandleDelete = useCallback((id: number) => {
        onDelete && onDelete(id)
    }, [onDelete])
    return (
        <div className={styles.root}>
            {label && <div className={styles.label}></div>}

            {
                images && images.map((image) => <Item key={image.id} image={image} onDelete={onHandleDelete} />)
            }
        </div>
    )
}
export default ImagesBlock