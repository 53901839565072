import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Endpoints } from '../constants/constants'
import { sendFormData, toQueryString } from '../constants/helper'
import { BASE_URL } from '../constants/location'
import { PaginationType, ResponseType } from '../types/customTypes'
import { NoteType } from '../types/types'
import api from '../utils/api'

type NotesType = {
    readed?: number
    offset?: number
    limit?: number
}
export class NoteService {
    static async getNotes(data: NotesType): Promise<NoteType[]> {
        const response = await api.get<ResponseType<NoteType[]>>(`${Endpoints.NOTE_GET}${toQueryString(data)}`)
        return response.data.data
    }
    // create note
    static async createNote(data: {
        content: string
        name?: string
    }): Promise<NoteType> {
        const response = await api.post<ResponseType<NoteType>>(Endpoints.NOTE_CREATE, sendFormData(data))
        return response.data.data
    }
    // update note
    static async updateNote(data: NoteType): Promise<NoteType> {
        const response = await api.post<ResponseType<NoteType>>(`${Endpoints.NOTE_UPDATE}/${data.id}`, sendFormData(data))
        return response.data.data
    }
    // delete note
    static async deleteNote(noteId: number) {
        const response = await api.delete(`${Endpoints.NOTE_DELETE}/${noteId}`)
        return response.data.data
    }
    // read notes
    static async readNote(id: number): Promise<{
        message: string
        success: boolean
    }> {
       try {
        const response = await api.get(`${Endpoints.NOTE_READ}/${id}`)
        return ({
            message: response.data.data[0],
            success: true
        })
       } catch (error) {
        return ({
            message: 'Ошибка прочтения',
            success: false
        })
       }
    }
}

export const noteApi = createApi({
    reducerPath: 'noteApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken')
            if (token) {
                headers.set('Authorization', `${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Notes'],

    endpoints: (builder) => ({
        getNotes: builder.query({
            query: (data: NotesType) => `${Endpoints.NOTE_GET}${toQueryString(data)}`,
            transformResponse: (rawResult: { data: NoteType[] }, meta) => {
                return rawResult.data.length > 0 ? rawResult.data[0] : undefined
            },
            providesTags: ['Notes']
        }),
        readNote: builder.mutation({
            query: (id: number) =>  ({
                url: `${Endpoints.NOTE_READ}/${id}`,
                method: 'GET'
            }),
            invalidatesTags: ['Notes']
        }),
        createNote: builder.mutation({
            query: (note: NoteType) => ({
                url: Endpoints.NOTE_CREATE,
                method: 'POST',
                body: sendFormData(note)
            }),
            invalidatesTags: ['Notes']

        }),
        updateNote: builder.mutation({
            query: (note: NoteType) => ({
                url: `${Endpoints.NOTE_UPDATE}/${note.id}`,
                method: 'POST',
                body: sendFormData(note)
            }),
            invalidatesTags: ['Notes']
        }),
        deleteNote: builder.mutation({
            query: (id: number) => ({
                url: `${Endpoints.NOTE_DELETE}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Notes']
        })

    })
})

export const { useCreateNoteMutation, useDeleteNoteMutation, useGetNotesQuery, useUpdateNoteMutation, useReadNoteMutation } = noteApi