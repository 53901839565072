import produce from 'immer';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { CheckBox } from '../../simples/CheckBox';
import { Dropdown } from '../../simples/Dropdown';
import { OptionType } from '../../simples/Dropdown/Dropdown';
import { ItemHolder } from '../../simples/ItemHolder';
import { ModalClose } from '../../simples/ModalClose';
import styles from './EditExcurtionWidgetModal.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { TextLine } from '../../simples/TextLine';

import { Button } from '../../simples/Button';
import { addZero, dayInMonth, getSendDate } from '../../../constants/helper';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchCities, selectCities, selectTripTypes } from '../../../store/slice/storage';
import { TripsService } from '../../../services/TripsService';
import { IsAll, PeriodType, ProfileType, TripType, UserRole, WidgetFilterBy, WidgetKind, WidgetType } from '../../../types/types';
import { UsersService } from '../../../services/UsersService';
import { selectUser } from '../../../store/slice/auth';

registerLocale('ru', ru)






export enum DataWidgetType {
    SUMM = 'summ',
    COUNT = 'count'
}

export type ExcurtionWidget = {
    uid?: string
    cities: Array<string>
    tripTypes: Array<string>
    tripNames: Array<string>
    periodType?: PeriodType
    dataType?: WidgetFilterBy
    date?: Date
    guidsIds: Array<string>
    allCities?: IsAll
    allGuids?: IsAll
    allTripTypes?: IsAll
    allTrips?: IsAll
}

const _PERIOD_OPTIONS: OptionType[] = [
    {
        value: PeriodType.YEAR,
        label: 'Год'
    },
    {
        value: PeriodType.MONTH,
        label: 'Месяц'
    },
    {
        value: PeriodType.RANGE,
        label: 'Произвольный период'
    }
]

const saleTypeOptions: OptionType[] = [
    {
        value: `${WidgetFilterBy.SALE_COUNT}`,
        label: 'По количеству продаж'
    },
    {
        value: `${WidgetFilterBy.SUMM}`,
        label: 'По сумме продаж'
    },

]
type Props = {
    widgetFilter?: WidgetType
    onClose: () => void
    onSubmit: (filter: WidgetType) => void
}

const EditExcurtionWidgetModal: FC<Props> = ({ onClose, onSubmit, widgetFilter }) => {

    // const dispatch = useAppDispatch();
    // useEffect(( ) => {
    //     dispatch(fetchCities());
    // },[])



    const isGuidWidget = widgetFilter?.type === WidgetKind.GUID

    const cities = useAppSelector(selectCities)
    const user = useAppSelector(selectUser)

    const tripTypes = useAppSelector(selectTripTypes)

    const [loading, setLoading] = useState<boolean>(false)
    const has = useRef(true)
    const offset = useRef(0)

    const [searchNames, setSerchNames] = useState<string>("")

    const hasGuids = useRef(true);
    const offsetGuids = useRef(0);

    const [searchGuids, setSearchGuids] = useState<string>("");
    const [guids, setGuids] = useState<Array<OptionType>>([]);
    const currentGuids = useRef<Array<OptionType>>(guids);
    currentGuids.current = guids;

    const [widget, setWidget] = useState<ExcurtionWidget>({
        cities: widgetFilter?.cityIds?.map(i => String(i)) || [],
        tripTypes: widgetFilter?.tripTypesIds || [],
        tripNames: widgetFilter?.tripIds || [],
        date: new Date(),
        guidsIds: widgetFilter?.guideIds?.map(i => String(i)) || [],
        allCities: widgetFilter?.allCities || IsAll.NOT_ALL,
        allTrips: widgetFilter?.allTrips || IsAll.NOT_ALL,
        allTripTypes: widgetFilter?.allTripTypes || IsAll.NOT_ALL,
        allGuids: widgetFilter?.allGuides || IsAll.NOT_ALL,
        dataType: widgetFilter?.filterBy,
        periodType: widgetFilter?.dateType
    })

    const [trips, setTrips] = useState<Array<OptionType>>([])

    const currentTrips = useRef<Array<OptionType>>(trips)
    currentTrips.current = trips

    const [dateRange, setDateRange] = useState([
        widgetFilter && widgetFilter.startDate ? new Date(widgetFilter.startDate) : null,
        widgetFilter && widgetFilter.endDate ? new Date(widgetFilter.endDate) : null
    ]);
    const [startDate, endDate] = dateRange;


    const periodLabel = useMemo(() => {
        switch (widget.periodType) {
            case PeriodType.MONTH:
                return 'Выберите месяц'
            case PeriodType.YEAR:
                return 'Выберите год'
            case PeriodType.RANGE:
                return 'Выберите период'


            default:
                return 'Выберите период'
        }
    }, [widget.periodType])

    const dateFormat = useMemo(() => {
        switch (widget.periodType) {
            case PeriodType.MONTH:
                return "MM/yyyy"
            case PeriodType.YEAR:
                return "yyyy"


            default:
                break;
        }
    }, [widget.periodType])

    /**
     * 
     * cities options
     */
    const cityOptions: OptionType[] = useMemo((): OptionType[] => {

        if (cities) {

            return cities.map((item) => ({
                value: String(item.id),
                label: item.name
            }))
        }
        return []
    }, [cities])

    console.log(tripTypes);

    /**
     * trip type options
     */
    const tripTypesOptions: OptionType[] = useMemo((): OptionType[] => {
        if (tripTypes) {
            return tripTypes.map((item) => ({
                value: String(item.id),
                label: item.name
            }))
        }
        return []
    }, [tripTypes])


    /**
     * получение списка экскурсий
     */

    const getTrips = useCallback(async () => {
        if (!has.current || loading) {
            return
        }
        try {

            const response = await TripsService.getTrips({
                tripTypesIds: widget.tripTypes,
                published: 2,
                offset: offset.current,
                limit: 100,
                name: searchNames,
                citiesIds: user?.role === UserRole.MANAGER ? [cities[0].id.toString()] : undefined

            })

            if (!response.length) {
                has.current = false
            }

            offset.current = offset.current + response.length

            setTrips([...currentTrips.current, ...createOptions(response)])

        } catch (error) {

        }

    }, [loading, searchNames, widget.tripTypes])
    const clearTrips = async () => {
        offset.current = 0
        has.current = true
        currentTrips.current = []
        setTrips([])
        setLoading(false)
        await getTrips()
    }

    useEffect(() => {
        clearTrips()
    }, [widget.tripTypes, searchNames])



    const getGuids = useCallback(
        async () => {
            if (!hasGuids.current || loading) {
                return;
            }
            setLoading(true);
            try {
                const response = await UsersService.getUsers({
                    offset: offsetGuids.current,
                    limit: 100,
                    search: searchGuids,
                    role: UserRole.GUID
                });

                if (!response.length) {
                    hasGuids.current = false;
                    setLoading(false);
                    return;
                }

                offsetGuids.current = offsetGuids.current + response.length;

                setGuids([...currentGuids.current, ...createGuidsOptions(response)]);
            } catch (error) { }
            setLoading(false);
        },
        [loading, searchGuids]
    );

    const clearGetGuids = () => {
        offsetGuids.current = 0;
        hasGuids.current = true;
        setGuids([]);
        currentGuids.current = [];
        getGuids().then();
    };

    useEffect(
        () => {
            clearGetGuids();
        },
        [searchGuids]
    );

    /**
     * 
     */
    const onChangeWidget = useCallback((value, key: keyof ExcurtionWidget) => {
        setWidget(produce(
            draft => {
                const f: any = draft
                f[key] = value
            }
        ))
    }, [])

    /**
     * 
     */
    const onChangeWidgetArray = useCallback((value: string, key: keyof ExcurtionWidget) => {
        if (key === 'tripTypes' || key === 'tripNames' || key === 'cities' || key === 'guidsIds') {
            const names = widget[key]
            if (names.includes(value)) {
                setWidget(prev => ({ ...prev, [key]: prev[key].filter(i => i !== value) }))
            } else {
                setWidget(prev => ({ ...prev, [key]: [...prev[key], value] }))
            }
        }
    }, [widget])

    const onChangeDate = (date: any) => {
        setWidget(prev => ({ ...prev, date: date }))
    }

    const getDay = (date: any) => {
        const dayNumber = new Date(date).getDay()
        return dayNumber === 6 || dayNumber === 0 ? 'weekend' : 'workday'
    }

    const getPeriodDate = useCallback((date: Date) => {
        let sDate: string = ''
        let eDate: string = ''
        if (!widget.date) return { sDate, eDate }
        if (widget.periodType === PeriodType.YEAR) {
            sDate = `${date.getFullYear()}-01-01`
            eDate = `${date.getFullYear()}-12-31`
        }
        if (widget.periodType === PeriodType.MONTH) {
            const countOfDays = dayInMonth(date)
            sDate = `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-01`
            eDate = `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${countOfDays}`
        }

        return { sDate, eDate }
    }, [widget.periodType, widget.date])

    /**
     * применение фильтра к виджету
     */
    const onHandleSaveFilter = useCallback(() => {

        const sDate = widget.periodType === PeriodType.MONTH || widget.periodType === PeriodType.YEAR ? getPeriodDate(widget.date || new Date()).sDate : getSendDate(startDate || new Date())
        const eDate = widget.periodType === PeriodType.MONTH || widget.periodType === PeriodType.YEAR ? getPeriodDate(widget.date || new Date()).eDate : getSendDate(endDate || new Date())

        onSubmit({
            id: widgetFilter?.id,
            tripIds: widget.tripNames,
            tripTypesIds: widget.tripTypes,
            cityIds: widget.cities.map(i => +i),
            guideIds: widget.guidsIds.map(i => +i),
            allCities: isGuidWidget ? IsAll.ALL : widget.allCities,
            allGuides: isGuidWidget ? widget.allGuids : IsAll.ALL,
            allTripTypes: isGuidWidget ? IsAll.ALL : widget.allTripTypes,
            allTrips: isGuidWidget ? IsAll.ALL : widget.allTrips,
            startDate: sDate,
            endDate: eDate,
            filterBy: widget.dataType || WidgetFilterBy.SUMM,
            type: widgetFilter?.type === 0 ? WidgetKind.GUID : WidgetKind.TRIPS,
            dateType: widget?.periodType,
        })
    }, [endDate, getPeriodDate, isGuidWidget, onSubmit, startDate, widget.allCities, widget.allGuids, widget.allTripTypes, widget.allTrips, widget.cities, widget.dataType, widget.date, widget.guidsIds, widget.periodType, widget.tripNames, widget.tripTypes, widgetFilter?.id, widgetFilter?.type])

    /**
     * check all
     */
    const onCheckAll = (key: keyof ExcurtionWidget) => {
        switch (key) {
            case 'allCities':
                setWidget({ ...widget, allCities: widget[key] === IsAll.ALL ? IsAll.NOT_ALL : IsAll.ALL })
                break

            case 'allTripTypes':
                setWidget({ ...widget, allTripTypes: widget[key] === IsAll.ALL ? IsAll.NOT_ALL : IsAll.ALL })
                break
            case 'allTrips':
                setWidget({ ...widget, allTrips: widget[key] === IsAll.ALL ? IsAll.NOT_ALL : IsAll.ALL })
                break

            case "allGuids":
                setWidget({ ...widget, allGuids: widget[key] === IsAll.ALL ? IsAll.NOT_ALL : IsAll.ALL })
                break

            default:
                break;
        }
    }

    const guidLabel = useMemo(() => {
        let label = ''
        if (widget.allGuids) {
            label = 'Выбраны все гиды'
        }

        return label
    }, [widget.allGuids])


    return (
        <ModalClose
            title={widgetFilter?.type === 0 ? 'Гид' : 'Экскурсии'}
            onClose={onClose}
        >

            {!isGuidWidget && <ItemHolder>
                <Dropdown
                    label='Город'
                    value={widget?.cities?.map(i => String(i))}
                    options={cityOptions}
                    onChange={(data) => onChangeWidgetArray(data.value, 'cities')}
                    isMultiple
                    disabled={!!widget.allCities}
                    rightLabel={<CheckBox labelLeft={'Выбрать все'} checked={widget.allCities === IsAll.ALL} onChange={() => onCheckAll('allCities')} />}
                    placeholder={'Выберите город'}
                />
            </ItemHolder>}
            {!isGuidWidget && <ItemHolder>
                <Dropdown
                    label='Тип экскурсии'
                    value={widget.tripTypes.map(i => String(i))}
                    disabled={!!widget.allTripTypes}
                    options={tripTypesOptions}
                    onChange={(data) => onChangeWidgetArray(data.value, 'tripTypes')}
                    isMultiple
                    rightLabel={<CheckBox labelLeft={'Выбрать все'} checked={widget.allTripTypes === IsAll.ALL} onChange={() => onCheckAll('allTripTypes')} />}
                    placeholder={'Выберите тип экскурсии'}
                />
            </ItemHolder>}
            {!isGuidWidget && <ItemHolder>
                <Dropdown
                    withSearch
                    onRechedEnd={getTrips}
                    onSearch={setSerchNames}
                    // disabled={widget.tripNames.length === 0}
                    disabled={!!widget.allTrips}
                    label='Название'
                    value={widget.tripNames.map(i => String(i))}
                    options={trips}
                    onChange={(data) => onChangeWidgetArray(data.value, 'tripNames')}
                    isMultiple
                    rightLabel={<CheckBox labelLeft={'Выбрать все'} checked={widget.allTrips === IsAll.ALL} onChange={() => onCheckAll('allTrips')} />}
                    placeholder={'Выберите название экскурсии'}
                />
            </ItemHolder>}
            {isGuidWidget && <ItemHolder>
                <Dropdown
                    label="Гид"
                    withSearch
                    labelInner={guidLabel}
                    onRechedEnd={getGuids}
                    onSearch={setSearchGuids}
                    isMultiple
                    value={widget.guidsIds ? widget.guidsIds.map(i => String(i)) : []}
                    options={guids}
                    disabled={!!widget.allGuids}
                    onChange={data => onChangeWidgetArray(data.value, "guidsIds")}
                    rightLabel={
                        <CheckBox
                            labelLeft={'Выбрать все'}
                            checked={!!widget.allGuids}
                            onChange={() => onCheckAll("allGuids")}
                        />
                    }
                    placeholder={"Выберите"}
                />
            </ItemHolder>}
            <ItemHolder>
                <Dropdown
                    label='Продажи'
                    value={[`${widget.dataType !== null ? widget.dataType : ''}`]}
                    options={saleTypeOptions}
                    onChange={(data) => onChangeWidget(data.value, 'dataType')}
                    placeholder={'Выберите тип'}
                />
            </ItemHolder>
            <ItemHolder>
                <Dropdown
                    label='Период'
                    value={[widget.periodType || '']}
                    options={_PERIOD_OPTIONS}
                    onChange={(data) => onChangeWidget(data.value, 'periodType')}
                    placeholder={'Выберите период'}
                />
            </ItemHolder>

            <ItemHolder>
                {/* {
                    (widget.periodType === PeriodType.YEAR) && <DatePicker onChange={onChngeYear}  locale={'ru'} />
                } */}

                {widget.periodType &&
                    (widget.periodType === PeriodType.MONTH ||
                        widget.periodType === PeriodType.YEAR) &&
                    <div className="">
                        <TextLine display={'block'} mb={8} weight={600} size={16}>{periodLabel}</TextLine>
                        <DatePicker
                            popperPlacement={'top'}

                            selected={new Date(widget.date || new Date())}
                            showMonthYearPicker={widget.periodType === PeriodType.MONTH}
                            showFullMonthYearPicker={widget.periodType === PeriodType.MONTH}
                            showYearPicker={widget.periodType === PeriodType.YEAR}
                            onChange={onChangeDate}
                            dateFormat={dateFormat}
                            locale={'ru'}
                            calendarClassName={styles.calendar}
                        />
                    </div>
                }
                {widget.periodType &&
                    widget.periodType === PeriodType.RANGE &&
                    <div className="">
                        <TextLine display={'block'} mb={8} weight={600} size={16}>{periodLabel}</TextLine>
                        <DatePicker
                            popperPlacement={'top'}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update: any) => {
                                setDateRange(update);
                            }}
                            dateFormat={('dd-MM-yyyy')}
                            isClearable={true}
                            locale={'ru'}
                            dayClassName={getDay}
                        />
                    </div>
                }
            </ItemHolder>

            <Button onClick={onHandleSaveFilter} className={styles.button}>Подтвердить</Button>
        </ModalClose>
    )
}
export default EditExcurtionWidgetModal


function createOptions(data: TripType[]): OptionType[] {
    return data.map((i) => ({
        value: String(i.id),
        label: i.name
    }))
}
function createGuidsOptions(data: ProfileType[]): OptionType[] {
    return data.map(i => ({
        value: String(i.id),
        label: i.firstName
    }));
}