import { KeyNumber } from "./customTypes"

export type WithId = {
  id: number
}
export enum BusyGuid {
  UNAVALIBLE,
  AVALIBLE,
}

export type ProfileType = {
  isBusy: BusyGuid
  id: number;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  secondName?: string;
  dateOfBirth?: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
  loggedAt?: string;
  active: 1 | 0;
  cityId: number,
  city: CityType
  photoUrl: string;
  favoriteTripsIds: number[];
  cart?: any[];
};

export enum UserRole {
  USER = "user",
  GUID = "guide",
  ADMIN = "admin",
  MANAGER = 'manager'
}

export enum SortBy {
  NAME = "name",
  TOTAL_VIEW = "totalViews",
  TOTAL_RATING = "totalRating",
  TOTAL_REVIEWS = "totalReviews"
}

export enum Sort {
  ASC = "asc",
  DESC = "desc"
}

// CITIES
export type CityType = {
  id: number;
  name: string;
  description: string | null;
  latitude: number;
  longitude: number;
  createdAt: string;
  updatedAt: string;
};

// trip-type
export type TripTypesType = {
  id: number;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
};

//image
export type ImageType = {
  id: number;
  name: string;
  description: string;
  url: string;
  fileId: number;
  tripId: number;
  createdAt: string;
  updatedAt: string;
};

// guid
export type GuideType = {
  id: number;
  phone: string;
  email: string;
  city?: CityType
  firstName: string;
  lastName: string;
  secondName: string;
  dateOfBirth: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  loggedAt: string;
  active: number;
  photoUrl: string;
  favoriteTripsIds: number[];
  cartTripsIds: number[];
};

//trasfer
export type TransferType = {
  id: number;
  name: string;
  svgIcon: string;
  createdAt: string;
  updatedAt: string;
};

// trip type
export type TripType = {
  id: number;
  name: string;
  description: string;
  content: string;
  images: ImageType[];
  cityId: number;
  tripTypeId: number;
  startLocationId: number;
  endLocationId: number;
  published: number;
  pricePerAdult: number;
  pricePerChild: number
  duration: number;
  adults: number;
  children: number;
  persons: number;
  totalViews: number;
  totalReviews: number;
  totalRating: number;
  guide: GuideType;
  phone: string;
  transferType: TransferType;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  startLocation: LocationType;
  endLocation: LocationType;

  guideId?: number;
  transferTypeId?: number;

  periodic: 0 | 1; // выбран период или отдельные даты
};

/**
 * 
 */
export type TripStateType = {
  id: number;
  tripName: string;
  tripNumber: string;
  city: CityType;
  pricePerAdult: number;
  pricePerChild: number;
};

export type LocationType = {
  id: number;
  name: string;
  address: string;
  cityId: number;
  latitude: number;
  longitude: number;
  createdAt?: string;
  updatedAt?: string;
};

/**
 * trip dates
 */
export type TimeTripType = {
  id: number;
  dateId: number;
  time: string;
  adults: null;
  children: null;
};
export type DateTripType = {
  id: number;
  tripId: number;
  date: string;
  times: Array<TimeTripType>;
};

/**
 * NOTE
 */
export enum ReadedNoteType {
  READED,
  ALL,
}
export type NoteType = {

  id?: number
  name: string
  content: string
  createdAt?: string
  updatedAt?: string
  usersReaded?: Array<any>
}

/**
 * Widget
 */

export enum WidgetKind {
  GUID,
  TRIPS
}
export enum WidgetFilterBy {
  SUMM,
  SALE_COUNT
}

export enum IsAll {
  NOT_ALL,
  ALL
}

export type WidgetDataType = {
  name: string
  items: KeyNumber
}

export enum PeriodType {
  YEAR = 'year',
  MONTH = 'month',
  RANGE = 'week'
}
export type WidgetType = {
  id?: number
  name?: string
  type: WidgetKind
  cityIds?: number[]
  allCities?: IsAll
  guideIds?: number[]
  allGuides?: IsAll
  tripTypesIds?: string[]
  allTripTypes?: IsAll
  tripIds?: string[]
  allTrips?: IsAll
  filterBy: WidgetFilterBy
  startDate: string
  endDate: string
  createdAt?: string
  updatedAt?: string
  data?: WidgetDataType[]
  dateType?: PeriodType
}


// requests

export type RequestsType = {
  id: number;
  userId: number;
  status: number;
  price: number;
  items: RequestsItem[];
  createdAt: string;
  updatedAt: string;
  userData: UserDataType;
};

export enum RequestStatusType {
  NEW,
  PAIED,
  CANCELED,
  COMPLITED,
  PAIED_BY_CASH
}


export type RequestsItem = {
  id: number;
  trip: TripType;
  tripDate: string;
  tripTime: string;
  status: RequestStatusType;
  price: number;
  adults: number;
  children: number;
  createdAt: string;
  updatedAt: string;
  orderId: number
};

export type RequestsTrip = {
  id: number;
  name: string;
  description: string;
  content: string;
  images: ImageType[];
  startLocation: LocationType;
  endLocation: LocationType;
  guideId: number;
  cityId: number;
  tripTypeId: number;
  transferTypeId: number;
  startLocationId: number;
  endLocationId: number;
  published: number;
  pricePerAdult: number;
  pricePerChild: number;
  duration: number;
  adults: number;
  children: number;
  persons: number;
  periodic: number;
  totalViews: number;
  totalReviews: number;
  totalRating: number;
  guide: GuideType;
  phone: string;
  transferType: TransferType;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
};


// staff

export type UsersStaffType = {
  id: number;
  name: string;
  description: string;
  content: string;
  images: ImageType[];
  startLocation: LocationType;
  guideId: number;
  cityId: number;
  tripTypeId: number;
  transferTypeId: number;
  startLocationId: number;
  endLocationId: number;
  published: number;
  pricePerAdult: number;
  pricePerChild: number;
  duration: number;
  adults: number;
  children: number;
  persons: number;
  periodic: number;
  totalViews: number;
  totalReviews: number;
  totalRating: number;
  guide: StaffType;
  phone: string;
  transferType: TransferType;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type CartStaffType = {
  id: number;
  userId: number;
  trip: TripType;
  tripId: number;
  tripDate: string;
  tripTime: string;
  tripDateId: number;
  tripTimeId: number;
  adults: number;
  children: number;
  createdAt: string;
  updatedAt: string;
};

export type StaffType = {
  id: number;
  phone: string;
  email: string;
  firstName: string;
  lastName: null;
  secondName: null;
  dateOfBirth: null;
  role: string;
  createdAt: string;
  updatedAt: string;
  loggedAt: string;
  active: number;
  cityId: string;
  city: LocationType | null;
  photoUrl: string;
  favoriteTripsIds: number[];
  cart: CartStaffType[];
};

export type UsersCrearte = {
  email: string;
  cityId: string;
  firstName: string;
  role: string;
  createdAt: Date;
  updatedAt: Date;
  active: number;
  id: number;
  city: UsersCrearteCity;
  photoUrl: string;
  favoriteTripsIds: any[];
  cart: any[];
}

export type UsersCrearteCity = {
  id: number;
  name: string;
  description: null;
  latitude: number;
  longitude: number;
  createdAt: Date;
  updatedAt: Date;
}


// Moderation
export type ModerationType = {
  id: number;
  comment: string;
  userData: UserDataType;
  tripData: {
    id: number,
    name: string,
    cityName: string
  },
  userId: number;
  tripId: number;
  rating: number;
  answer: null | answerModerationType[];
  publishedAt: null;
  createdAt: string;
  updatedAt: string;
  tripReviewId: number
};

type answerModerationType = {
  id: number;
  comment: string;
  userId: number;
  tripReviewId: number;
  createdAt: string;
}

// profile info

export type Profile = {
  id: number;
  phone: string;
  email: string;
  firstName: string;
  lastName: null;
  secondName: null;
  dateOfBirth: null;
  role: string;
  createdAt: Date;
  updatedAt: Date;
  loggedAt: Date;
  active: number;
  cityId: null;
  city: CityType;
  photoUrl: string;
  favoriteTripsIds: number[];
  cart: any[];
}

// Profile tab

export enum AliasStaticPage {
  CONTACTS = 'contacts',
  ABOUTUS = 'aboutUs',
  PRIVACYPOLICY = 'privacyPolicy',
  USERAGREEMENT = 'userAgreement'
}

export type ProfileTab = {
  id: number;
  title: string;
  alias: AliasStaticPage;
  content: string;
  createdAt?: null;
  updatedAt?: null;
}

/**
 * Order types
 */

export type UserDataType = {
  name?: string
  firstName: string;
  phone: string;
  photoUrl?: string;
};

export type OrderItemType = {

  id: number;
  userData: UserDataType;
  orderId: number;
  trip: TripType;
  tripDate: string;
  tripTime: string;
  status: RequestStatusType
  price: number;
  adults: number;
  children: number;
  createdAt: string;
  updatedAt: string
}

export type OrderType = {
  id: number
  userId: number
  guideId: number
  adminOrder: number
  payFact: number
  paymentPhone: string,
  userData: UserDataType
  status: RequestStatusType
  price: number
  items: Array<OrderItemType>
  createdAt: string;
  updatedAt: string
}

// orders 
export enum PeriodSaleType {
  MONTH = "month",
  YEAR = "year",
  DAY = "day",
  WEEK = "week"
}

export type OrdersTimeType = {
  id: number;
  userData: UserDataType;
  orderId: number;
  trip: TripType;
  tripDate: string;
  tripTime: string;
  status: number;
  price: number;
  pricePerChild: number;
  pricePerAdult: number;
  adults: number;
  children: number;
  createdAt: string;
  updatedAt: string;
}